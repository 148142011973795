<template>
    <div>
        <v-row>
            <v-col>
                <autocompleteOneForm
                    label="Empresa"
                    :valueInput="values.company"
                    :rule="rules.company"
                    :items="serverItems.companies"
                    @valueChange="(v) => {values.company = v; getAreas(v); getLocations(v); getPositions(v); delete serverErrors[`company`]}"
                    :loading="loadingCompanies"
                    :errorMessages="serverErrors[`company`]"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <autocompleteOneForm
                    label="Locación"
                    :valueInput="values.branch_office_id"
                    :rule="rules.branch_office_id"
                    :items="serverItems.locations"
                    @valueChange="(v) => {values.branch_office_id = v; delete serverErrors[`branch_office_id`]}"
                    :loading="loadingLocations"
                    :errorMessages="serverErrors[`branch_office_id`]"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <autocompleteOneForm
                    label="Área"
                    :valueInput="values.area"
                    :rule="rules.area"
                    :items="serverItems.areas"
                    @valueChange="(v) => {values.area = v; delete serverErrors[`area`]}"
                    :loading="loadingAreas"
                    :errorMessages="serverErrors[`area`]"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <autocompleteOneForm
                    label="Puesto"
                    :valueInput="values.position_id"
                    :rule="rules.position_id"
                    :items="serverItems.positions"
                    @valueChange="(v) => {values.position_id = v; delete serverErrors[`position_id`]}"
                    :loading="loadingPositions"
                    :errorMessages="serverErrors[`position_id`]"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col >
                <switchForm
                    title="Motivo de la requisición"
                    leftLabel="Reemplazo"
                    rightLabel="Nuevo"
                    :valueInput="values.reason"
                    :rule="rules.reason"
                    @valueChange="(v) => {values.reason = v; delete serverErrors[`reason`]}"
                    :errorMessages="serverErrors[`reason`]"
                ></switchForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col sm="6">
                <datePickerForm
                    label="Fecha de solicitud"
                    :valueInput="values.request_date"
                    :rule="rules.request_date"
                    @valueChange="(v) => {values.request_date = v; delete serverErrors[`request_date`]}"
                    :errorMessages="serverErrors[`request_date`]"
                ></datePickerForm>
            </v-col>
            <v-col >
                <datePickerForm
                    label="Fecha esperada para llenado"
                    :valueInput="values.waiting_date"
                    :rule="rules.waiting_date"
                    @valueChange="(v) => {values.waiting_date = v; delete serverErrors[`waiting_date`]}"
                    :errorMessages="serverErrors[`waiting_date`]"
                ></datePickerForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col sm="6">
                <autocompleteOneForm
                    label="Puesto jefe de la vacante"
                    :valueInput="values.manager_position"
                    :rule="rules.manager_position"
                    :items="serverItems.positions"
                    :loading="loadingPositions"
                    @valueChange="(v) => {values.manager_position = v; getEmployee(v); delete serverErrors[`manager_position`]}"
                    :errorMessages="serverErrors[`manager_position`]"
                ></autocompleteOneForm>
            </v-col>
            <v-col >
                <autocompleteOneForm
                    label="Jefe de la vacante"
                    :valueInput="values.manager_id"
                    :rule="rules.manager_id"
                    :items="serverItems.employees"
                    :loading="loadingEmployee"
                    @valueChange="(v) => {values.manager_id = v; delete serverErrors[`manager_id`]}"
                    :errorMessages="serverErrors[`manager_id`]"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col sm="6">
                <textFieldForm
                    label="Lugares disponibles"
                    :valueInput="values.quantity"
                    :rule="rules.quantity"
                    @valueChange="(v) => {values.quantity = v.trim(); delete serverErrors[`quantity`]}"
                    :errorMessages="serverErrors[`quantity`]"
                ></textFieldForm>
            </v-col>
            <v-col >
                <textFieldForm
                    label="Edad deseada (años)"
                    :valueInput="values.age"
                    :rule="rules.age"
                    @valueChange="(v) => {values.age = v.trim(); delete serverErrors[`age`]}"
                    :errorMessages="serverErrors[`age`]"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <textFieldForm
                    label="Horario"
                    :valueInput="values.working_hours"
                    :rule="rules.working_hours"
                    @valueChange="(v) => {values.working_hours = v.trim(); delete serverErrors[`working_hours`]}"
                    :errorMessages="serverErrors[`working_hours`]"
                ></textFieldForm>
            </v-col>
        </v-row>


    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
   
    data(){
        return {
            loadingCompanies: false,
            loadingPositions: false,
            loadingAreas: false,
            loadingLocations: false,
            loadingEmployee: false,
            rules: {
				company: [
                    v => !!v || 'El campo es requerido'
				],
                branch_office_id: [
					v => !!v || 'El campo es requerido'
				],
                area: [
					v => !!v || 'El campo es requerido'
				],
                position_id: [
					v => !!v || 'El campo es requerido'
				],
                request_date:[
                    v => !!v || 'El campo es requerido'
                ],
                waiting_date: [
                    v => !!v || 'El campo es requerido'
                ],
                manager_id: [
                    v => !!v || 'El campo es requerido'
                ],
                manager_id: [
                    v => !!v || 'El campo es requerido'
                ],
                quantity: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]*?$"))
                            return 'Solo números válidos';
                        if(!v)
                            return 'El campo es requerido'
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],
                age: [
                    v => {
                        // if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                        //     return 'Solo números válidos';
                        if(!v)
                            return 'El campo es requerido';
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],
                working_hours: [
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ]
             
            },
            serverItems: {
                companies: [],
                locations: [],
                areas: [],
                positions: [],
                employees: [],
            },

          
        }
    },
    mounted(){
        this.getCompanies();
        this.getLocations(this.values.company);
        this.getAreas(this.values.company);
        this.getPositions(this.values.company);
        this.getEmployee(this.values.manager_position);
    },
    methods: {
        
        /**
         * Load companies
         */
        getCompanies()
        {
            this.loadingCompanies = true;
            this.$api.user.getUserBusinessSelect()
				.then((resp) =>{
					//console.log(resp, "desde form");
					this.serverItems.companies = resp.data.map((item) => {
                        return {text: item.name, value: item.id};
                    });
				})
				.catch((error) =>{
					console.log(error, "error desde form areas");
				})
				.finally(() => {
                    this.loadingCompanies = false;
				})
			;
        },

        /**
         * Load locations
         */
        getLocations(company)
        {
            if(company == '' || company == undefined)
                return;

            this.loadingLocations = true;
            this.$api.locations.getLocationsByBusiness(company)
                .then((resp) => {
                    this.serverItems.locations = resp.data.map((item) => {
                        return {value: item.id, text: item.name}
                    });
                })
                .catch((error) => {
                    console.log("error get locations");
                })
                .finally(() => {
                    this.loadingLocations = false;
                })
            ;

        },

        /**
         * Load areas by company
         */
        getAreas(company = undefined)
        {
            if(company == '' || company == undefined)
                return;

            this.loadingAreas = true;
            this.$api.areas.getAreasByCompany(company)
                .then((resp) =>{
                    //console.log(resp, "desde form");
                    this.serverItems.areas = resp.data.map((itm) => {
                        return {value: itm.id, text: itm.name};
                    });
                })
                .catch((error) =>{
                    console.log(error, "error desde form areas");
                })
                .finally(() => {
                    this.loadingAreas = false;
                })
            ;
        },

        /**
         * Load positions
         */
        getPositions(company = undefined)
        {
            if(company == '' || company == undefined)
                return;
            
            this.loadingPositions = true;
            this.$api.positions.getSelectByBussiness(company)
                .then((resp) => {
                    this.serverItems.positions = resp.data.map((item) => {
                        return {value: item.id, text: item.name};
                    });
                })
                .catch((error) => {
                    console.log(error, "error get positions");
                })
                .finally(() => {
                    this.loadingPositions = false;
                })
            ;
        },

        getEmployee(position = undefined)
        {
            if(position == '' || position == undefined)
                return;
            this.loadingEmployee = true;
            this.$api.employees.getByPosition(position)
                .then((resp) => {
                    this.serverItems.employees = resp.data.map((item) => {
                        return {value: item.id, text: item.name};
                    });
                })
                .catch((error) => {
                    console.log(error, "error get employee");
                })
                .finally(() => {
                    this.loadingEmployee = false;
                })
            ;
        }
    
    }
}
</script>

<style lang="scss">
</style>