<template>
    <div>
        <v-row v-if="!hideCountry">
            <v-col>
                <autocompleteOneForm
                    :label="countryLabel ? countryLabel : 'País'"
                    :valueInput="valuesInput.country"
                    :rule="rules.country"
                    :items="serverItems.countries"
                    :loading="loadingCountries"
                    @valueChange="(v) => {valuesInput.country = v; restart(); delete serverErrors.country}"
                    :errorMessages="serverErrors.country"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <textFieldForm 
                    @valueChange="(v) => {valuesInput.zip_code = v.trim(); zipCodeRestart(); getNeighborhoodsByZipCode(v); delete serverErrors.zip_code}" 
                    :valueInput="valuesInput.zip_code" 
                    :rule="rules.zip_code" 
                    label="Código postal"
                    :errorMessages="serverErrors.zip_code"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="valuesInput.country != 'MX'">
                <textFieldForm 
                    @valueChange="(v) => {valuesInput.neighborhood = v.trim(); delete serverErrors.neighborhood}" 
                    :valueInput="valuesInput.neighborhood" 
                    :rule="rules.neighborhood" 
                    label="Colonia"
                    :errorMessages="serverErrors.neighborhood"
                ></textFieldForm>
            </v-col>
            <v-col v-else>
                <autocompleteOneForm
                    label="Colonia"
                    :valueInput="valuesInput.neighborhood"
                    :rule="rules.neighborhood"
                    :items="serverItems.neighborhoods"
                    @valueChange="(v) => {valuesInput.neighborhood = v; getNeighborhootInfo(v); delete serverErrors.neighborhood}"
                    :loading="this.loadingNeightborhoods"
                    :errorMessages="serverErrors.neighborhood"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
        <!-- Selectos only in MX -->
        <v-row v-if="valuesInput.country != 'MX'">
            <v-col sm="6">
                <textFieldForm 
                    @valueChange="(v) => {valuesInput.city = v.trim(); delete serverErrors.city}" 
                    :valueInput="valuesInput.city" 
                    :rule="rules.city" 
                    label="Municipio"
                    :errorMessages="serverErrors.city"
                ></textFieldForm>
            </v-col>
            <v-col >
                <textFieldForm 
                    @valueChange="(v) => {valuesInput.state = v.trim(); delete serverErrors.state}" 
                    :valueInput="valuesInput.state" 
                    :rule="rules.state" 
                    label="Estado"
                    :errorMessages="serverErrors.state"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col sm="6">
               <autocompleteOneForm
                    label="Municipio"
                    :valueInput="valuesInput.city"
                    :rule="rules.city"
                    :items="serverItems.cities"
                    @valueChange="(v) => {valuesInput.city = v; delete serverErrors.city}"
                    :loading="this.loadingCities"
                    :disabled="true"
                    :errorMessages="serverErrors.city"
                ></autocompleteOneForm>
            </v-col>
            <v-col >
               <autocompleteOneForm
                    label="Estado"
                    :valueInput="valuesInput.state"
                    :rule="rules.state"
                    :items="serverItems.states"
                    @valueChange="(v) => {valuesInput.state = v; delete serverErrors.state}"
                    :loading="this.loadingStates"
                    :disabled="true"
                    :errorMessages="serverErrors.state"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        
    </div>
</template>

<script>
export default {
    props: ['item', 'index', 'rules', 'valuesInput', 'serverErrors', 'countryLabel', 'hideCountry'],
   
    data(){
        return {
            loadingCountries: false,
            loadingNeightborhoods: false,
            loadingStates: false,
            loadingCities: false,

            serverItems: {
                countries: [],
                neighborhoods: [],
                states: [],
                cities: [],
            },
        }
    },
    watch: {
       valuesInput: function () {
          //console.log(this.valuesInput);
          this.start();
        }
    },
  
   
    mounted() {
    //    this.getStates();
    //    this.getCityInfo();
        this.getCountries();
        this.getStates();
        this.start();
    },
    methods: {
        restart(){
            //console.log('restarted with:', this.valuesInput.country);
            this.valuesInput.city = '';
            this.valuesInput.state = '';
            this.valuesInput.neighborhood = '';
            this.valuesInput.zip_code = '';
            this.serverItems.neighborhoods = [];
            //this.serverItems.states = [];
            this.serverItems.cities = [];
        },
        zipCodeRestart(){
            this.valuesInput.city = '';
            this.valuesInput.state = '';
            this.valuesInput.neighborhood = '';
            this.serverItems.neighborhoods = [];
            this.serverItems.cities = [];
        },
        start(){
            //console.log(this.valuesInput, 88);
            if(this.valuesInput.country == 'MX' && this.valuesInput.zip_code != ''){
                this.getNeighborhoodsByZipCode(this.valuesInput.zip_code);
                this.getNeighborhootInfo(this.valuesInput.neighborhood);
            }
        },
        getCountries(){
            this.loadingCountries = true;
            this.$locationsApi.locations.getCountries()
                .then((resp) =>{
                   this.serverItems.countries = resp.data;
                })
                .catch((error) =>{
                    console.log(error);
                })
                .finally(() => {
                    //this.tableProps.loading = false;
                    this.loadingCountries = false;
                })
            ;
        },
        getNeighborhoodsByZipCode(zip_code){
            if(!!zip_code && zip_code.length == 5 && this.valuesInput.country == 'MX'){
                this.loadingNeightborhoods = true;
                //get neighborhoods
                this.$locationsApi.locations.getNeighborhoodsByZipCode(zip_code)
                    .then((resp) =>{
                        //console.log(resp, 963);
                        this.serverItems.neighborhoods = resp.data.map((n) => {
                            return {
                                'value': n.id.toString(),
                                'text': n.name
                            };
                        });
                    })
                    .catch((error) =>{
                        console.log(error);
                    })
                    .finally(() =>{
                        this.loadingNeightborhoods = false;
                    })
                ;
            }
            return;
        },

        getNeighborhootInfo(neighbothood){
            this.loadingCities = true;
            this.loadingStates = true;
            
            this.$locationsApi.locations.getNeighborhoodDetails(neighbothood)
                .then((resp) =>{
                    //console.log(resp);
                    this.serverItems.cities = [{'value':resp.data.city.id, 'text': resp.data.city.name}];
                    this.valuesInput.city = resp.data.city.id;
                    this.valuesInput.state = resp.data.city.state_code == 'CDMX' ? 'DIF' : resp.data.city.state_code;
                })
                .catch((error) =>{
                    console.log(error);
                })
                .finally(() =>{
                    this.loadingCities = false;
                    this.loadingStates = false;
                })
            ;
        },

        getStates(){
           
            this.loadingStates = true;
            this.$locationsApi.locations.getStates()
			.then((response) => {
                //console.log(response)
				this.serverItems.states = response.data.map((x) => {
					return {
                        value: x.value,
                        text: x.text
                    }
				})
			})
			.catch(error => {
				error
			})
            .finally(() => {
                this.loadingStates = false
            })
        },

        getCities(state){
            if(state == '')
                return;
            this.loadingCities = true;
            this.$http.get(`${this.$store.state.apiAddresses}/api/getCities/MX/${state}`)
            .then((response) => {
                //console.log(response)
                this.serverItems.cities = response.data.map((x) => {
                    return {
                        value: x.valuesInput.toString(),
                        text: x.text
                    }
                })
            })
			.catch(error => {
				error
			})
            .finally(() => {
                this.loadingCities = false
            })
        },

        getCityInfo(){
            //console.log(this.item.address_code)
            if(this.item.address_code == '')
                return;
            this.loadingCities = true;
            //console.log(this.item.address_code)
            this.$http.get(`${this.$store.state.apiAddresses}/api/getCityDetails/${this.item.address_code}`)
            .then((response) => {
               // console.log(response)
                this.item['state_id'] = response.data.city.state_code;
                this.getCities(this.item['state_id']);
            })
			.catch(error => {
				error
			})
            .finally(() => {
                this.loadingCities = false
            })
        },

        removeAddress(val){
            this.$emit('removeItem', val);
        }
    }
}
</script>

<style>

</style>