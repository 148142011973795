<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center" >
                    <v-col class="text-left col-md-5">
                        <div class="font-weight-bold body-1">
                            Crear encuesta de baja de empleado
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center" >
                    <v-col class="text-left col-md-5 pt-0">
                        <div class=" body-1 layoff-message">
                            Estas a punto de dar de baja a un empleado, esto provocará
                            que este deje de ser considerado para cualquier
                            funcionalidad del sistema y pase a estatus inactivo.
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="5">
                        <v-form ref="form" @submit.prevent="save">
                            <layoff-form ref="LayoffForm" :values="form" @save="save" :serverErrors="serverErrors"></layoff-form>
                        </v-form>
                    </v-col>
                    
                </v-row>

            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="5" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>

            <!-- DELETE -->
            <modal :props="modalDeleteProps" @modalResponse="method_handler">
                <template slot="cardBody">
                    <p>
                        Una vez concluida la encuesta de baja el empleado <span class="layoff-name-employee">{{form.employeeName}}</span>
                        pasará a estatus inactivo ¿Estás 
                        seguro que deseas continuar con el proceso de baja?

                    </p>
                   
                
                </template>
                <!-- Buttons -->
                <template slot="cardActions">
                    <v-row justify="end" >
                        <v-col cols="12" sm="3">
                            <secondaryButton
                            :props="cancelDeleteModal"
                            ></secondaryButton>
                        </v-col>
                      
                        <v-col cols="12" sm="3" class="mr-sm-4">
                            <primaryButton
                            :props="acceptDeleteModal"
                            ></primaryButton>
                        </v-col>
                    </v-row>
                </template> 
            </modal>
        </template>
    </contentCard>
</template>

<script>
import PrimaryButton from '../../../../components/primaryButton.vue';
import LayoffForm from "./LayoffForm.vue";
import Utils from "../../../../helpers/Utils";
import PageStateManager from '../../../../helpers/PageStateManager';
export default {
    components: {
        'layoff-form': LayoffForm,
        PrimaryButton,
    },
	data(){
		return {
            serverErrors: {},
            loading: false,
            // id: this.$route.params.id,
            form: {
                company: PageStateManager.getState("/empleados", 'selectedCompany')??'',
                branch_office_id: '',
                employee_id: '',
                reason: "",
                comments: "",
                employeeName: "",
               
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "Employees",
                block:false,
                click: () => {}
            },
            saveBtnData: {
                text: "Continuar",
                icon: "mdi-arrow-right-thin",	
                click: this.openDeleteModal,
                block:false,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },

            modalDeleteProps: {
				visible: false,
				width: '700',
				title: 'Atención',
				text: '',
				loading: false,
				bottomLineColor: 'error',
				
			},

            cancelDeleteModal: {
                text: "NO, REGRESAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
                click: ()=>{this.modalDeleteProps.visible = false}
            },
			acceptDeleteModal: {
                text: "Si, Continuar",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
				loading:false,
                click: ()=>{this.save()}
            },
            acceptDeleteModalFALSE: {
                text: "ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
				loading:false,
                disabled: true,
                click: ()=>{}
            },
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        // this.index();
    },
	methods: {
        method_handler(object){
			this[object.method_name](object.parameters)
		},
        openDeleteModal(){
            if(this.validate())
                this.modalDeleteProps.visible = true;
        },
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
        
		validate () {
			return this.$refs.form.validate();
		},
        save(){
           
            this.acceptDeleteModal.loading = true;
           
            // this.$store.state.overlay = true

            if(this.validate()){
                
                // let form = JSON.parse(JSON.stringify(this.form))
                let request = this.prepareRequest(this.form);
                this.$api.employees.discharge(this.form.employee_id, request)
                    .then((response) => {
                        console.log(response)
                        let routeData = this.$router.resolve({
                            path: `/survey-lo-merca/${this.form.employee_id}`,
                            query: {
                            }
                        });
                        //* open survey
                        window.open(routeData.href, '_blank', "popup");
                        this.$router.push({name: 'Employees'});
                    })
                    .catch((error) => {
                        console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 400: 
                                //console.log(99, error.response);
                                if(("message" in error.response.data)){
                                    Object.keys(error.response.data.message).forEach((x) => {
                                        //console.log(x);
                                        this.serverErrors = error.response.data.message;
                                        error.response.data.message[x].forEach((y) => {
                                            this.serverErrors[x] = Utils.deleteWord(x,y);
                                            errors.push(Utils.deleteWord(x,y))
                                        })
                                    });
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }

                       
                    })
                    .finally(() => {
                        this.acceptDeleteModal.loading = false;
                    })
                ;
            }else{
                this.acceptDeleteModal.loading = false;
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },

        /**
         * Process data
         */
        prepareRequest(data){
            let newData = JSON.parse(JSON.stringify(data));
            // if(data.employee_id == '')
            delete newData.employee_id;
            delete newData.company;
            delete newData.branch_office_id;
            delete newData.employee_id;
            delete newData.employeeName;
            return newData;
        }
    },
}
</script>

<style lang="scss">
    .layoff{
        &-message{
            line-height: 1.2rem !important;
        }

        &-name-employee{
            font-weight: bold;
            color: red;
        }

       
    }
</style>

