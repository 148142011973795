<template>
  <div>
   
    <loadingPage :show="loading"></loadingPage>
	<v-card v-if="!loading">
		<v-card-text>
			<!-- <v-row justify="center" >
				<v-col class="text-left col-md-6">
					<div class="font-weight-bold body-1">
						Editar Empleado
					</div>
				</v-col>
			</v-row> -->
            
			<v-row justify="center">
				<v-col cols="12" md="6">
                    <v-form ref="form" @submit.prevent="save" v-if="!updated">
                        <employee-form  ref="EmployeeForm" :values="form" @save="save" :serverErrors="serverErrors"></employee-form>
                    </v-form>

                    <div v-if="updated" class="text-center">
                       <h3>Actualizado con éxito</h3>
                    </div>


				</v-col>
                
			</v-row>

            <v-row justify="center"  v-if="!updated">
				<v-col cols="12" md="6" class="buttons__container">
					<primary-button :props="saveBtnData"></primary-button>
				</v-col>
			</v-row>
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
import EmployeeForm from "./FormUpdateByEmployee.vue";
import Utils from '../../../../helpers/Utils';
// import Object2Form from '../../../../helpers/Object2Form';
export default {
    components: {
        'employee-form': EmployeeForm,
    },
    
	data(){
		return {
            
            token: this.$route.params.token,
            updated: false,
            loading: false,
            serverErrors: {},
            form: {
                /**GENERAL */
                employe_number: '',
                name: '',
                email: '',
                business_email: '',
                profession: '',
                birthplace: '',
                birthdate: '',
                gender: '',
                marital_status: '',
                curp: '',
                rfc: '',
                infonavit_number: '',
                fonacot_number: '',
                nss: '',
                phone: '',
                blood_type: '',
                diseases: [],
                country: 'MX',
                street: '',
                ext_number: '',
                int_number: '',
                zip_code: '',
                neighborhood: '',
                city: '',
                state: '',
                emergency_contact: '',
                emergency_number: '',
                payee_name: '',
                payee_family_phone: '',
                payee_family_relationship: '',
                photo_file: [],
                photo_url: null,
                has_childs: false,
                children: [],

                

              

            },
            
          
            saveBtnData: {
                text: "Guardar",
                icon: "mdi-content-save-outline",	
                click: this.save,
                loading: false,
            },
           
		}
    },
    mounted(){
        console.log("ok index")
        this.index();
    },
	methods: {
        method_handler(object){
            this[object.method_name](object.parameters)
        },
       
        /**
         * Get employee
         */
        index(){
            //this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.employees.getExternal(this.token)
			.then((response) => {
                this.form = {
                    ...this.form,
                    employe_number: response.data.employe_number,
                    name: response.data.name,
                    email: response.data.email,
                    business_email: response.data.business_email??"",
                    profession: response.data.profession??"",
                    birthplace: response.data.birthplace??"",
                    birthdate: response.data.birthdate??"",
                    gender: response.data.gender??"",
                    marital_status: response.data.marital_status??"",
                    curp: response.data.curp,
                    rfc: response.data.rfc,
                    infonavit_number: response.data.infonavit_number??"",
                    fonacot_number: response.data.fonacot_number??"",
                    nss: response.data.nss,
                    phone: response.data.phone,
                    blood_type: response.data.blood_type??"",
                    diseases: response.data.diseases,
                    country: response.data.country,
                    street: response.data.street,
                    ext_number: response.data.ext_number,
                    int_number: response.data.int_number,
                    zip_code: response.data.zip_code,
                    neighborhood: response.data.neighborhood,
                    city: response.data.city,
                    state: response.data.state,
                    emergency_contact: response.data.emergency_contact,
                    emergency_number: response.data.emergency_number,
                    payee_name: response.data.payee_name??"",
                    payee_family_phone: response.data.payee_family_phone??"",
                    payee_family_relationship: response.data.payee_family_relationship??"",
                    photo_file: [],
                    photo_url: response.data.photo_url, 
                    has_childs: false,
                    children: [],
                };

                if("children" in response.data){
                    if(response.data.children.length > 0){
                        this.form.has_childs = true;

                        response.data.children.forEach(item => {
                            this.form.children.push(
                                {birthdate: item.birthdate, gender: item.gender, id: item.id}
                            )
                        });
                    }
                }
                //part 1
                // this.form = {
                //     ...this.form, 
                //     photo_file:[], 
                //     photo_url: response.data.photo_url, 
                //     has_childs: false,
                //     children: []
                // };
                

               
				//console.log(this.id,this.form, 'here');
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
                // this.$refs.form.reset();
			})
        },

        getValidTime(time)
        {
            if(time == '' || time == null)
                return null;
            return time.substring(0,time.length - 3)
        },

        

     
       
		validate () {
			return this.$refs.form.validate()
		},

       

        
        /**
         * UPDATE
         */
        save(){
            this.saveBtnData.loading = true;
            this.$store.state.loading = true;
           
            // this.$store.state.overlay = true
            let formData = this.prepareRequest(this.form);
            console.log(formData);

            // const request = Object2Form.objectToFormData(formData,'',[]);
            this.$api.employees.updateByEmployee(this.token, formData)
                .then((response) => {
                    console.log(response)
                    //this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                    this.updated = true;
                    Utils.scrollToTop();
                })
                .catch((error) => {
                    //this.setTabErrorByStep(this.step);
                    console.log(error)
                    var errors = []
                    switch(error.response.status){
                        case 400: 
                            if(("message" in error.response.data)){
                                this.setTabErrorByServerMessages(error.response.data.message);
                                Object.keys(error.response.data.message).forEach((x) => {
                                    error.response.data.message[x].forEach((y) => {
                                        if(x.startsWith('documentation')){
                                            this.setErrorDocuments(x,y,formData);
                                        }else{
                                            console.log(x,'fuera')
                                            this.serverErrors[x] = Utils.deleteWord(x,y);
                                            
                                        }
                                        errors.push(Utils.deleteWord(x,y))
                                    });
                                });
                                this.$store.dispatch('snackbarError', errors.join('.<br>'));
                            }
                        break;
                        
                    }

                    console.log(this.serverErrors);
                })
                .finally(() => {
                    this.saveBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
            // eslint-disable-next-line no-console
        },

      


       

        /**
         * Process data to send
         */
        prepareRequest(data)
        {
            let newData = JSON.parse(JSON.stringify(data));

            delete newData.photo;
            delete newData.documents;
           

            //!Part 1
            //photo_file
            delete newData.photo_file;
            if(data.photo_file instanceof File)
                newData.photo_file = data.photo_file;
            else if(data.photo_url != '')
                newData.photo_path = 'path';

            
            if(newData.business_email == "")
                delete newData.business_email;
            
            if(newData.int_number == "")
                delete newData.int_number;

            //!part 2
            //files
            newData.documentation = [];

            //birthCertificate_file
            delete newData.birthCertificate_file;
            if(data.birthCertificate_file instanceof File)
                newData.documentation.push({file:data.birthCertificate_file, type: 'ACTA DE NACIMIENTO'});
            else if(data.birthCertificate_url != '')
                newData.documentation.push({type: 'ACTA DE NACIMIENTO'});

            //ine_file
            delete newData.ine_file;
            if(data.ine_file instanceof File)
                newData.documentation.push({file: data.ine_file, type: 'IDENTIFICACIÓN OFICIAL'});
            else if(data.ine_url != '')
                newData.documentation.push({type: 'IDENTIFICACIÓN OFICIAL'});

            //curp_file
            delete newData.curp_file;
            if(data.curp_file instanceof File)
                newData.documentation.push({file: data.curp_file, type: 'CURP'});
            else if(data.curp_url != '')
                newData.documentation.push({type: 'CURP'});

            //rfc
            delete newData.rfc_file;
            if(data.rfc_file instanceof File)
                newData.documentation.push({file:data.rfc_file, type: 'RFC'});
            else if(data.rfc_url != '')
                newData.documentation.push({type: 'RFC'});

            //nss_file
            delete newData.nss_file;
            if(data.nss_file instanceof File)
                newData.documentation.push({file: data.nss_file, type: 'NSS'});
            else if(data.nss_url != '')
                newData.documentation.push({type:'NSS'});


            //proofResidency_file
            delete newData.proofResidency_file;
            if(data.proofResidency_file instanceof File)
                newData.documentation.push({file: data.proofResidency_file, type: 'COMPROBANTE DE DOMICILIO'});
            else if(data.proofResidency_url != '')
                newData.documentation.push({type:'COMPROBANTE DE DOMICILIO'});

            //studyCert_file
            delete newData.studyCert_file;
            if(data.studyCert_file instanceof File)
                newData.documentation.push({file: data.studyCert_file, type: 'CERTIFICADO DE ESTUDIOS'});
            else if(data.studyCert_url != '')
                newData.documentation.push({type:'CERTIFICADO DE ESTUDIOS'});

            //economyStudy_file
            delete newData.economyStudy_file;
            if(data.economyStudy_file instanceof File)
                newData.documentation.push({file: data.economyStudy_file, type: 'ESTUDIO SOCIOECONÓMICO'});
            else if(data.economyStudy_url != '')
                newData.documentation.push({type:'ESTUDIO SOCIOECONÓMICO'});

            //recomendationLetter_file
            delete newData.recomendationLetter_file;
            if(data.recomendationLetter_file instanceof File)
                newData.documentation.push({file: data.recomendationLetter_file, type: 'CARTA DE RECOMENDACIÓN'});
            else if(data.recomendationLetter_url != '')
                newData.documentation.push({type:'CARTA DE RECOMENDACIÓN'});

            //psychometricTest_file
            delete newData.psychometricTest_file;
            if(data.psychometricTest_file instanceof File)
                newData.documentation.push({file: data.psychometricTest_file, type: 'PRUEBA PSICOMÉTRICA'});
            else if(data.psychometricTest_url != '')
                newData.documentation.push({type:'PRUEBA PSICOMÉTRICA'});

            //internalRules_file
            delete newData.internalRules_file;
            if(data.internalRules_file instanceof File)
                newData.documentation.push({file: data.internalRules_file, type: 'REGLAMENTO INTERNO'});
            else if(data.internalRules_url != '')
                newData.documentation.push({type:'REGLAMENTO INTERNO'});

            //confidential_file
            delete newData.confidential_file;
            if(data.confidential_file instanceof File)
                newData.documentation.push({file: data.confidential_file, type: 'CONVENCIO CONFIDENCIAL'});
            else if(data.confidential_url != '')
                newData.documentation.push({type:'CONVENCIO CONFIDENCIAL'});

            //payment_file
            delete newData.payment_file;
            if(data.payment_file instanceof File)
                newData.documentation.push({file: data.payment_file, type: 'PROPUESTA ECONÓMICA'});
            else if(data.payment_url != '')
                newData.documentation.push({type:'PROPUESTA ECONÓMICA'});

            //medicalTest_file
            delete newData.medicalTest_file;
            if(data.medicalTest_file instanceof File)
                newData.documentation.push({file: data.medicalTest_file, type: 'EXAMEN MÉDICO'});
            else if(data.medicalTest_url != '')
                newData.documentation.push({type:'EXAMEN MÉDICO'});

            //letter_file
            delete newData.letter_file;
            if(data.letter_file instanceof File)
                newData.documentation.push({file: data.letter_file, type: 'ANTECEDENTES PENALES'});
            else if(data.letter_url != '')
                newData.documentation.push({type:'ANTECEDENTES PENALES'});


            //!part 3
            newData.contract = new Object();
            newData.contract["admission_date"] = data.admission_date;
            newData.contract["start_date"] = data.start_date;
            newData.contract["end_date"] = data.end_date;
            newData.contract["type"] = data.type;
            newData.contract["mode"] = data.mode;
            newData.contract["payroll_type"] = data.payroll_type;
            newData.contract["fiscal_salary"] = data.fiscal_salary;
            newData.contract["integrated_salary"] = data.integrated_salary;
            newData.contract["support_transportation"] = data.support_transportation?1:0;
            newData.contract["meal_schedule"] = data.meal_schedule?1:0;
            newData.contract["monday_schedule_start"] = data.monday_schedule_start;
            newData.contract["monday_schedule_end"] = data.monday_schedule_end;
            newData.contract["tuesday_schedule_start"] = data.tuesday_schedule_start;
            newData.contract["tuesday_schedule_end"] = data.tuesday_schedule_end;
            newData.contract["wednesday_schedule_start"] = data.wednesday_schedule_start;
            newData.contract["wednesday_schedule_end"] = data.wednesday_schedule_end;
            newData.contract["thursday_schedule_start"] = data.thursday_schedule_start;
            newData.contract["thursday_schedule_end"] = data.thursday_schedule_end;
            newData.contract["friday_schedule_start"] = data.friday_schedule_start;
            newData.contract["friday_schedule_end"] = data.friday_schedule_end;
            
            if( data.saturday_schedule_start != '' &&  data.saturday_schedule_start != null)
                newData.contract["saturday_schedule_start"] = data.saturday_schedule_start;
            if( data.saturday_schedule_end != '' &&  data.saturday_schedule_end != null)
                newData.contract["saturday_schedule_end"] = data.saturday_schedule_end;
            
            if(data.sunday_schedule_start != '' && data.sunday_schedule_start != null)
                newData.contract["sunday_schedule_start"] = data.sunday_schedule_start;
            if(data.sunday_schedule_end != ''&& data.sunday_schedule_end != null)
                newData.contract["sunday_schedule_end"] = data.sunday_schedule_end;

            newData.contract["branch_office_id"] = data.branch_office_id;
            newData.contract["position_id"] = data.position_id;
            newData.contract["repeat_schedule"] = data.repeat_schedule?1:0;
             
            if(data.type == 'INDETERMINADO'){
                delete newData.contract['start_date'];
                delete newData.contract['end_date'];
            }

            console.log(newData, 99);

            newData.city = newData.city + "";

            return newData;
        },

      

    },
}
</script>

<style lang="scss">
    .buttons{
        &__container{
            display: flex !important;
            
            justify-content: flex-end;

            @media (max-width: #{map-get($grid-breakpoints, 'sm')}) {
				//background-color: red;
                flex-direction: column;
                & > * {
                    margin: 5px 8px 5px 0;
                }
			}
			// @media (min-width: #{map-get($grid-breakpoints, 'md')}) {
				
			// }
			// @media (min-width: #{map-get($grid-breakpoints, 'lg')}) {
				
			// 	background-color: blue;
			// }
			// @media (min-width: #{map-get($grid-breakpoints, 'xl')}) {
				
				
			// }
        }
    }
</style>