<template>
    <contentCardMobile :loading="false" :elevation="0" classCard="px-0 mx-0 py-0" cardColor="transparent">
        <template slot="cardContent">
            <div class="">
                <EventVisualVue
                    v-if="showEventVisual"
                    ref="eventVis"
                    :overlayValue="showEventVisual"
                    @valueChange="(v) => {showEventVisual = v}"
                    :event="eventSelected"
                    @confirmed="confirmAssistance"
                    @canceled="cancelAssistance"
                    :loadingSwipe="loadingConfirm"
                ></EventVisualVue>

                
                <v-row justify="space-around">
                    <v-col cols="12" md="5" class="pb-0 pt-1"
                        >
                            <v-chip-group
                            mandatory
                            active-class="employee-event-chip-selected"
                            v-model="chipSelected"
                            >
                                <v-chip
                                    :value="0"
                                    class="employee-event-chip"
                                    
                                >
                                    <span class="">De interés</span>
                                </v-chip>

                                <v-chip
                                    :value="1"
                                    class="employee-event-chip"
                                    
                                    
                                >
                                    <span class="">Asistidos</span>
                                </v-chip>

                                <v-chip
                                    :value="2"
                                    class="employee-event-chip"
                                    
                                    
                                >
                                    <span class="">Vigentes</span>
                                </v-chip>

                                <v-chip
                                    :value="3"
                                    class="employee-event-chip"
                                    
                                    
                                >
                                    <span class="">Todos</span>
                                </v-chip>
                            </v-chip-group>
                    </v-col>
                </v-row>
            

                <v-row justify="space-around">
                    <v-col
                        cols="12"
                        md="5"
                        >
                            <div class="text-center" v-if="loading">
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                ></v-progress-circular>
                            </div>

                            <div v-if="!loading && eventsData.length == 0" class="text-center">
                                <span>Sin resultados</span>
                            </div>


                            <div class="employee-event-container mb-5" v-for="(item, index) in eventsData" :key="index">
                                <span class="employee-event-item-title">{{item.monthText}} {{item.year}}</span>

                                <div class="employee-event-item mb-4 mt-2" v-for="(event, index) in item.items" :key="index" @click="toEvent(event)">
                                    <div class="employee-event-image">
                                        <v-img
                                            :src="event.image_url"
                                            max-height="100"
                                            class="grey darken-4"
                                        >
                                            <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular
                                                indeterminate
                                                color="black lighten-3"
                                                ></v-progress-circular>
                                            </v-row>
                                            </template>
                                        </v-img>
                                    </div>

                                    <div class="employee-event-item-texts-container">
                                        <span class="employee-event-item-texts-title">{{event.title}}</span>
                                        <span class="employee-event-item-texts-subtitle">{{event.dayWeek}} {{event.day}} de {{event.monthText}}</span>

                                    </div>

                                </div>

                            </div>
                        
                            
                    </v-col>
                </v-row>
            </div>
        </template>
    </contentCardMobile>
</template>

<script>
import EventVisualVue from '../../../components/EventVisual.vue';
import PageStateManager from '../../../helpers/PageStateManager';
import Utils from '../../../helpers/Utils';
export default {
   components:{
    'EventVisualVue': EventVisualVue
   },
	data(){
		return {
            loading: true,
            showEventVisual:false,
            eventSelected: null,
            loadingConfirm:false,
            chipSelected: PageStateManager.getState(this.$router.history.current.path, 'chipSelected')??2, 
            
            eventsData: [],
            rawData: []
		}
    },
    watch: {
        
        [`chipSelected`]: function () {
            this.loadChip()
        },
     
      
    },
    mounted(){
        this.loadChip()
    },
	methods: {
        onActionConfirmed() {
            setTimeout(() => {
                this.$refs.swipeButtonrl.reset();
            }, 1000);
        },
        toEvent(event)
        {
            if(event.status == "active" && event.has_attendance){
                this.showEventVisual = true;
                this.eventSelected = event;
                return;
            }
            // if(event.status == "inactive" || event.)
            this.$router.push(`/m/events/${event.id}/details`);
        },
        loadChip()
        {
            PageStateManager.setState(this.$router.history.current.path, 'chipSelected', this.chipSelected)
            if(this.chipSelected == 0){
                this.interested();
                return;
            }
            if(this.chipSelected == 1){
                this.attendend();
                return;
            }
            if(this.chipSelected == 2){
                this.current();
                return;
            }
            if(this.chipSelected == 3){
                this.all();
                return;
            }
        },
        current(){
            this.eventsData = [];
            this.loading = true;
            this.$api.employeeEvents.current()
				.then((resp) =>{

                    for (let i = 0; i < resp.data.length; i++) {
                        resp.data[i].status = "active";
                        
                    }
                    
					this.processData(resp.data);
					
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					// this.tableProps.loading = false;
                    this.loading = false;
				})
			;

          
        },
        all(){
            this.eventsData = [];
            this.loading = true;
            this.$api.employeeEvents.all()
				.then((resp) =>{
                    
					this.processData(resp.data);
					
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					// this.tableProps.loading = false;
                    this.loading = false;
				})
			;

          
        },

        interested(){
            this.eventsData = [];
            this.loading = true;
            this.$api.employeeEvents.interested()
				.then((resp) =>{
                    
					this.processData(resp.data);
					
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					// this.tableProps.loading = false;
                    this.loading = false;
				})
			;

          
        },
        attendend(){
            this.eventsData = [];
            this.loading = true;
            this.$api.employeeEvents.attendend()
				.then((resp) =>{
                    
					this.processData(resp.data);
					
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					// this.tableProps.loading = false;
                    this.loading = false;
				})
			;

          
        },

        processData(info){
            let data = info.sort((a, b) => {
            if (a.date < b.date)
                return -1;
            if (a.date > b.date)
                return 1;
            return 0;
            })

            data.forEach(elem => {
               
                let dateDiv = elem.date.split("-");
                let found = this.eventsData.findIndex((itm) => itm.dateId == `${dateDiv[0]}-${dateDiv[1]}`);
                console.log(found)
                const dateData = Utils.getDateTexts(dateDiv)
                elem.date = Utils.globalDateFormat(elem.date)
                elem.dayWeek = dateData.dayText;
                elem.day = dateData.day;
                elem.monthText = dateData.monthText;
                if(found > -1){
                    this.eventsData[found].items.push(elem);
                }else{
                    this.eventsData.push({
                        dateId: `${dateDiv[0]}-${dateDiv[1]}`,
                        monthText: dateData.monthText,
                        year:dateData.year,
                        
                        items: [elem]
                    });
                }
                
                
            });

            console.log(this.eventsData);

        },

        confirmAssistance(){
            this.loadingConfirm = true;
            this.$api.employeeEvents.confirmAssistance(this.eventSelected.id)
				.then(() =>{
                    setTimeout(() => {
                        // this.$refs.swipeButton.reset();
                        this.showEventVisual = false;
                        this.$refs.eventVis.resetSwipe();
                        this.loadChip();
                    }, 1500);
                    
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					// this.tableProps.loading = false;
                    this.loadingConfirm = false;
				})
			;
        },

        cancelAssistance(){
            this.loadingConfirm = true;
            this.$api.employeeEvents.cancelAssistance(this.eventSelected.id)
				.then(() =>{
                    setTimeout(() => {
                        // this.$refs.swipeButton.reset();
                        this.showEventVisual = false;
                        this.$refs.eventVis.resetSwipe();
                        this.loadChip();
                    }, 1500);
                    
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					// this.tableProps.loading = false;
                    this.loadingConfirm = false;
				})
			;
        }
        
        
    },
}
</script>

<style lang="scss"> 
    .employee-event-chip{
        background-color: #d6d6d7 !important;
        &-selected{
            background-color: var(--v-primary-base) !important;
            color: var(--v-buttonTextDefault-base) !important;
        }
    }

    .employee-event{

        &-item-title{
            font-weight: 600;
            font-size: 0.85rem;
        }

        &-item{
            display: flex;
            flex-direction: row;
            background-color: #ebebeb;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        &-image{
            max-width: 150px;
           
        }

        &-item-texts{
            
            &-container{
                display: flex;
                flex-direction: column;
                font-size: 0.7rem;
                overflow-wrap: anywhere;
                padding: 15px 10px 15px 10px;
                max-height: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &-title{
                font-weight: 600;
            }

            &-subtitle{
   
            }

        }
    }

   
</style>