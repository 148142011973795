<template>
    <div class="accountConfirmation-container">
        <v-row justify="center" v-if="loading">
            <v-col align-self="center" cols="12" class="text-center accountConfirmation-message" >
                <v-progress-circular
					size="200"
					indeterminate
					color="secondary"
				></v-progress-circular>
            </v-col>
            
        </v-row>
        <v-row justify="center" v-if="!loading">
            <v-col align-self="center" cols="12" class="text-center accountConfirmation-message" >
                ¡GRACIAS! TU CUENTA HA SIDO CONFIRMADA CON ÉXITO<br>
                Vamos a generar sinergia
                <br><br>
                <small class="text-center accountConfirmation-sub-message">
                    HEMOS ENVIADO A TU CORREO UN MENSAJE CON INSTRUCCIONES Y  UNA CONTRASEÑA <br> TEMPORAL PARA TU PRIMER INICIO DE SESIÓN SÍGUELAS PARA COMENZAR A HACER USO DE TU CUENTA
                </small>
                    
                <br><br><br><br>
                <span class="accountConfirmation-return clic" @click="ToLogin()">Ir al inicio de sesión</span>
            </v-col>
            
        </v-row>
        <div>
            <img :src="companyLogo" alt="" class="accountConfirmation-company">
        </div>
    </div>
   
</template>

<script>
// import image from '../../assets/backgroundLogin.png';
import companyLogo from '../../assets/LOGO_GRUPO_MERCA.png';
import Utils from '../../helpers/Utils';
// import VueGatesPlugin from '../plugins/vue-gates';
export default {
  components: {  },
	name: 'Login',
	data(){
		return {
            token: this.$route.params.token,
            loading: true,
            companyLogo,
        }
		

	},
    mounted(){
       this.save()
    },
	methods: {
		ToLogin(){
            this.$router.push({name: 'Login'});
        },
        save(){
            this.loading = true;
            this.$api.auth.confirmEmail(this.token)
                .then(() => {
                    this.loading = false;
                    //console.log(response)
                    // this.$router.push({name: 'ResetPasswordSuccess'});
                })
                .catch((error) => {
                    console.log(error.response)
                    var errors = []
                    switch(error.response.status){
                        case 400: 
                            //console.log(99, error.response);
                            if(("message" in error.response.data)){

                                Object.keys(error.response.data.message).forEach((x) => {
                                    //console.log(x);
                                    this.serverErrors = error.response.data.message;
                                    error.response.data.message[x].forEach((y) => {
                                        this.serverErrors[x] = Utils.deleteWord(x,y);
                                        errors.push(Utils.deleteWord(x,y))
                                    })
                                });
                                this.$store.dispatch('snackbarError', errors.join('.<br>'));
                            }
                            
                        break;
                        
                    }

                    
                })
                .finally(() => {
                    
                })
            ;
            // eslint-disable-next-line no-console
        }

        
	}
}
</script>


<style lang="scss">
    .accountConfirmation{

        &-container{
            display: flex;
            flex-direction: column;
            position: relative;
        }

        &-message{
            font-size: 1.7rem;
            font-weight: 500;
        }

        &-sub-message{
            font-size: 1.2rem;
            font-weight: 400;
        }

        &-return{
            font-size: 0.8rem;
            font-weight: 400;
        }

        &-company{
            // width: 100%;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 60px;
        }
    }
</style>

