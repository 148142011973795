<template>
    <div>
        <v-row >
            <v-col>
                <autocompleteOneForm
                    label="Empleado"
                    :valueInput="values.employee_id"
                    :rule="rules.employee_id"
                    :items="serverItems.employees"
                    @valueChange="(v) => {values.employee_id = v; delete serverErrors['employee_id']}"
                    :errorMessages="serverErrors['employee_id']"
                    :disabled="true"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row >
            <v-col >
                <autocompleteOneForm
                    label="Empresa"
                    :valueInput="values.company"
                    :rule="rules.company"
                    :items="serverItems.companies"
                    @valueChange="(v) => {values.company = v; getEmployees(v)}"
                    :loading="loadingCompanies"
                    :disabled="true"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col cols="6">
                <datePickerForm
                    :min="minDaysStart"
                    label="Fecha de inicio del periodo"
                    :valueInput="values.start_date"
                    :rule="rules.start_date"
                    @valueChange="(v) => {values.start_date = v; delete serverErrors['start_date']}"
                    :errorMessages="serverErrors['start_date']"
                    :allowed-dates="allowedDates"
                ></datePickerForm>
            </v-col>
             <v-col >
                <datePickerForm
                    :min="minDaysEnd"
                    label="Fecha de final del periodo"
                    :valueInput="values.end_date"
                    :rule="rules.end_date"
                    @valueChange="(v) => {values.end_date = v; delete serverErrors['end_date']}"
                    :errorMessages="serverErrors['end_date']"
                    :allowed-dates="allowedDates"
                ></datePickerForm>
            </v-col>
        </v-row>

      
       
        <v-row>
            <v-col>
               <textFieldForm
                    label="Comentarios"
                    :valueInput="values.comments"
                    :rule="rules.comments"
                    @valueChange="(v) => {values.comments = v.trim(); delete serverErrors['comments']}"
                    :errorMessages="serverErrors['comments']"
                ></textFieldForm>
            </v-col>
        </v-row>

        <!-- <v-row>
            <v-col>
                <switchForm
                    title="Autorizado por su jefe inmediato"
                    rightLabel="Si"
                    :valueInput="values.required_supervisor_auth"
                    :rule="rules.authorized"
                    @valueChange="(v) => {values.required_supervisor_auth = v; delete serverErrors.required_supervisor_auth}"
                    :errorMessages="serverErrors.required_supervisor_auth"
                ></switchForm>
            </v-col>
        </v-row> -->

       
    </div>
</template>

<script>
import Utils from '../../../../helpers/Utils';
/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'editMode', 'daysOff'],
   
    data(){
        return {
            minDaysStart:null, // Utils.getDateAddedDays(0),
            minDaysEnd: null, //Utils.getDateAddedDays(0),
            loadingCompanies:false,
            loadingEmployees: false,
            lockDays:{
                week:[],
                holidays:[]
            },
            rules: {
				company: [
                    v => !!v || 'El campo es requerido'
				],
                location: [
					v => !!v || 'El campo es requerido'
				],
                employee_id: [
					v => !!v || 'El campo es requerido'
				],
                type:[
                    v => !!v || 'El campo es requerido'
                ],
                start_date:[
                    v => !!v || 'El campo es requerido'
                ],
                end_date:[
                    v => !!v || 'El campo es requerido'
                ],
                reason: [
                    v => !!v || 'El campo es requerido'
                ],
                apply: [
                    v => !!v || 'El campo es requerido'
                ],
               
                comments:[
                    v => {
                        if(v.length > 0){
                            try {
                                if(v.trim() == "")
                                    return "Campo requerido";
                            } catch (error) {
                                console.log(error);
                            }
                        }
                        return true;
                    }
                ],
                
             
            },
            serverItems: {
                companies: [],
                employees: [],
                
            },
            events: [],

          
        }
    },
    mounted(){
        this.lockDays = Utils.processDaysOff(this.daysOff);
        this.getCompanies();
        this.getEmployees(this.values.company)
    },
    methods: {
        allowedDates(val){
           
            const date = new Date(val);
            //holidays
            if(this.lockDays.holidays.includes(val))
                return null;

            //days off
            if(this.lockDays.week.includes(date.getDay()))
                return null;

            return val;
        },
        
        /**
         * Load companies
         */
        getCompanies()
        {
            this.loadingCompanies = true;
            this.$api.user.getUserBusinessSelect()
                .then((resp) =>{
                    this.serverItems.companies = resp.data.map((item) => {
                        return {text: item.name, value: item.id};
                    });
                })
                .catch((error) =>{
                    console.log(error, "error eventForm");
                })
                .finally(() => {
                    this.loadingCompanies = false;
                })
            ;
        },

        /**
         * Load employee
         */
        getEmployees(business)
        {
            if(business == "" || business == undefined || business == null)
                return;
            console.log(business)
            this.loadingEmployees = true;
            this.$api.employees.indexByBusiness(business)
                .then((resp) =>{
                    //console.log(resp, "desde form");
                    this.serverItems.employees = resp.data.map((itm) => {
                        return {value: itm.id, text: itm.name};
                    });
                })
                .catch((error) =>{
                    console.log(error, "error eventForm");
                })
                .finally(() => {
                    this.loadingEmployees = false;
                })
            ;
        }
    }
}
</script>

<style lang="scss">
</style>