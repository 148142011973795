<template>
     <contentCardMobile :loading="loading" :elevation="0" classCard="px-0 mx-0 py-0" cardColor="transparent">
        <template slot="cardContent">
			<div>
                <v-row justify="space-around">
                    <v-col cols="12" md="5" >
                        <div class="font-weight-bold body-1 text-center">
                            {{activeYear}}º año laboral
                        </div>
                    </v-col>
                </v-row>

                <vacationItem 
                    :color="this.color"
                    :title="`${daysAvailable} ${daysAvailable == 1 ? 'día' : 'días'} disponibles`"
                    :takenDays="takenDays"
                    :vacationDaysAvailable="accumulatedDays"
                    :percentageDays="percentageDays"
                    :showAvatar="false"
                ></vacationItem>


                <v-row justify="space-around">
                    <v-col cols="12" md="5" >
                        <div v-for="(item, index) in vacationsData" :key="index">
                            <detail-item
                                :title="item.title"
                                :subtitle="item.subtitule"
                                icon="mdi-umbrella-beach-outline"
                            ></detail-item>
                        </div>
                    </v-col>
                </v-row>
               
               
               
                

            
                
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="5" xl="5" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <!-- <secondary-button :props="cancelBtnData" class="mb-3"></secondary-button> -->
                        <primary-button :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCardMobile>


</template>

<script>
import DetailItemEmployeeCardVue from '../../../components/DetailItemEmployeeCard.vue';
import Utils from '../../../helpers/Utils';
// import Utils from '../../../helpers/Utils';
export default {
    components: {
        'detail-item': DetailItemEmployeeCardVue,
    },
	data(){
		return {
            loading: true,
            vacationsData: [],
            activeYear: 0,
            accumulatedDays: 0,
            takenDays:0,
            percentageDays: 0,
            daysAvailable:0,
            id: this.$route.params.id,
            
           
            
            saveBtnData: {
                text: "Regresar",
                to:"EmployeeVacations",
                icon: "",	
                click: ()=>{},
                block:true,
                loading: false,
            },
            colors: [
                '#FFB236',
                '#6CC823',
                '#BE30FD',
                '#FF0000',
                '#FFFF00',
                '#009AFF',
                '#249A89',
                '#A0A088',
                '#1C6199',

                '#FFB236',
                '#6CC823',
                '#BE30FD',
                '#FF0000',
                '#FFFF00',
                '#009AFF',
                '#249A89',
                '#A0A088',
                '#1C6199',

            ]
            
           
		}
    },
    watch: {
       
        
    },
    mounted(){
        this.index();
        this.getDetails(this.id);
    },
	methods: {
        getDetails(id){
            this.$api.employeeVacations.getYearDetails(id)
				.then((resp) =>{

                    this.vacationsData = resp.data.map((x) => {
                        // x.days_taken = x.days_taken;
                        const dateArr = x.period.split(" - ")
                        x.title = `Periodo vacacional de ${x.days_taken} ${x.days_taken == 1 ? 'día' : 'días'}`;
                        x.subtitule = `${Utils.globalDateFormat(dateArr[0])} - ${Utils.globalDateFormat(dateArr[1])}`;
                        return x;
                    });
                    
					
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					// this.tableProps.loading = false;
                    this.loading = false;
				})
			;

        },
        index(){
            this.vacationsData = [];
            this.loading = true;
            this.daysAvailable = 0;
            this.takenDays = 0;
            this.accumulatedDays = 0;
            this.$api.employeeVacations.list()
				.then((resp) =>{
                    
                    if(resp.data.periods.length == 0)
                        return;

                    const item = resp.data.periods.find((x) => x.year_number == this.id);
                    

                    this.activeYear = item.year_number;

                    this.daysAvailable = item.vacation_days_pending;

                    this.takenDays = item.vacation_days_available - item.vacation_days_pending;

                    this.accumulatedDays = item.vacation_days_available;

                    this.percentageDays = (this.takenDays * 100) /  this.accumulatedDays;

                    this.color = this.colors[this.activeYear - 1];

					
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					// this.tableProps.loading = false;
                    // this.loading = false;
				})
			;

          
        },
        validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate();
            // return true;
		},
        

        
    },
}
</script>

<style lang="scss">
    .employee-request-vacation{

        &-container{
            line-height: 15px !important;
        }
         &-message{
            font-size: 0.8rem;
        }
    }
    

        
</style>