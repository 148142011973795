<template>
    <div>
        <!-- <v-card class="mb-4 mt-0" elevation="0"> -->
            <!-- <v-card-text> -->
                <v-row>
                    <v-col>
                       <textFieldForm 
                            @valueChange="(v) => {values.code = v.trim(); delete serverErrors.code}" 
                            :valueInput="values.code" 
                            :rule="rules.code" 
                            label="Código"
                            :errorMessages="serverErrors.code"
                        ></textFieldForm>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                       <textFieldForm 
                            @valueChange="(v) => {values.name = v.trim(); delete serverErrors.name}" 
                            :valueInput="values.name" 
                            :rule="rules.name" 
                            label="Nombre"
                            :errorMessages="serverErrors.name"
                        ></textFieldForm>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <autocompleteOneForm
                            label="Empresa"
                            :valueInput="values.company"
                            :rule="rules.company"
                            :items="serverItems.companies"
                            @valueChange="(v) => {values.company = v; delete serverErrors.company; getAreas(v);}"
                            :errorMessages="serverErrors.company"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col sm="6">
                        <autocompleteOneForm
                            label="Área"
                            :valueInput="values.area_id"
                            :rule="rules.area_id"
                            :items="serverItems.areas"
                            :loading="loadingAreas"
                            @valueChange="(v) => {values.area_id = v; delete serverErrors.area_id}"
                            :errorMessages="serverErrors.area_id"
                        ></autocompleteOneForm>
                    </v-col>

                    <v-col>
                        <autocompleteOneForm
                            label="Departamento"
                            :valueInput="values.department_id"
                            :rule="rules.required"
                            :items="serverItems.departments"
                            :loading="loadingDepartments"
                            @valueChange="(v) => {values.department_id = v; delete serverErrors.department_id}"
                            :errorMessages="serverErrors.department_id"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col sm="6">
                        <switchForm
                            title="Jefe de área"
                            rightLabel="Si"
                            :valueInput="values.area_manager"
                            :rule="rules.area_manager"
                            @valueChange="(v) => {values.area_manager = v; delete serverErrors.area_manager}"
                            :errorMessages="serverErrors.area_manager"
                        ></switchForm>
                    </v-col>
                    <v-col >
                        <switchForm
                            title="Jefe de RH"
                            rightLabel="Si"
                            :valueInput="values.rh_manager"
                            :rule="rules.rh_manager"
                            @valueChange="(v) => {values.rh_manager = v; delete serverErrors.rh_manager}"
                            :errorMessages="serverErrors.rh_manager"
                        ></switchForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col sm="6">
                        <switchForm
                            title="Requiere viajar"
                            rightLabel="Si"
                            :valueInput="values.requires_travel"
                            :rule="rules.requires_travel"
                            @valueChange="(v) => {values.requires_travel = v; delete serverErrors.requires_travel}"
                            :errorMessages="serverErrors.requires_travel"
                        ></switchForm>
                    </v-col>
                    <v-col >
                        <switchForm
                            title="A cargo de gente"
                            rightLabel="Si"
                            :valueInput="values.have_persons_in_charge"
                            :rule="rules.have_persons_in_charge"
                            @valueChange="(v) => {values.have_persons_in_charge = v; delete serverErrors.have_persons_in_charge}"
                            :errorMessages="serverErrors.have_persons_in_charge"
                        ></switchForm>
                    </v-col>
                </v-row>


                <v-row>
                    <v-col sm="6">
                        <switchForm
                            title="Requiere inglés"
                            rightLabel="Si"
                            :valueInput="values.requires_english"
                            :rule="rules.requires_english"
                            @valueChange="(v) => {values.requires_english = v; delete serverErrors.requires_english}"
                            :errorMessages="serverErrors.requires_english"
                        ></switchForm>
                    </v-col>
                    <v-col >
                        <textFieldForm 
                            @valueChange="(v) => {values.english_level = v.trim(); delete serverErrors.english_level}" 
                            :valueInput="values.english_level" 
                            :rule="rules.english_level" 
                            label="Nivel requerido"
                            :errorMessages="serverErrors.english_level"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <textAreaForm
                            @valueChange="(v) => {values.purpose = v.trim(); delete serverErrors.purpose}" 
                            :valueInput="values.purpose" 
                            :rule="rules.purpose" 
                            label="Propósito del puesto"
                            :errorMessages="serverErrors.purpose"
                        ></textAreaForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col sm="6">
                        <autocompleteOneForm
                            label="Reporta a"
                            :valueInput="values.report_to_id"
                            :rule="rules.report_to_id"
                            :items="serverItems.positions"
                            @valueChange="(v) => {values.report_to_id = v; delete serverErrors.report_to_id}"
                            :errorMessages="serverErrors.report_to_id"
                        ></autocompleteOneForm>
                    </v-col>
                    <v-col>
                        <autocompleteOneForm
                            label="Supervisa a"
                            :valueInput="values.supervise_to_id"
                            :rule="rules.supervise_to_id"
                            :items="serverItems.positions"
                            @valueChange="(v) => {values.supervise_to_id = v; delete serverErrors.supervise_to_id}"
                            :errorMessages="serverErrors.supervise_to_id"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col sm="6">
                        <autocompleteOneForm
                            label="Grado de estudios"
                            :valueInput="values.studies"
                            :rule="rules.studies"
                            :items="serverItems.studies"
                            @valueChange="(v) => {values.studies = v; delete serverErrors.studies}"
                            :errorMessages="serverErrors.studies"
                        ></autocompleteOneForm>
                    </v-col>
                    <v-col>
                        <textFieldForm 
                            @valueChange="(v) => {values.experience = v.trim(); delete serverErrors.experience}" 
                            :valueInput="values.experience" 
                            :rule="rules.experience" 
                            label="Experiencia previa (años)"
                            :errorMessages="serverErrors.experience"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <textAreaForm
                            @valueChange="(v) => {values.background = v.trim(); delete serverErrors.background}" 
                            :valueInput="values.background" 
                            :rule="rules.background" 
                            label="Formación (conocimientos específicos)"
                            :errorMessages="serverErrors.background"
                        ></textAreaForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <textAreaForm
                            @valueChange="(v) => {values.abilities = v.trim(); delete serverErrors.abilities}" 
                            :valueInput="values.abilities" 
                            :rule="rules.abilities" 
                            label="Habilidades"
                            :errorMessages="serverErrors.abilities"
                        ></textAreaForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <textAreaForm
                            @valueChange="(v) => {values.responsibilities = v.trim(); delete serverErrors.responsibilities}" 
                            :valueInput="values.responsibilities" 
                            :rule="rules.responsibilities" 
                            label="Responsabilidades"
                            :errorMessages="serverErrors.responsibilities"
                        ></textAreaForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <textAreaForm
                            @valueChange="(v) => {values.authority = v.trim(); delete serverErrors.authority}" 
                            :valueInput="values.authority" 
                            :rule="rules.authority" 
                            label="Tiene autoridad de"
                            :errorMessages="serverErrors.authority"
                        ></textAreaForm>
                    </v-col>
                </v-row>
                
            <!-- </v-card-text> -->
        <!-- </v-card> -->

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
   
    data(){
        return {
            tab: null,
            show4: false,
            loadingCompanies:false,
            loadingPositions:false,
            loadingDepartments:false,
            loadingAreas:false,
            rules: {
                required: [
					v => !!v || 'El campo es requerido'
				],
                code: [
					v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				],
				name: [
					v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				],
				company: [
                    v => !!v || 'El campo es requerido'
				],
                area_id: [
                    v => !!v || 'El campo es requerido'
				],

                english_level: [
                   v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],

                purpose: [
                   v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],

                report_to_id: [
                    //v => !!v || 'El campo es requerido'
                ],

                supervise_to_id: [
                    //v => !!v || 'El campo es requerido'
                ],

                studies: [
                    v => !!v || 'El campo es requerido'
                ],

                experience: [
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],

                background: [
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],

                abilities: [
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],

                responsibilities: [
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],

                authority: [
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ]


				
            },
            serverItems: {
                companies: [],
                areas: [],
                positions: [],
                departments:[],
                studies: [
                    {text: "Primaria", value: "Primaria"}, 
                    {text: 'Secundaria', value: 'Secundaria'}, 
                    {text: 'Preparatoria', value: 'Preparatoria'},
                    {text: 'Licenciatura', value: 'Licenciatura'},
                    {text: 'Posgrado', value: 'Posgrado'}
                ]
            },

          
        }
    },
    watch: {
        values: function () {
          //console.log(this.values.company);
          this.getAreas(this.values.company);
        }
    },
    mounted(){
        this.getCompanies();
        this.getDepartments();
        
       // this.getPosistions();
       this.getAreas(this.values.company);
       this.getPosistions();
    },
    methods: {
        getDepartments()
        {
            this.loadingDepartments = true;
            this.$api.departments.getSelect()
                .then((resp) =>{
                    //console.log(resp, "desde form");
                    this.serverItems.departments = resp.data.map(item => {
                        return {text: item.name, value: item.id }
                    });
                })
                .catch((error) =>{
                    console.log(error, "error desde form areas");
                })
                .finally(() => {
                    this.loadingDepartments = false;
                })
            ;
        },
        getCompanies()
        {
            this.loadingCompanies = true;
            this.$api.user.getUserBusinessSelect()
                .then((resp) =>{
                    //console.log(resp, "desde form");
                    this.serverItems.companies = resp.data.map((item) => {
                        return {text: item.name, value: item.id};
                    });
                })
                .catch((error) =>{
                    console.log(error, "error desde form areas");
                })
                .finally(() => {
                    this.loadingCompanies = false;
                })
            ;
        },

        getAreas(company = undefined)
        {
            if(company == '' || company == undefined)
                return;
            this.loadingAreas = true;
            this.$api.areas.getAreasByCompany(company)
                .then((resp) =>{
                    //console.log(resp, "desde form");
                    this.serverItems.areas = resp.data.map((itm) => {
                        return {value: itm.id, text: itm.name};
                    });
                })
                .catch((error) =>{
                    console.log(error, "error desde form areas");
                })
                .finally(() => {
                    this.loadingAreas = false;
                })
            ;
        },

        getPosistions()
        {
            
            this.loadingPositions = true;
            this.$api.positions.getSelectAll()
                .then((resp) =>{
                    //console.log(resp, "desde form");
                    this.serverItems.positions = resp.data.map((itm) => {
                        return {value: itm.id, text: itm.name};
                    });
                })
                .catch((error) =>{
                    console.log(error);
                })
                .finally(() => {
                    this.loadingPositions = false;
                })
            ;
        }
    }
}
</script>

<style>

</style>