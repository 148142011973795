<template>
    <div>
        <v-row v-if="!editMode">
            <v-col >
                <autocompleteOneForm
                    label="Empresa"
                    :valueInput="values.company"
                    :rule="rules.company"
                    :items="serverItems.companies"
                    @valueChange="(v) => {values.company = v; getLocations(v); getEmployees(v)}"
                    :loading="loadingCompanies"
                    :disabled="true"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
        <v-row v-if="!editMode">
            <v-col>
                <autocompleteOneForm
                    label="Locación"
                    :valueInput="values.location"
                    :rule="rules.location"
                    :items="serverItems.locations"
                    @valueChange="(v) => {values.location = v}"
                    :loading="loadingLocations"
                    :disabled="true"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
        <v-row v-if="!editMode">
            <v-col>
                <autocompleteOneForm
                    label="Empleado"
                    :valueInput="values.employee_id"
                    :rule="rules.employee_id"
                    :items="serverItems.employees"
                    @valueChange="(v) => {values.employee_id = v; delete serverErrors['employee_id']}"
                    :errorMessages="serverErrors['employee_id']"
                    :disabled="true"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
        <v-row v-if="showType">
            <v-col>
                <autocompleteOneForm
                    label="Tipo de eventualidad"
                    :valueInput="values.type"
                    :rule="rules.type"
                    :items="events"
                    @valueChange="(v) => {values.type = v; delete serverErrors['type']}"
                    :errorMessages="serverErrors['type']"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
        <v-row v-if="!editMode">
            <v-col cols="6">
                <datePickerForm
                    label="Fecha de inicio del periodo"
                    :valueInput="values.start_date"
                    :rule="rules.start_date"
                    @valueChange="(v) => {values.start_date = v; delete serverErrors['start_date']}"
                    :errorMessages="serverErrors['start_date']"
                ></datePickerForm>
            </v-col>
             <v-col >
                <datePickerForm
                    label="Fecha de final del periodo"
                    :valueInput="values.end_date"
                    :rule="rules.end_date"
                    @valueChange="(v) => {values.end_date = v; delete serverErrors['end_date']}"
                    :errorMessages="serverErrors['end_date']"
                ></datePickerForm>
            </v-col>
        </v-row>

        <v-expand-transition v-if="showType">
            <v-row
                v-if="values.type == 'BONOS' ||
                        values.type == 'SUSPENSIONES'"
            >
                <v-col>
                <textFieldForm
                        label="Motivo"
                        :valueInput="values.reason"
                        :rule="rules.reason"
                        @valueChange="(v) => {values.reason = v.trim(); delete serverErrors['reason']}"
                        :errorMessages="serverErrors['reason']"
                    ></textFieldForm>
                </v-col>
            </v-row>
        </v-expand-transition>

        <v-expand-transition v-if="showType">
            <v-row
                v-if="values.type == 'PRÉSTAMOS'"
            >
                <v-col>
                <textFieldForm
                        label="Forma de aplicación"
                        :valueInput="values.apply"
                        :rule="rules.apply"
                        @valueChange="(v) => {values.apply = v.trim(); delete serverErrors['apply']}"
                        :errorMessages="serverErrors['apply']"
                    ></textFieldForm>
                </v-col>
            </v-row>
        </v-expand-transition>
        

        <v-expand-transition v-if="showType">
            <v-row
                v-if="values.type == 'BONOS' ||
                    values.type == 'SUSPENSIONES' ||
                    values.type == 'PRÉSTAMOS'"
            >
                <v-col>
                <textFieldForm
                        label="Monto"
                        :valueInput="values.amount"
                        :rule="rules.amount"
                        @valueChange="(v) => {values.amount = v.trim(); delete serverErrors['amount']}"
                        :errorMessages="serverErrors['amount']"
                    ></textFieldForm>
                </v-col>
            </v-row>
        </v-expand-transition>
       
        <v-row>
            <v-col>
               <textFieldForm
                    label="Comentarios"
                    :valueInput="values.comments"
                    :rule="rules.comments"
                    @valueChange="(v) => {values.comments = v.trim(); delete serverErrors['comments']}"
                    :errorMessages="serverErrors['comments']"
                ></textFieldForm>
            </v-col>
        </v-row>
    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'editMode'],
   
    data(){
        return {
            loadingCompanies:false,
            loadingLocations: false,
            loadingEmployees: false,
            originalType: '',
            showType: false,
            rules: {
				company: [
                    v => !!v || 'El campo es requerido'
				],
                location: [
					v => !!v || 'El campo es requerido'
				],
                employee_id: [
					v => !!v || 'El campo es requerido'
				],
                type:[
                    v => !!v || 'El campo es requerido'
                ],
                start_date:[
                    v => !!v || 'El campo es requerido'
                ],
                end_date:[
                    v => !!v || 'El campo es requerido'
                ],
                reason: [
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],
                apply: [
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],
                amount: [
					v => {
                        if(v!= null && !v.match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(!v)
                            return 'El campo es requerido';
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				],
                comments:[
                    v => {
                        if(v.length > 0){
                            try {
                                if(v.trim() == "")
                                    return "Campo requerido";
                            } catch (error) {
                                console.log(error);
                            }
                        }
                        return null;
                    }
                ],
                
             
            },
            serverItems: {
                companies: [],
                locations: [],
                employees: [],
                
            },
            events: [],

          
        }
    },
    mounted(){
        this.getCompanies();
        this.getEventsItems();
        this.checkType();
        this.getLocations(this.values.company);
        this.getEmployees(this.values.company);
    },
    methods: {

        checkType()
        {
            this.originalType = this.values.type;
            if((this.editMode && this.originalType == 'INASISTENCIAS') || !this.editMode)
            {
                this.showType = true;
            }
        },

        getEventsItems()
        {

            this.events = [
                //{value: "INCAPACIDADES", text:"Incapacidad"},
                {value: "INCAPACIDAD LABORAL", text: "Incapacidad laboral"},
                {value: "INCAPACIDAD GENERAL", text: "Incapacidad general"},
                {value: "PERMISOS SIN GOCE", text:"Permiso sin goce"},
                {value: "PERMISOS CON GOCE", text:"Permiso con goce"},
                // {value: "INASISTENCIAS", text:"Falta"},
                {value: "DÍAS DE DESCANSO", text:"Días de descanso"},
                //{value: "BONOS", text:"Bono"},
                {value: "SUSPENSIONES", text:"Suspensión"},
                //{value: "PRÉSTAMOS", text:"Préstamo"},
                {value: "DÍAS EXTRA", text:"Días extra"},
                {value: "PATERNIDAD", text:"Paternidad"},
                {value: "MATERNIDAD", text:"Maternidad"},
                
            ];
            if(this.editMode && this.values.type == 'INASISTENCIAS'){
                this.events = [
                    // {value: "INASISTENCIAS", text:"Falta"},
                    {value: "PERMISOS SIN GOCE", text:"Permiso sin goce"},
                    {value: "PERMISOS CON GOCE", text:"Permiso con goce"},
                    {value: "DÍAS DE DESCANSO", text:"Días de descanso"},
                    {value: "SUSPENSIONES", text:"Suspensión"},
                    {value: "INCAPACIDADES", text:"Incapacidad"},
                ];
            }
           
        },

        /**
         * Load companies
         */
        getCompanies()
        {
            this.loadingCompanies = true;
            this.$api.user.getUserBusinessSelect()
                .then((resp) =>{
                    this.serverItems.companies = resp.data.map((item) => {
                        return {text: item.name, value: item.id};
                    });
                })
                .catch((error) =>{
                    console.log(error, "error eventForm");
                })
                .finally(() => {
                    this.loadingCompanies = false;
                })
            ;
        },

        /**
         * Load locations
         */
        getLocations(business)
        {
            if(business == null || business == undefined || business == "")
                return;


            this.loadingLocations = true;
            this.$api.locations.getLocationsByBusiness(business)
                .then((resp) =>{
                    //console.log(resp, "desde form");
                    this.serverItems.locations = resp.data.map((itm) => {
                        return {value: itm.id, text: itm.name};
                    });
                })
                .catch((error) =>{
                    console.log(error, "error eventForm");
                })
                .finally(() => {
                    this.loadingLocations = false;
                })
            ;
        },

        /**
         * Load employee
         */
        getEmployees(business)
        {
            if(business == null || business == undefined || business == "")
                return;

            this.loadingEmployees = true;
            this.$api.employees.indexByBusiness(business)
                .then((resp) =>{
                    //console.log(resp, "desde form");
                    this.serverItems.employees = resp.data.map((itm) => {
                        return {value: itm.id, text: itm.name};
                    });
                })
                .catch((error) =>{
                    console.log(error, "error eventForm");
                })
                .finally(() => {
                    this.loadingEmployees = false;
                })
            ;
        }
    }
}
</script>

<style lang="scss">
</style>