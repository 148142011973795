<template>
     <contentCardMobile :loading="loading" :elevation="0" classCard="px-0 mx-0 py-0" cardColor="transparent">
        <template slot="cardContent">
			<div>
                <v-row justify="space-around">
                    <v-col cols="12" md="5" >
                        <div class="font-weight-bold body-1 text-center">
                           Confirmar vacaciones
                        </div>
                    </v-col>
                </v-row>


                <v-row justify="space-around" v-if="currentVacation != null">
                    <v-col cols="12" md="5" class="employee-request-vacation-container">
                        <span class="employee-request-vacation-message">
                            <span class="font-weight-bold">{{currentVacation.employee.name}}</span> CON NÚMERO DE EMPLEADO
                            <span class="font-weight-bold">{{currentVacation.employee.employe_number}}</span> ADSCRITO A LA EMPRESA
                            <span class="font-weight-bold">{{currentVacation.employee.company_name}}</span> 
                            EN EL PUESTO DE <span class="font-weight-bold">{{currentVacation.employee.position_name}}</span>, TE INFORMAMOS QUE TU
                            SOLICITUD DE VACACIONES DE <span class="font-weight-bold">{{toTakeDays}}</span> A DISFRUTAR EN EL
                            PERIODO COMPRENDIDO ENTRE <span class="font-weight-bold">{{ut.globalDateFormat(currentVacation.start_date)}}</span> Y
                            <span class="font-weight-bold">{{ut.globalDateFormat(currentVacation.end_date)}}</span> HA SIDO APROBADA.
                            <br>
                            <br>
                            TUS VACACIONES SERÁN DESCONTADAS DE TU <span class="font-weight-bold">{{yearTaken}} AÑO</span> 
                            DE SERVICIO AL QUE CORRESPONDEN <span class="font-weight-bold">{{totalDays}} DÍAS</span> DE
                            DESCANSO, DE LOS CUÁLES HAS DISFRUTADO <span class="font-weight-bold">{{takenDays}}</span>, TRAS
                            LOS <span class="font-weight-bold">{{toTakeDays}}</span> QUE DISFRUTARÁS EN ESTE PERIODO TENDRÁS
                            DISPONIBLES <span class="font-weight-bold">{{afterAvailableDays}} {{afterAvailableDays == 1 ? 'DÍA' : 'DÍAS'}}</span> DEL MISMO.
                            <br>
                            <br>
                            DEBERÁS PRESENTARTE DE REGRESO A TUS LABORES EL
                            DIA <span class="font-weight-bold">{{ut.globalDateFormat(currentVacation.day_to_return)}}</span>
                            <br>
                            <br>
                            PARA HACER EFECTIVA TU SOLICITUD DEBES MARCAR LA
                            CASILLA CON LA LEYENDA <span class="font-weight-bold">"ACEPTO LOS TÉRMINOS DE
                            LA SOLICITUD"</span> Y HACER CLIC EN EL BOTÓN CONFIRMAR,
                            AL HACERLO EXPRESAS TU CONFORMIDAD DE SOLICITAR
                            Y GOZAR TUS VACACIONES DE ACUERDO A LO QUE
                            ESTABLECE EL <span class="font-weight-bold font-italic">ARTICULO 76 DE LA LEY FEDERAL DEL
                            TRABAJO</span> CONSIDERANDO LOS DATOS PREVIAMENTE
                            ESTABLECIDOS.



                            
                        </span>
                    </v-col>
                </v-row>
               

                <v-row justify="space-around">
                    <v-col cols="12" md="5">
                        <v-form ref="form" @submit.prevent="save">
                            <checkboxForm
                                label="ACEPTO LOS TÉRMINOS DE LA SOLICITUD"
                                :valueInput="confirmation"
                                :rule="rules.confirmation"
                                @valueChange="(v) => {confirmation = v; }"
                            ></checkboxForm>
                        </v-form>
                    </v-col>
                    
                </v-row>

                
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="5" xl="5" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button :props="cancelBtnData" class="mb-3"></secondary-button>
                        <primary-button :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCardMobile>


</template>

<script>
import Utils from '../../../helpers/Utils';
// import Utils from '../../../helpers/Utils';
export default {
    components: {
    },
	data(){
		return {
            ut: Utils,
            loading: true,
            vacationsData: [],
            activeYear: 0,
            accumulatedDays: 0,
            toTakeDays:0,
            percentageDays: 0,
            daysAvailable:0,


            yearTaken: "",
            totalDays: "",
            takenDays: "",
            afterAvailableDays: "",



            confirmation: false,
            currentVacation: null,
            id: this.$route.params.id,
           
            cancelBtnData: {
                text: "Cancelar",
                icon: "",
                to: "EmployeeVacations",
                block:true,	
                click: () => {}
            },
            saveBtnData: {
                text: "Confirmar",
                icon: "",	
                click: this.save,
                block:true,
                loading: false,
                disabled:true,
            },
            serverErrors: {},
            form:{
                start_date: "",
                end_date: ""
            },

            rules:{
                confirmation: [
					v => v==true || 'Campo requerido'
				],
            }
            
           
		}
    },
    watch: {
        [`confirmation`]: function (v) {
           this.saveBtnData.disabled = true;
            if(v == true){
                this.saveBtnData.disabled = false;
            }
        },

       
        '$route.params.id': {
            handler: function(search) {
            this.getVacation(search);
            },
            deep: true,
            immediate: true
        }
        
    },
    mounted(){
        this.getVacation(this.id);
    },
	methods: {
        doubleYearCheck(){
            //2 years
            if(this.currentVacation.start_year_number > 0 && this.currentVacation.end_year_number > 0){
                this.yearTaken = `${this.currentVacation.start_year_number}º y ${this.currentVacation.end_year_number}º`
                const fYear = this.currentVacation.index.periods.find((x) => x.year_number == this.currentVacation.start_year_number);
                const lYear = this.currentVacation.index.periods.find((x) => x.year_number == this.currentVacation.end_year_number);
                this.totalDays = fYear.vacation_days_available + lYear.vacation_days_available;
                this.takenDays = (fYear.vacation_days_available - fYear.vacation_days_pending) + ((lYear.vacation_days_available - lYear.vacation_days_pending));
                this.afterAvailableDays = fYear.vacation_days_pending + lYear.vacation_days_pending;
                return;
            }
            //1 year
            if((this.currentVacation.start_year_number > 0) && !(this.currentVacation.end_year_number > 0)){
                this.yearTaken = `${this.currentVacation.start_year_number}º`;
                const fYear = this.currentVacation.index.periods.find((x) => x.year_number == this.currentVacation.start_year_number);
                this.totalDays = fYear.vacation_days_available;
                this.takenDays = (fYear.vacation_days_available - fYear.vacation_days_pending);
                this.afterAvailableDays = fYear.vacation_days_pending;
                return;
            }
            //2 year
            if(!(this.currentVacation.start_year_number > 0) && (this.currentVacation.end_year_number > 0)){
                this.yearTaken = `${this.currentVacation.end_year_number}º`;
                const lYear = this.currentVacation.index.periods.find((x) => x.year_number == this.currentVacation.end_year_number);
                this.totalDays = lYear.vacation_days_available;
                this.takenDays = ((lYear.vacation_days_available - lYear.vacation_days_pending));
                this.afterAvailableDays = lYear.vacation_days_pending;
                return;
            }
        },
        getVacation(id){
            this.$api.employeeVacations.get(id)
				.then((resp) =>{
                    
                    this.currentVacation = resp.data;
                    this.toTakeDays = resp.data.start_year_quantity + resp.data.end_year_quantity;
                    this.toTakeDays = `${this.toTakeDays} ${this.toTakeDays == 1 ? 'Día' : 'Días'}`;
                    
                    if(resp.data.status == "autorizada")
                        this.index();
                    else
                        this.$router.push({ name: 'EmployeeVacations', params: {  }}).catch(()=>{});
                    
					
				})
				.catch((error) =>{
                    
                    this.$router.push({ name: 'EmployeeVacations', params: {  }}).catch(()=>{});
					console.log(error);
				})
				.finally(() => {
					// this.tableProps.loading = false;
                    // this.loading = false;
				})
			;
        },
        index(){
            this.loading = true;
            this.$api.employeeVacations.list()
				.then((resp) =>{
                    this.currentVacation['index'] = resp.data;
                    if(resp.data?.current_vacation == null){
                        this.$router.push({name: 'EmployeeVacations'});
                    }
                    if(resp.data?.current_vacation?.status == "pendiente"){
                        this.$router.push({name: 'EmployeeVacations'});
                    }
                    this.doubleYearCheck();
                    // // this.currentVacation = resp.data.current_vacation;
                    // if(resp.data.periods.length == 0)
                    //     return;

                    // this.vacationsData = resp.data.periods.map((x) => {
                    //     x.toTakeDays = x.vacation_days_available - x.vacation_days_pending;
                    //     x.percentageDays = (x.toTakeDays * 100) /  x.vacation_days_available;
                    //     return x;
                    // });

                    // this.vacationsData = this.vacationsData.sort((a, b) => {
                    //     if (a.year_number < b.year_number)
                    //         return 1;
                    //     if (a.year_number > b.year_number)
                    //         return -1;
                    //     return 0;
                    // })

                    // this.activeYear = this.vacationsData[0]?.year_number??0;

                    // this.vacationsData.forEach(it => {
                    //     this.accumulatedDays = this.accumulatedDays + it.vacation_days_available;
                    //     this.toTakeDays = this.toTakeDays + it.toTakeDays;
                    //     this.daysAvailable = this.daysAvailable + it.vacation_days_pending
                    // });

                    // this.percentageDays = (this.toTakeDays * 100) /  this.accumulatedDays;

                    
					
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					// this.tableProps.loading = false;
                    this.loading = false;
				})
			;

          
        },
        validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate();
            // return true;
		},
        save(){
            
            //console.log(this.form);
            
            if(this.validate()){
                
                
                this.saveBtnData.loading = true;
                this.$store.state.loading = true;
                this.$api.employeeVacations.confirm(this.id)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Tus vacaciones han sido confirmadas`);
                        this.$router.push({name: 'EmployeeVacations'});
                    })
                    .catch((error) => {
                        //console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 400: 
                                //console.log(99, error.response);
                                if(("message" in error.response.data)){
                                    Object.keys(error.response.data.message).forEach((x) => {
                                        //console.log(x);
                                        this.serverErrors = error.response.data.message;
                                        error.response.data.message[x].forEach((y) => {
                                            this.serverErrors[x] = Utils.deleteWord(x,y);
                                            errors.push(Utils.deleteWord(x,y))
                                        })
                                    });
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },

        
    },
}
</script>

<style lang="scss">
    .employee-request-vacation{

        &-container{
            line-height: 15px !important;
        }
         &-message{
            font-size: 0.8rem;
        }
    }
    

        
</style>