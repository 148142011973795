<template>
    <div>

        <v-row v-if="edit">
            <v-col>
              <textFieldForm
                label="Correo electrónico"
                :valueInput="values.email"
                :rule="rules.email"
                @valueChange="(v) => {values.email = v.trim(); serverErrors.email; delete serverErrors.email}"
                :errorMessages="serverErrors.email"
              ></textFieldForm>
            </v-col>
          </v-row>

        <v-row v-if="!edit">
            <v-col>
                <autocompleteOneForm
                    label="Empresa"
                    :loading="loadingCompanies"
                    :valueInput="values.company_id"
                    :rule="rules.company_id"
                    :items="serverItems.company"
                    :errorMessages="serverErrors.company_id"
                    @valueChange="(v) => {values.company_id = v;  getEmployeeByBusiness(v); delete serverErrors.company_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row v-if="!edit">
            <v-col>
                <autocompleteOneForm
                    label="Empleado"
                    :loading="loadingEmployees"
                    :valueInput="values.employee_id"
                    :rule="rules.employee_id"
                    :items="serverItems.employees"
                    :errorMessages="serverErrors.employee_id"
                    @valueChange="(v) => {values.employee_id = v; setEmployeInfo(v); delete serverErrors.employee_id; delete serverErrors.email}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <autocompleteOneForm
                    label="Rol"
                    :loading="loadingRoles"
                    :valueInput="values.role_id"
                    :rule="rules.role_id"
                    :items="serverItems.roles"
                    :errorMessages="serverErrors.role_id"
                    @valueChange="(v) => {values.role_id = v; delete serverErrors.role_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <autocompleteMultipleForm
                label="Otras empresas de interes"
                :valueInput="values.companies_list"
                :loading="loadingCompanies"
                :rule="[]"
                :items="serverItems.company"
                @valueChange="(v) => {values.companies_list = v; delete serverErrors.companies_list}"
                :errorMessages="serverErrors.companies_list"
                ></autocompleteMultipleForm>
            </v-col>
        </v-row>


        <v-row v-if="!edit">
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.password = v.trim(); delete serverErrors.password}" 
                    :valueInput="values.password" 
                    :rule="rules.required" 
                    label="Contraseña"
                    :errorMessages="serverErrors.password"
                    :count="true"
                    :prependInnerIcon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPass ? 'text' : 'password'"
                    @clickAppend="() => {showPass = !showPass}"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row v-if="!edit">
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.password_confirmation = v.trim(); delete serverErrors.password_confirmation}" 
                    :valueInput="values.password_confirmation" 
                    :rule="[...rules.required, ...rules.samePassword]" 
                    label="Confirmar contraseña"
                    :errorMessages="serverErrors.password_confirmation"
                    :count="true"
                    :type="showPass ? 'text' : 'password'"
                    @click:append="showPass = !showPass"
                ></textFieldForm>
            </v-col>
        </v-row>
        
        

       
        
    
    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            showPass: false,
            loadingCompanies: false,
            loadingEmployees: false,
            loadingRoles: false,

            rules: {
                required: [
					v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				],
                email: [
                    //v => !!v || 'El campo es requerido'
                    v => {
                        if(!v)
                        return 'El campo es requerido';
                        if(v.length > 0) {
                            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                            return pattern.test(v) || 'Correo no válido';
                        }
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true
                    }
                ],
				name: [
					v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				],
				company_id: [
                    v => !!v || 'El campo es requerido'
				],
                samePassword:[
                    (v) => v == this.values.password || "Las contraseñas no coinciden",
                ],
				
            },
            serverItems: {
                company: [],
                employees: [],
                roles: [],
                employeesData:[],
            },

          
        }
    },
    watch: {
        values: function (vv) {
            //console.log(this.values, vv);
        }
    },
    mounted(){
        this.getCompanies();
        this.getRoles();
        if(this.edit){
            this.getEmployeeByBusiness(this.values.company_id);
        }
    },
    methods: {

        getRoles()
        {
            this.loadingRoles = true;
            this.$api.rol.index()
				.then((resp) =>{
					//console.log(resp, "desde form");
					this.serverItems.roles = resp.data.map(item => {
                        return {value: item.id, text:item.name}
                    });
				})
				.catch((error) =>{
					console.log(error, "error desde form areas");
				})
				.finally(() => {
                    this.loadingRoles = false;
				})
			;
        },
        getCompanies()
        {
            this.loadingCompanies = true;
            this.$flujoApi.business.getBusinessSelect()
				.then((resp) =>{
					//console.log(resp, "desde form");
					this.serverItems.company = resp.data;
				})
				.catch((error) =>{
					console.log(error, "error desde form areas");
				})
				.finally(() => {
                    this.loadingCompanies = false;
				})
			;
        },

        getEmployeeByBusiness(business = undefined)
        {
            if(business == '' || business == undefined)
                return;
            this.loadingEmployees = true;
            this.$api.employees.indexByBusiness(business)
                .then((resp) => {
                    // this.serverItems.employees = [];
                    this.serverItems.employeesData = resp.data;
                    this.serverItems.employees = resp.data.map((emp) =>{
                        return {value:emp.id, text:emp.name}
                    });
                   
                })
                .catch((error) => {
                    console.log(error, "assing form");
                })
                .finally(() =>{
                    this.loadingEmployees = false;
                })
            ;
        },
        setEmployeInfo(employee_id = "")
        {
            if(employee_id == '' || employee_id == undefined || employee_id == null)
                return;


            let found = this.serverItems.employeesData.find(item => item.id == employee_id);
            if(found == undefined)
                return;
            
            this.values.email = found.email;
            // this.values.name = found.name;

        }
    }
}
</script>

<style>

</style>