<template>
    <div class="d-flex">
        <!-- <v-col :class="`mx-0 my-0 ${url?'pr-0':''}`"> -->
            <v-row align="center" class="ml-0">
                
                <v-col cols="8" align-self="center" xl="11" class="mx-0 px-1 ">
                    <span class="ellipsis">{{nameImg}}</span>
               
                </v-col>
                 
               
            </v-row>
            <div v-if="url">
                <alternativeButton
                    :class="`ml-2 btn primary max-h`"
                    :props="openUrlBtn"
                ></alternativeButton>
            
                <alternativeButton
                    :class="`mx-1 btn error max-h`"
                    :props="deleteBtn"
                ></alternativeButton>
            </div>
            
        <!-- </v-col> -->
        
    </div>

   

</template>
<script>
export default {
    props: ['valueInput',  'url', 'nameImg'],
    model: {
        prop: 'value',
        event: 'valueChange'
    },
    computed: {
        value: {
            get: function() {
                return this.valueInput
            },
            set: function(value) {
                //console.log(value)
                this.$emit('valueChange', value)
            }
        }
    },
    data(){
        return{
            openUrlBtn: {
                text: "",
                icon: "mdi-eye-outline",
				to: "",
				block: false,
                textClass:"",
                click: ()=>{this.openUrl(this.url)}
            },
            deleteBtn: {
                text: "",
                icon: "mdi-delete-outline",
                textClass:"white--text",
				to: "",
				block: false,
                click: ()=>{this.delete()}
            },
        }
    },
    methods:{
        openUrl(fileUrl){
            window.open(fileUrl)
        },
        delete(){
            //console.log('ok delete');
            this.$emit('update:url', '');
        },
        cleared(){
            this.$emit('cleared:file','');
        }
    }
    
}
</script>

<style lang="scss" >
//    .finp> .v-input__control>.v-input__slot>.v-input__append-inner>{
//       margin-top: 0px !important;
//    }

    //align icons
    .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner{
        align-self: center !important;
        margin-top:  unset !important;
    }


    .max-h{
        height: 2.4375rem !important;
    }
  

    .max-w-img-prev{
        max-width: 60px !important;
    }
</style>

