<template>
    <contentCardMobile :loading="loading" :elevation="0" classCard="px-0 mx-0 py-0" cardColor="transparent">
        <template slot="cardContent">
            <div >
                <v-row justify="space-around">
                    <v-col cols="12" md="5" class="employee-equipment-container">
                        <span class="employee-equipment-message">
                            Recuerda que el equipo de trabajo que te facilitamos es para
                            tu beneficio y asegurar que puedas cumplir con tus funciones de forma eficiente,
                            es tu responsabilidad mantenerlo en buen estado y cuidarlo.
                        </span>
                    </v-col>
                </v-row>
            
                    

                <v-row justify="space-around">
                    <v-col cols="12" md="5" class="py-0">

                        <div class="text-center" v-if="loading">
                            <v-progress-circular
                                indeterminate
                                color="primary"
                            ></v-progress-circular>
                        </div>

                        <div v-if="!loading && equipmentData.length == 0" class="text-center">
                            <span>Sin resultados</span>
                        </div>

                        <div v-for="(item, index) in equipmentData" :key="index">
                            <detail-item
                                :title="item.title"
                                :subtitle="item.assignment_date"
                                icon="mdi-cellphone-link"
                            ></detail-item>
                        </div>
                        
                        <!-- <div class="employee-equipment-item mb-2" v-for="(item, index) in equipmentData" :key="index">
                            <div class="employee-equipment-item-texts">
                                <span class="employee-equipment-item-title">{{item.title}}</span>
                                <span class="employee-equipment-item-subtitle">{{item.assignment_date}}</span>
                            </div>
                            <div class="employee-equipment-item-icon">
                                <v-icon small>mdi-cellphone-link</v-icon>
                            </div>
                        </div> -->
                        
                    </v-col>
                </v-row>
            </div>
        </template>
    </contentCardMobile>
    
</template>

<script>
import DetailItemEmployeeCardVue from '../../../components/DetailItemEmployeeCard.vue';
import Utils from '../../../helpers/Utils';
export default {
    
    components: {
        'detail-item': DetailItemEmployeeCardVue,
    },
	data(){
		return {
            loading: true,
            
            equipmentData: [],
            rawData: []
		}
    },
    watch: {
        
     
      
    },
    mounted(){
       this.index();
    },
	methods: {
        index(){
            this.equipmentData = [];
            this.loading = true;
            this.$api.employeeEquipments.list()
				.then((resp) =>{
                    console.log(resp)
                    this.equipmentData = resp.data.map((x) => {
                        x.assignment_date = Utils.globalDateFormat(x.assignment_date);
                        x.title = `${x.description??""} ${x.brand??""} ${x.model??""}`;
                        return x;
                    });
					
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					// this.tableProps.loading = false;
                    this.loading = false;
				})
			;

          
        },
        
    },
}
</script>

<style lang="scss"> 
    .employee-equipment{
        &-message{
            font-size: 0.8rem;
        }

        &-container{
            line-height: 15px !important;

        }


        &-item{
            padding: 1px 7px;
            display: flex;
            justify-content: space-between;
            background-color: #e8e8e8;
            border-radius: 8px;
            border-left: 7px solid var(--v-primary-base);
        }

        &-item-texts{
            padding: 8px 5px;
            display: flex;
            flex-direction: column;

        }

        &-item-title{
            font-size: 0.7rem;
            font-weight: 500;
        }

        &-item-subtitle{
            font-size: 0.65rem;
        }
    }
   
    
</style>