<template>
    <div>
       
        <v-row>
            <v-col>
                <textFieldForm 
                    @valueChange="(v) => {values.name = v; delete serverErrors.name}" 
                    :valueInput="values.name" 
                    :rule="rules.required" 
                    label="Nombre del evento"
                    :errorMessages="serverErrors.name"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
              <fileInputForm
                label="Afiche del evento"
                :multiple="false"
                :valueInput="values.poster_file"
                :url="values.poster_url"
                :rule="rules.fileRequired"
                @valueChange="(v) => {values.poster_file = v; delete serverErrors.poster_file}"
                :errorMessages="serverErrors.poster_file"
                v-on:update:url="values.poster_url = $event"
                ></fileInputForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <datePickerForm
                    label="Fecha del evento"
                    :valueInput="values.start_date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.start_date = v; delete serverErrors.start_date}"
                    :errorMessages="serverErrors.start_date"
                ></datePickerForm>
            </v-col>
            <v-col>
                <datePickerForm
                    label="Vigencia del evento"
                    :valueInput="values.end_date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.end_date = v; delete serverErrors.end_date}"
                    :errorMessages="serverErrors.end_date"
                ></datePickerForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <timePickerForm
                    label="Hora del evento"
                    :valueInput="values.hour"
                    :rule="rules.required"
                    @valueChange="(v) => {values.hour = v; delete serverErrors.hour;}"
                    :errorMessages="serverErrors.hour"
                ></timePickerForm>
            </v-col>
            <v-col>
                <textFieldForm 
                    @valueChange="(v) => {values.place = v; delete serverErrors.place}" 
                    :valueInput="values.place" 
                    :rule="rules.required" 
                    label="Lugar en el que será el evento"
                    :errorMessages="serverErrors.place"
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col>
                <textFieldForm 
                    @valueChange="(v) => {values.speaker = v; delete serverErrors.speaker}" 
                    :valueInput="values.speaker" 
                    :rule="rules.required" 
                    label="Dirige/imparte"
                    :errorMessages="serverErrors.speaker"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <textFieldForm 
                    @valueChange="(v) => {values.comments = v; delete serverErrors.comments}" 
                    :valueInput="values.comments" 
                    :rule="rules.required" 
                    label="Comentarios"
                    :errorMessages="serverErrors.comments"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <switchForm
                    title="Evento requiere lista de asistencia"
                    leftLabel="No"
                    rightLabel="Si"
                    :valueInput="values.has_attendance"
                    :rule="[]"
                    @valueChange="(v) => {values.has_attendance = v}"
                ></switchForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <table-items :props="companyTable" @methodHandler="method_handler" :items="companyTable.items" :valueInput="values.companies_notified" @valueChange="(v) => {values.companies_notified = v}" >
                </table-items>
                
            </v-col>
        </v-row>

        <v-row v-if="fullForm && values.has_attendance">
            <v-col >
             
                <actionButtonForm
                    label="Confirmar asistencia"
                    :prependInnerIcon="'fact_check'"
                    @clickAppend="() => {openModal()}"
                    
                ></actionButtonForm>
            </v-col>
        </v-row>

        <div v-if="fullForm">
            <v-row>
                <v-col class="mt-3">
                <fileInputForm
                    label="Fotografías del evento"
                    :multiple="true"
                    :valueInput="values.new_photo_album_files"
                    
                    :rule="[]"
                    @valueChange="(v) => {values.new_photo_album_files = v; delete serverErrors.new_photo_album_files}"
                    :errorMessages="serverErrors.new_photo_album_files"
                    
                    ></fileInputForm>
                </v-col>
            </v-row>
            
            <v-row v-for="(item, index) in values.imgs" :key="index">
                <v-col>
                    <imagePrevForm
                        :nameImg="item.name"
                        :url="item.url"
                        v-on:update:url="item.url = $event; removeImg(index);"
                    ></imagePrevForm>
                </v-col>
            </v-row>

        </div>





        <modal :props="modalProps" @modalResponse="method_handler">
            <!-- Body -->
            <template slot="cardBody" >
                
                <!-- SELECT employee -->
               <!-- <v-row>
                    <v-col class="mt-3">
                       <autocompleteOneForm
                            placeholder="Agregar empleado"
                            :valueInput="employeeSelected"
                            :rule="[]"
                            :items="serverItems.employees"
                            @valueChange="(v) => {employeeSelected = v;}"
                            :loading="loadingEmployee"
                            class="no-label"
                        ></autocompleteOneForm>
                       
                    </v-col>
                   <v-col cols="2">
                      <alternativeButton
                            :class="`btn primary mt-3`"
                            :props="addEmployeeBtn"
                        ></alternativeButton>
                   </v-col>
                </v-row> -->

                <v-row>
					<v-col class="mx-0 px-0"> 
						<datatable :props="tableProps" @method_handler="method_handler">
                            <template v-slot:[`item.value`]="props">
                               <div class='d-flex justify-center'>
                                    <v-checkbox  v-model="props.item.value"></v-checkbox>
                               </div>
                            </template>
                        </datatable>
					</v-col>
				</v-row>
                    
			</template>
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					
					<v-col cols="12" class="text-end">
						<primaryButton
						:props="acceptModal"
                        ref="acceptMdl"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>
    

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'fullForm'],
   
    data(){
        return {
            loadingCompanies: false,
            loadingEmployee:false,
            employeeSelected: "",
            addEmployeeBtn: {
                text: "",
                icon: "mdi-plus",
				to: "",
				block: false,
                textClass:"",
                click: ()=>{this.addEmployee()}
            },
            modalProps: {
				visible: false,
				width: '550',
				title: 'Lista de asistencia',
				text: 'Los siguientes compañeros confirmaron su asistencia al evento, corrobora su presencia si aplica.',
				loading: false,
				bottomLineColor: 'primary',
				
			},
			
			
			acceptModal: {
                text: "Guardar",
                icon: "",
				to: "",
				block: false,
				color: 'primary',
				// textColor:'',
				loading:false,
                click: ()=>{this.saveItems()}
            },

            tableProps: {
				headers: [
					{
						text: 'Nombre',
						align: 'left',
						value: 'name',
						class: 'table-b-border'
					},
                    {
						text: 'Check',
						align: 'center',
						value: 'value',
                        sortable: false,
						class: 'table-b-border text-center'
					},
                    
				],
				items: [],
				search: '',
				loading: false,
                elevation: '0',
                hideFooter:true
			},

            companyTable: {
				headers: [
					{
						text: 'Empresas a notificar',
						align: 'left',
						value: 'name',
						class: 'table-b-border body-1 font-weight-bold',
                        sortable: false,
					},
                   
					{
						text: '',
						align: 'right',
						sortable: false,
						value: 'data-table-select',
						class: 'table-b-border'
					}
				],
				items: [],
				search: '',
				loading: false,
                showSelect: true,
                key:"id",
                showSelect:true
			},
            
            rules: {
				required: [
					v => !!v || 'Campo requerido'
				],
				company_id: [
                    v => !!v || 'El campo es requerido'
				],
                fileRequired: [
                    v => {
                        if(this.values.poster_file.length == 0 && this.values.poster_url == "")
                            return "El campo es requerido"
                        return true
                    }
                ]
				
            },
            serverItems: {
                employees: []
            },

          
        }
    },
    watch: {
        values: function (vv) {
            //console.log(this.values, vv);
        }
    },
    async mounted(){
        // await this.getEmployee();
        await this.getCompanies();
    },
    methods: {
        method_handler(object){
			this[object.method_name](object.parameters)
		},
        openModal()
        {
            this.employeeSelected = "";
            this.tableProps.items = JSON.parse(JSON.stringify(this.values.assist))
			this.modalProps.visible = true;

            //to lost focus on first checkbox
            setTimeout(() => {
                this.$refs.acceptMdl.$el.focus();
            }); 
        },

        saveItems(){
            this.modalProps.visible = false;
            this.values.assist = JSON.parse(JSON.stringify(this.tableProps.items));
            console.log(this.values.assist)
        },

        removeImg(index)
        {
            let deleted = JSON.parse(JSON.stringify(this.values.imgs[index]));
            this.values.imgs.splice(index, 1);
            this.values.delete_photo_album_files.push({photo_path: deleted.path});
        },

        addEmployee()
        {
            if(this.employeeSelected == "" || this.employeeSelected == undefined)
                return;
            const tableFound = this.tableProps.items.find((item) => item.idEmployee == this.employeeSelected);
            if(tableFound != undefined)
                return;

            const found = this.serverItems.employees.find((item) => item.value == this.employeeSelected);
            this.tableProps.items.push({name: found.text, value: true, idEmployee: found.value});
            this.employeeSelected = "";
        },  

        getEmployee()
        {
            
            this.loadingEmployee = true;
            this.$api.employees.index()
                .then((resp) => {
                   console.log(resp.data)
                   this.serverItems.employees = resp.data.map((item) => {
                    return {text: item.name, value: item.id};
                   });
                })
                .catch((error) => {
                    console.log(error, "assing form");
                })
                .finally(() =>{
                    this.loadingEmployee = false;
                })
            ;
        },

        /**
         * Load companies
         */
         getCompanies()
        {
            this.loadingCompanies = true;
            this.$api.user.getUserBusinessSelect()
                .then((resp) =>{
                    const data = Object.values(resp.data.reduce((acc,cur)=>Object.assign(acc,{[cur.id]:cur}),{}));
                    this.companyTable.items = data;
                })
                .catch((error) =>{
                    console.log(error, "error eventForm");
                })
                .finally(() => {
                    this.loadingCompanies = false;
                })
            ;
        },
       
       
    }
}
</script>

<style lang="scss">
    .no-label .v-text-field .v-input__control .v-input__slot input, textarea{
        margin-top: 0 !important;
    }
</style>