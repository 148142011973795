<template>
    <div class="survey-thanks-container">
        <v-row justify="center">
            <v-col align-self="center" cols="12" class="text-center survey-thanks-message" >
                ¡¡Gracias por tu tiempo y tus respuestas!!
            </v-col>
            
        </v-row>
        <div>
            <img :src="companyLogo" alt="" class="survey-thanks-company">
        </div>
    </div>
   
</template>

<script>
// import image from '../../assets/backgroundLogin.png';
import companyLogo from '../../../../assets/LOGO_GRUPO_MERCA.png';

// import VueGatesPlugin from '../plugins/vue-gates';
export default {
  components: {  },
	name: 'Login',
	data: () => ({
        companyLogo,
		

	}),
	methods: {
		
        
	}
}
</script>


<style lang="scss">
    .survey-thanks{

        &-container{
            display: flex;
            flex-direction: column;
            position: relative;
        }

        &-message{
            font-size: 2.5rem;
            font-weight: 500;
        }

        &-company{
            // width: 100%;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 60px;
        }
    }
</style>

