<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="3" lg="3" class="mx-0 px-0">
                        <div class="calendar-index-inputs">
                            <span class="body-1 font-weight-bold">{{monthName}} {{yearText}}</span>
                            <div class="ml-0 ml-md-0 ">
                                <alternativeButton
                                class="mr-1 btn x"
                                :props="returnBtnData"
                                ></alternativeButton>
                                <alternativeButton
                                    class="btn"
                                    :props="nextBtnData"
                                ></alternativeButton>
                            </div>

                        </div>
					</v-col>
                    
					<v-spacer></v-spacer>
					
				</v-row>
                
                <v-row class="fill-height">
                    <v-col
                        cols="12"
                        class="mx-0 px-0"
                    >
                    
                        <div class="testets">
                            <div class="calendar-loading" v-if="loading">
                                <v-progress-circular
                                    size="200"
                                    indeterminate
                                    color="secondary"
                                ></v-progress-circular>
                            </div>

                            <div class="calendar-week-names" v-if="!loading">
                                <span>Domingo</span>
                                <span>Lunes</span>
                                <span>Martes</span>
                                <span>Miercoles</span>
                                <span>Jueves</span>
                                <span>Viernes</span>
                                <span>Sábado</span>
                            </div>
                                   
                               
                            <v-calendar
                                hide-header
                                
                                :key="calendayKey"
                                v-if="!loading"
                                :start="start"
                                :end="end"
                                :min-date='new Date()'
                                :max-date='new Date()'
                                :disabled-dates='{}'
                                ref="calendar"
                                v-model="focus"
                                color="primary"
                                :events="events"
                                :event-color="getEventColor"
                                type="month"
                                @click:event="showEvent"
                                @click:date="showDay"
                                @click:day="showDay"
                                
                                @click:more="showDay"
                            >
                                <template v-slot:event="{ event }">
                                    <span class="calendar-event-item">  
                                       
                                        <span :class="`material-symbols-outlined calendar-event-icon-1`">
                                            {{event.icon}}
                                        </span> 
                                    {{ event.name }}</span>
                                </template>
                            </v-calendar>

                            <!-- DAY -->
                             <v-menu
                                v-model="selectedOpenDay"
                                :close-on-content-click="false"
                                :activator="selectedElementDay"
                                offset-y
                                max-width="330px"
                                class="calendar-max-item-height"
                                
                                >
                                <v-card
                                    color="grey lighten-4"
                                    min-width="330px"
                                    max-width="330px"
                                    flat
                                    class="calendar-card-item calendar-max-item-height"
                                >
                                    <div class="calendar-item-close-btn">
                                        <v-btn
                                            elevation="0"
                                            icon
                                            small
                                            
                                            max-height="25"
                                            max-width="25"
                                            @click="selectedOpenDay = false"
                                        >
                                            <v-icon dark  class="">
                                                mdi-close-circle-outline
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                    
                                    <div v-for="(item, index) in dayEvents" :key="index" class="calendar-selected-item mb-2">
                                        <!-- <div class="calendar-selected-item-icon">
                                            
                                        </div> -->
                                        <v-avatar class="calendar-selected-item-avatar" :color="getEventTypeColor(item.type)" size="24">
                                            <span :class="`material-symbols-outlined calendar-event-icon-1 calendar-selected-item-icon`">
                                                {{item.icon}}
                                            </span> 
                                        </v-avatar>
                                        
                                        <span class="font-weight-bold body-2" v-if="item.details == ''" >{{item.name}}</span>
                                        <span class="font-weight-bold body-2" v-else>{{item.details}}</span>
                                    </div>
                                    <div>
                                        <span :class="`${dayEvents.length == 0 ? 'mx-2' : 'ml-7'} caption`">{{selectedDay}}</span>
                                    </div>
                                </v-card>
                            </v-menu>

                            <!-- EVENT -->
                            <v-menu
                                v-model="selectedOpen"
                                :close-on-content-click="false"
                                :activator="selectedElement"
                                offset-y
                                max-width="330px"
                                class="calendar-max-item-height"
                                >
                                <v-card
                                    color="grey lighten-4"
                                    min-width="330px"
                                    max-width="330px"
                                    flat
                                    class="calendar-card-item calendar-max-item-height"
                                >
                                    <div class="calendar-item-close-btn">
                                        <v-btn
                                            elevation="0"
                                            icon
                                            small
                                            
                                            max-height="25"
                                            max-width="25"
                                            @click="selectedOpen = false"
                                        >
                                            <v-icon dark  class="">
                                                mdi-close-circle-outline
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                    <div class="calendar-selected-item">
                                        <!-- <div class="calendar-selected-item-icon">
                                            
                                        </div> -->
                                        <v-avatar class="calendar-selected-item-avatar" :color="getEventTypeColor(selectedEvent.type)" size="24">
                                            <span :class="`material-symbols-outlined calendar-event-icon-1 calendar-selected-item-icon`">
                                                {{selectedEvent.icon}}
                                            </span> 
                                        </v-avatar>
                                        <span class="font-weight-bold body-2" v-if="selectedEvent.details == ''" >{{selectedEvent.name}}</span>
                                        <!-- <span class="font-weight-bold body-2" >{{selectedEvent.details}}</span> -->
                                        <span class="font-weight-bold body-2" v-else>{{selectedEvent.details}}</span>
                                    </div>
                                </v-card>
                            </v-menu>
                        </div>
                    </v-col>
                </v-row>
				
			</v-card-text>
		</v-card>

       
		
	</div>
</template>
<script>
import Utils from '../../../helpers/Utils'
export default {
    data(){
		return {
            calendayKey: 999,
            focus: '',
            actualMonth: 0,
            actualYear: 0,
            start:"",
            end:"",
            workDate: new Date().setHours(0,0,0,0),
            events: [
               
            ],

            dayEvents:[],
            selectedDay: "Miércoles 7 de Febrero de 2024",

            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,

            selectedElementDay: null,
            selectedOpenDay: false,
            
            loading: true,
			returnBtnData: {
                height: 38,
                text: "<",
                icon: "mdi-chevron-left",
                textClass: "black--text",
				to: "",
				block: false,
                click: ()=>{this.prevMonth()}
            },
            nextBtnData: {
                height: 38,
                text: ">",
                icon: "mdi-chevron-right",
                textClass: "black--text",
				to: "",
				block: false,
                click: ()=>{this.nextMonth()}
            },

		}
	},
    computed: {
        monthName: function () {
            return Utils.capitalizePhrase(new Intl.DateTimeFormat("es", { month: "long" }).format(
                this.workDate
            ))
           
            // return .;
            // return this.workDate.toString();
        },
        yearText: function () {
            return (new Intl.DateTimeFormat("es", { year: "numeric" }).format(
                this.workDate
            ));
        },
       
    },
	mounted(){
        this.workDate = new Date();
        this.workDate.setHours(0,0,0,0);
        this.actualMonth = this.workDate.getMonth() + 1;
        this.actualYear = this.workDate.getFullYear();
		this.index();
	},

    methods: {
        method_handler(object){
			this[object.method_name](object.parameters)
		},

        getStartMonth(date)
        {
            let moStart = new Intl.DateTimeFormat("es", { month: "2-digit" }).format(
                date
            );
            let daStart = "01"
            return `${date.getFullYear()}-${moStart}-${daStart}`;
        },
       
		index() {
			this.loading = true;
            this.events = []
            let moStart = new Intl.DateTimeFormat("es", { month: "2-digit" }).format(
                this.workDate
            );
            let daStart = "01"
            const startDate = `${this.workDate.getFullYear()}-${moStart}-${daStart}`;

            let moEnd = new Intl.DateTimeFormat("es", { month: "2-digit" }).format(
                this.workDate
            );
            let daEnd = Utils.getLastDayMonth(this.workDate)
            const endDate = `${this.workDate.getFullYear()}-${moEnd}-${daEnd}`;

            this.focus = JSON.parse(JSON.stringify(startDate));
            this.start = startDate;
            this.end = endDate
			// call api
			this.$api.calendar.index(startDate, endDate)
				.then((resp) =>{
                    this.events = resp.data.map((event) => {
                        return {
                            name: event.title,
                            start: event.start_date,
                            end: event.end_date,
                            color: this.getEventTypeColor(event.type),
                            details: event.description??event.title,
                            icon: this.getEventIcon(event.type),
                            type: event.type
                        }
                    })
				})
				.catch((error) =>{
					console.log(error);
                    this.$store.dispatch('snackbarError', "Error al cargar el calendario");
				})
				.finally(() => {
					// this.tableProps.loading = false;
                    this.loading = false;
                    this.calendayKey = this.calendayKey + 1
				})
			;
			// console.log(test);
			
			
			
		},

        nextMonth()
        {
            this.actualMonth = this.actualMonth + 1;
            if(this.actualMonth%13 == 0){
                this.actualMonth = 1;
                this.actualYear = this.actualYear + 1;
            }
            // console.log(this.actualMonth, this.actualMonth%13)
            let res = new Date(`${this.actualYear}-${(this.actualMonth%13)}-01 00:00:00`);
            this.workDate = res;
            this.index()
            // console.log(res)
        },

        prevMonth()
        {
            this.actualMonth = this.actualMonth - 1;
            // console.log(this.actualMonth, this.actualMonth%13)
            if(this.actualMonth%13 == 0){
                this.actualMonth = 12;
                this.actualYear = this.actualYear - 1;
            }
            let res = new Date(`${this.actualYear}-${(this.actualMonth%13)}-01 00:00:00`);
            this.workDate = res;
            this.index()
            // console.log(res)
        },


        getEventColor (event) {
            return event.color
        },

        getEventTypeColor(type)
        {
            switch (type) {
                case "BIRTHDAY":
                    return "#40CA01";

                case "ANNIVERSARY":
                    return "#C451D8";

                case "EVENT":
                    return "#FFAD02";

                case "ANNOUNCEMENT":
                    return "#20A2F8";


                case "HOLIDAY":
                    return "#F70003";

                case "VACATION":
                    return "#FA7F08";
                    
            
                default:
                    return "primary";
            }
        },

        getEventIcon(type)
        {
            switch (type) {
                case "BIRTHDAY":
                    return "cake";

                case "ANNIVERSARY":
                    return "celebration";

                case "EVENT":
                    return "person_celebrate";

                case "ANNOUNCEMENT":
                     return "event";


                case "HOLIDAY":
                    return "star";

                case "VACATION":
                    return "beach_access"
                    
            
                default:
                    return "event";
            }
        },

        

        showEvent ({nativeEvent, event}) {
            
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },

        showDay(item, second){
            // console.log(item,this.outRangeDate(item.date))
            if(this.outRangeDate(item.date)){
                if(this.outRangeDatePast(item.date))
                    this.prevMonth();
                else
                    this.nextMonth()
             
                return;
            }
            const date = new Date(`${item.date} 00:00:00`);
            // this.selectedDay = 
            this.selectedDay = Utils.capitalizePhrase(new Intl.DateTimeFormat("es", {
                dateStyle: 'full',
                
            }).format(date))
            this.getEventsDate(item.date)

            const open = () => {
                this.selectedElementDay = second.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpenDay = true))
            }

            if (this.selectedOpenDay) {
                this.selectedOpenDay = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            second.stopPropagation()
        },

        getEventsDate(dateString)
        {
            const date = new Date(dateString).setHours(0,0,0,0);
            this.dayEvents = [];
            for (let i = 0; i < this.events.length; i++) {
                const event = this.events[i];
                const dateStart = new Date(event.start.substring(0,10)).setHours(0,0,0,0);
                const dateEnd = new Date(event.end.substring(0,10)).setHours(0,0,0,0);
                // console.log((date > dateStart || date == dateStart), date, dateStart, dateEnd)
                if ((date >= dateStart) && (date <= dateEnd)) {
                    this.dayEvents.push(event);
                } 
            }
        },

        outRangeDate(dateString)
        {
            const date = new Date(dateString).setHours(0,0,0,0);
            const dateStart = new Date(this.start.substring(0,10)).setHours(0,0,0,0);
            const dateEnd = new Date(this.end.substring(0,10)).setHours(0,0,0,0);
            // console.log(dateString, this.start, this.end)
            if ((date >= dateStart) && (date <= dateEnd)) {
               return false
            } 
            return true;

        },
        outRangeDatePast(dateString)
        {
            const date = new Date(dateString).setHours(0,0,0,0);
            // const dateStart = new Date(this.start.substring(0,10)).setHours(0,0,0,0);
            const dateEnd = new Date(this.end.substring(0,10)).setHours(0,0,0,0);
            // console.log(dateString, this.start, this.end)
            if ( (date <= dateEnd)) {
               return true
            } 
            return false;

        },
        



    }
}
</script>

<style lang="scss">
    

    .testets{
        height: calc(100vh - 220px);
    }

    .calendar{

        &-week-names{
            display: flex;
            justify-content: space-between;
            border-bottom: 2px solid black;
            span{
                text-align: center;
                flex-basis: 14.28%;
                font-weight: bold;
                font-size: 0.99rem;

                @media (max-width: #{map-get($grid-breakpoints, 'sm')}) {
                    font-size: 0.5rem;
                }
            }
        }

        &-loading{
            height: -webkit-fill-available;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-index-inputs{
            display: flex;
            align-items: center;
            gap: 10px !important;
        }

        &-event-item{
            display: flex;
            align-items: center;
            gap: 3px !important;
        }

        &-event-icon-1{
            font-size: 15px;
            margin-left: 2px;
            color: white;
        }



        &-selected-item{
            display: flex;
            align-items: center;
            gap: 5px;

            &-avatar{
                align-self: baseline;
            }

            &-icon{
                margin-right: 2px;
            }
        }


        &-card-item{
            position: relative;
            padding: 15px 28px 10px 5px ;
            overflow: overlay;
        }

        &-item-close-btn{
            position: absolute;
            right: 3px;
            top: 0px;
        }


        &-max-item-height{
            max-height: 300px;
            // height: 100px;
        }

    }

 
</style>