<template>
    <v-chip :color="color" dark>
        {{text}}
    </v-chip>
</template>

  
<script>
	export default {
		name: 'ChipDataTableItem',
        props: ['color', 'text']
	};
</script>
