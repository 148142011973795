<template>
    <div>
   
        <v-row>
            <v-col>
                <textFieldForm 
                    @valueChange="(v) => {values.description = v.trim(); delete serverErrors['description']}" 
                    :valueInput="values.description" 
                    :rule="rules.description" 
                    label="Descripción"
                    :errorMessages="serverErrors['description']"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <textFieldForm 
                    @valueChange="(v) => {values.model = v.trim(); delete serverErrors['model']}" 
                    :valueInput="values.model" 
                    :rule="rules.model" 
                    label="Modelo"
                    :errorMessages="serverErrors['model']"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <textFieldForm 
                    @valueChange="(v) => {values.brand = v.trim(); delete serverErrors['brand']}" 
                    :valueInput="values.brand" 
                    :rule="rules.brand" 
                    label="Marca"
                    :errorMessages="serverErrors['brand']"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <textFieldForm 
                    @valueChange="(v) => {values.serial_number = v.trim(); delete serverErrors['serial_number']}" 
                    :valueInput="values.serial_number" 
                    :rule="rules.serial_number" 
                    label="No. De serie"
                    :errorMessages="serverErrors['serial_number']"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <textFieldForm 
                    @valueChange="(v) => {values.color = v.trim(); delete serverErrors['color']}"
                    :valueInput="values.color" 
                    :rule="rules.color" 
                    label="Color"
                    :errorMessages="serverErrors['color']"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <textFieldForm 
                    @valueChange="(v) => {values.comments = v.trim(); delete serverErrors['comments']}" 
                    :valueInput="values.comments" 
                    :rule="rules.comments" 
                    label="Comentarios"
                    :errorMessages="serverErrors['comments']"
                ></textFieldForm>
            </v-col>
        </v-row>
               
               
               
               
               

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
   
    data(){
        return {
            rules: {
				description: [
					v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				],
				model: [
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				],
                brand: [
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				],
                serial_number: [
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				],
                color: [
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				],
                comments: [
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				]
				
            },
            
        }
    },
   
    mounted(){
       
    },
    methods: {
      
    }
}
</script>

<style>

</style>