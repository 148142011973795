<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-left col-md-6">
                        <div class="font-weight-bold body-1">
                            Nuevo usuario
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="6">
                        <v-form ref="form" @submit.prevent="save">
                            <user-form ref="UserForm" :values="form" @save="save" :serverErrors="serverErrors"></user-form>
                        </v-form>
                    </v-col>
                    
                </v-row>

                <!-- btns -->
               <!-- <v-row justify="center" align="end">
                    <v-col cols="12" md="6" class="">
                        <v-row justify="end" class="px-3 py-3">
                            <secondary-button :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                            <primary-button :props="saveBtnData" class=""></primary-button>  
                        </v-row>
                    </v-col>
                </v-row> -->
            </div>


            <v-row justify="center" align="end">
				<v-col cols="12" md="6" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
           
        </template>
    </contentCard>
</template>

<script>
import Utils from '../../../../helpers/Utils';
import UserForm from "./Form.vue";
export default {
    components: {
        'user-form': UserForm,
    },
	data(){
		return {
            loading: false,
            serverErrors: {},
            form: {
                company_id: "",
                employee_id: "",
                role_id: "",
                companies_list:[],
                password: "",
                password_confirmation: "",

                email: "",
                // name: "",
                documentation: false,
                status: true,
                refresh_token:"",
                    
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "Users",
                block:false,	
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar",
                icon: "mdi-content-save-outline",	
                click: this.save,
                block:false,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
    },
	methods: {
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
		validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate();
            // return true;
		},
        save(){
            
            //console.log(this.form);
            
            if(this.validate()){
                
                let form = this.processRequest(this.form)
                this.saveBtnData.loading = true;
                this.$store.state.loading = true;
                this.$api.user.store(form)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                        this.$router.push({name: 'Users'});
                    })
                    .catch((error) => {
                        //console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 400: 
                                //console.log(99, error.response);
                                if(("message" in error.response.data)){
                                    Object.keys(error.response.data.message).forEach((x) => {
                                        //console.log(x);
                                        this.serverErrors = error.response.data.message;
                                        error.response.data.message[x].forEach((y) => {
                                            this.serverErrors[x] = Utils.deleteWord(x,y);
                                            errors.push(Utils.deleteWord(x,y))
                                        })
                                    });
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },

        processRequest(request)
        {
            let data = JSON.parse(JSON.stringify(request));


            //mientras está listo
            delete data.business;

            data.role_id = ""+data.role_id;

            return data;
        }
    },
}
</script>

<style>

</style>