<template>
    <div class="accountConfirmation-container">
        <v-row justify="center">
            <v-col align-self="center" cols="12" class="text-center accountConfirmation-message" >
                Se ha enviado al correo las instrucciones para restablecer la contraseña
                <br><br><br><br>
                <span class="accountConfirmation-return clic" @click="ToLogin()">Ir al inicio de sesión</span>
            </v-col>
            
        </v-row>
        <div>
            <img :src="companyLogo" alt="" class="accountConfirmation-company">
        </div>
    </div>
   
</template>

<script>
// import image from '../../assets/backgroundLogin.png';
import companyLogo from '../../assets/LOGO_GRUPO_MERCA.png';

// import VueGatesPlugin from '../plugins/vue-gates';
export default {
  components: {  },
	name: 'Login',
	data: () => ({
        companyLogo,
		

	}),
	methods: {
		ToLogin(){
            this.$router.push({name: 'Login'});
        }
        
	}
}
</script>


<style lang="scss">
    .accountConfirmation{

        &-container{
            display: flex;
            flex-direction: column;
            position: relative;
        }

        &-message{
            font-size: 1.7rem;
            font-weight: 500;
        }

        &-return{
            font-size: 0.8rem;
            font-weight: 400;
        }

        &-company{
            // width: 100%;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 60px;
        }
    }
</style>

