<template>
    <div class="requestPassword-container">
        <div class="requestPassword-form">



            <v-form ref="form" @submit.prevent="save" >
                <v-row justify="center" >
                    <v-col class="text-center" >
                        <div class="font-weight-bold body-2">
                            Olvidé mi contraseña
                        </div>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col class="py-2" cols="12" sm="9">
                        <div class="requestPassword-message">
                            Ingresa el correo electrónico asociado a tu
                            cuenta y te enviaremos instrucciones para
                            ayudarte a restablecer tu contraseña
                        </div>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col class="py-2" cols="12" sm="9">
                        <textFieldForm 
                            @valueChange="(v) => {form.email = v.trim(); serverErrors = {} }" 
                            :valueInput="form.email" 
                            :rule="rules.email" 
                            label="Correo"
                            :errorMessages="serverErrors['email']"
                            
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col cols="12" sm="9"  class="">
                        <!-- <v-row justify="end" class="px-3 py-3"> -->
                            <primary-button :props="saveBtnData" class=""></primary-button>  
                        <!-- </v-row> -->
                    </v-col>
                </v-row>

                 <v-row justify="center">
                    <v-col class="py-2" cols="12" sm="9">
                        <div class="requestPassword-return-btn clic" @click="ToLogin()">
                            Regresar al inicio de sesión
                        </div>
                    </v-col>
                </v-row>

            </v-form>
            

        </div>
    </div>
</template>

<script>
import Utils from '../../helpers/Utils';
export default {
    components: {
    },
	data(){
		return {
            loading: false,
            serverErrors: {},
            form: {
                email: '',
             
            },
            
            saveBtnData: {
                text: "Solicitar nueva contraseña",
                block: true,
                icon: "",	
                click: this.save,
                loading: false,
            },

            rules: {
				required: [
					v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				],
                email: [
                    //v => !!v || 'El campo es requerido'
                    v => {
                        if(!v)
                        return 'El campo es requerido';
                        if(v.length > 0) {
                            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                            return pattern.test(v) || 'Correo no válido';
                        }
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true
                    }
                ],
				
				
            },
            
		}
    },
    mounted(){
        // window.addEventListener('resize', this.onResize, { passive: true });
        // this.onResize();
    },
	methods: {
        ToLogin(){
            this.$router.push({name: 'Login'});
        },
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
            }
        },
		validate () {
            console.log(this.serverErrors)
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate()
		},
        save(){
            this.saveBtnData.loading = true;
            console.log(this.form);
            
            if(this.validate()){
                
                let form = JSON.parse(JSON.stringify(this.form))
                
                this.$api.auth.resetPasswordRequest(form)
                    .then(() => {
                        //console.log(response)

                        // this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                        this.$router.push({name: 'RequestPasswordSend'});
                    })
                    .catch((error) => {
                        //console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 400: 
                                //console.log(99, error.response);
                                if(("message" in error.response.data)){
                                    // if(Array.isArray(error.response.data.message)){
                                        Object.keys(error.response.data.message).forEach((x) => {
                                            //console.log(x);
                                            this.serverErrors = error.response.data.message;
                                            error.response.data.message[x].forEach((y) => {
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                errors.push(Utils.deleteWord(x,y))
                                            })
                                            
                                        });
                                    // }else{
                                    //     console.log(error.response.data, "elfe")
                                    //     errors.push(error.response.data.message)
                                    // }
                                    
                                    // this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }

                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                    })
                ;
            }else{
                this.saveBtnData.loading = false;
                this.$store.state.loading = false;
                // this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        }
    },
}
</script>

<style lang="scss">
    .requestPassword{

        &-container{
            z-index: 1000;
            display: flex;
            flex-direction: column;
            justify-items: center;
            justify-content: center;
            align-items: center;
            
        }

        &-message{
            line-height: 1.2rem;
            // background-color: red;
            border-left: var(--v-primary-base) 8px solid;
            padding: 0 5px;
        }

        &-form{
            display: flex;
            flex-direction: column;
            flex-direction: column;
            justify-items: center;
            justify-content: center;
            align-items: center;

            background-color: white;
            min-height: 280px;
            width: 50%;
            padding: 2rem 1rem;

            @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
               width: 80%;
                
            }

            @media (max-width: #{map-get($grid-breakpoints, 'sm')}) {
               width: 95%;
                
            }

        }

        &-return-btn{
            font-size: .7rem;
            text-align: center;
            margin-top: 2rem;
        }
    }
</style>