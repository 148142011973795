<template>
    <div>
        <v-row>
            <v-col class="pb-0">
               <p class="mb-0">{{values.name}}</p>
            </v-col>
        </v-row>
        <v-radio-group
            v-model="values.step"
            mandatory
        >

            <v-row>
                <v-col cols="12">
                    <radioItemForm
                        label="Entrevista"
                        valueInput="ENTREVISTA"
                        title="Estatus"
                    >
                    </radioItemForm>
                    <v-row>
                        <v-col cols="6">
                            <textFieldForm
                                label="Comentarios"
                                :valueInput="values.status[0].comments"
                                :rule="rules.interviewComment"
                                @valueChange="(v) => {values.status[0].comments = v.trim(); delete serverErrors[`status.0.comments`]}"
                                :errorMessages="serverErrors[`status.0.comments`]"
                            ></textFieldForm>
                        </v-col>
                       
                        <v-col cols="6">
                            <datePickerForm
                                label="Fecha"
                                :valueInput="values.status[0].date"
                                :rule="rules.date_interview"
                                @valueChange="(v) => {values.status[0].date = v; delete serverErrors[`status.0.date`]}"
                                :errorMessages="serverErrors[`status.0.date`]"
                            ></datePickerForm>
                        </v-col>
                       
                    </v-row>
                   
                </v-col>
              
            </v-row>


            <v-row>
                <v-col cols="12">
                    <radioItemForm
                        label="Referencias laborales"
                        valueInput="REFERENCIAS LABORALES"
                        title="Estatus"
                    >
                    </radioItemForm>
                    <v-row>
                        <v-col cols="6">
                            <textFieldForm
                                label="Comentarios"
                                :valueInput="values.status[1].comments"
                                :rule="rules.referencesComment"
                                @valueChange="(v) => {values.status[1].comments = v.trim(); delete serverErrors[`status.1.comments`]}"
                                :errorMessages="serverErrors[`status.1.comments`]"
                            ></textFieldForm>
                        </v-col>
                        <v-col cols="6">
                            <datePickerForm
                                label="Fecha"
                                :valueInput="values.status[1].date"
                                :rule="rules.date_references"
                                @valueChange="(v) => {values.status[1].date = v; delete serverErrors[`status.1.date`]}"
                                :errorMessages="serverErrors[`status.1.date`]"
                            ></datePickerForm>
                        </v-col>
                    </v-row>
                    
                </v-col>
                
            </v-row>

            <v-row>
                <v-col cols="12">
                    <radioItemForm
                        label="Pruebas psicométricas"
                        valueInput="PRUEBAS PSICOMÉTRICAS"
                        title="Estatus"
                    >
                    </radioItemForm>
                    <v-row>
                        <v-col cols="6">
                            <textFieldForm
                                label="Comentarios"
                                :valueInput="values.status[2].comments"
                                :rule="rules.psichoComment"
                                @valueChange="(v) => {values.status[2].comments = v.trim(); delete serverErrors[`status.2.comments`]}"
                                :errorMessages="serverErrors[`status.2.comments`]"
                            ></textFieldForm>
                        </v-col>
                        <v-col cols="6">
                            <datePickerForm
                                label="Fecha"
                                :valueInput="values.status[2].date"
                                :rule="rules.date_psicho"
                                @valueChange="(v) => {values.status[2].date = v; delete serverErrors[`status.2.date`]}"
                                :errorMessages="serverErrors[`status.2.date`]"
                            ></datePickerForm>
                        </v-col>
                    </v-row>
                    
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <radioItemForm
                        label="Estudio socioeconómico"
                        valueInput="ESTUDIO SOCIOECONÓMICO"
                        title="Estatus"
                    >
                    </radioItemForm>
                    <v-row>
                        <v-col cols="6">
                            <textFieldForm
                                label="Comentarios"
                                :valueInput="values.status[3].comments"
                                :rule="rules.studyComment"
                                @valueChange="(v) => {values.status[3].comments = v.trim(); delete serverErrors[`status.3.comments`]}"
                                :errorMessages="serverErrors[`status.3.comments`]"
                            ></textFieldForm>
                        </v-col>
                        <v-col cols="6">
                            <datePickerForm
                                label="Fecha"
                                :valueInput="values.status[3].date"
                                :rule="rules.date_study"
                                @valueChange="(v) => {values.status[3].date = v; delete serverErrors[`status.3.date`]}"
                                :errorMessages="serverErrors[`status.3.date`]"
                            ></datePickerForm>
                        </v-col>
                    </v-row>
                    
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <radioItemForm
                        label="Sin respuesta"
                        valueInput="SIN RESPUESTA"
                        title="Estatus"
                    >
                    </radioItemForm>
                    <v-row>
                        <v-col cols="6">
                            <textFieldForm
                                label="Comentarios"
                                :valueInput="values.status[4].comments"
                                :rule="rules.noAnswerComment"
                                @valueChange="(v) => {values.status[4].comments = v.trim(); delete serverErrors[`status.4.comments`]}"
                                :errorMessages="serverErrors[`status.4.comments`]"
                            ></textFieldForm>
                        </v-col>
                        <v-col cols="6">
                            <datePickerForm
                                label="Fecha"
                                :valueInput="values.status[4].date"
                                :rule="rules.date_noAnswer"
                                @valueChange="(v) => {values.status[4].date = v; delete serverErrors[`status.4.date`]}"
                                :errorMessages="serverErrors[`status.4.date`]"
                            ></datePickerForm>
                        </v-col>
                    </v-row>
                   
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <radioItemForm
                        label="Contratación"
                        valueInput="CONTRATACION"
                        title="Estatus"
                    >
                    </radioItemForm>
                    <v-row>
                        <v-col cols="6">
                            <textFieldForm
                                label="Comentarios"
                                :valueInput="values.status[5].comments"
                                :rule="rules.hireComment"
                                @valueChange="(v) => {values.status[5].comments = v.trim(); delete serverErrors[`status.5.comments`]}"
                                :errorMessages="serverErrors[`status.5.comments`]"
                            ></textFieldForm>
                        </v-col>
                        <v-col cols="6">
                            <datePickerForm
                                label="Fecha"
                                :valueInput="values.status[5].date"
                                :rule="rules.date_hire"
                                @valueChange="(v) => {values.status[5].date = v; delete serverErrors[`status.5.date`]}"
                                :errorMessages="serverErrors[`status.5.date`]"
                            ></datePickerForm>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-radio-group>
    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
   
    data(){
        return {
          
            
            rules: {
                interviewComment:[
                    v => {
                        if(v == null)
                            return true;
                        if(!!v && v.length > 255){
                            return 'Máximo 255 caracteres';
                        }
                        try {
                            if(v.length > 0){
                                if(v.trim() == "")
                                    return "Campo requerido";
                            }
                            
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],
                referencesComment:[
                    v => {
                        if(v == null)
                            return true;
                        if(!!v && v.length > 255){
                            return 'Máximo 255 caracteres';
                        }
                        try {
                            if(v.length > 0){
                                if(v.trim() == "")
                                    return "Campo requerido";
                            }
                            
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],
                psichoComment:[
                    v => {
                        if(v == null)
                            return true;
                        if(!!v && v.length > 255){
                            return 'Máximo 255 caracteres';
                        }
                        try {
                            if(v.length > 0){
                                if(v.trim() == "")
                                    return "Campo requerido";
                            }
                            
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],
                studyComment:[
                    v => {
                        if(v == null)
                            return true;
                        if(!!v && v.length > 255){
                            return 'Máximo 255 caracteres';
                        }
                        try {
                            if(v.length > 0){
                                if(v.trim() == "")
                                    return "Campo requerido";
                            }
                            
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],
                noAnswerComment:[
                    v => {
                        if(v == null)
                            return true;
                        if(!!v && v.length > 255){
                            return 'Máximo 255 caracteres';
                        }
                        try {
                            if(v.length > 0){
                                if(v.trim() == "")
                                    return "Campo requerido";
                            }
                            
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],
                hireComment:[
                    v => {
                       
                        if(!!v && v.length > 255){
                            return 'Máximo 255 caracteres';
                        }
                        try {
                            if(v.length > 0){
                                if(v.trim() == "")
                                    return "Campo requerido";
                            }
                            
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ]
              
             
            },
            serverItems: {
            },

          
        }
    },
    mounted(){
        
    },
    methods: {
        
    }
}
</script>

<style lang="scss">
</style>