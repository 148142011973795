<template>
    <div>
        <!-- <v-card class="mb-4 mt-0" elevation="0"> -->
            <!-- <v-card-text> -->
                <v-row>
                    <v-col>
                       <textFieldForm 
                            @valueChange="(v) => {values.name = v.trim(); delete serverErrors.name}" 
                            :valueInput="values.name" 
                            :rule="rules.name" 
                            label="Nombre"
                            :errorMessages="serverErrors.name"
                        ></textFieldForm>
                    </v-col>
                </v-row>
                
               
                
            <!-- </v-card-text> -->
        <!-- </v-card> -->

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
   
    data(){
        return {
            
            
            rules: {
				name: [
					v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				],
				
            },
           

          
        }
    },
    watch: {
        values: function (vv) {
            //console.log(this.values, vv);
        }
    },
    mounted(){
        
    },
    methods: {

       
        
    }
}
</script>

<style>

</style>