<template>
    <div class="detail-item-employee-card-item mb-2" >
        <div class="detail-item-employee-card-item-texts">
            <span class="detail-item-employee-card-item-title">{{title}}</span>
            <span class="detail-item-employee-card-item-subtitle">{{subtitle}}</span>
        </div>
        <div class="detail-item-employee-card-item-icon">
            <v-icon small>{{icon}}</v-icon>
        </div>
    </div>
    

</template>
<script>
export default {
    props: ['title', 'subtitle', 'icon'],
    model: {
        
    },
    computed: {
       
    }
}
</script>

<style lang="scss">
     .detail-item-employee-card{
        &-message{
            font-size: 0.8rem;
        }

        &-container{
            line-height: 15px !important;

        }


        &-item{
            padding: 1px 7px;
            display: flex;
            justify-content: space-between;
            background-color: #e8e8e8;
            border-radius: 8px;
            border-left: 7px solid var(--v-primary-base);
        }

        &-item-texts{
            padding: 8px 5px;
            display: flex;
            flex-direction: column;

        }

        &-item-title{
            font-size: 0.7rem;
            font-weight: 500;
        }

        &-item-subtitle{
            font-size: 0.65rem;
        }
    }
</style>