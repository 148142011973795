<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center" >
                    <v-col class="text-left col-md-6">
                        <div class="font-weight-bold body-1">
                            Detalles Puesto - {{items[1].valor}}
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="6" >

                    <details-item :detailsItems="items"></details-item>


                    </v-col>
                    
                </v-row>
                
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" class="d-flex justify-end">
                    
					<primary-button :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../../components/DetailsItem.vue';
import Utils from '../../../../helpers/Utils';
export default {
    components: {
        DetailsItem
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
           
            items: [
                { concepto: 'Código', index: 'code', valor: '' },
                { concepto: 'Nombre', index: 'name', valor: null },
                { concepto: 'Empresa', index: 'area.company.name', valor: null},
                { concepto: 'Área', index: 'area.name', valor: null},
                { concepto: 'Departamento', index: 'department.name', valor: null},
                { concepto: 'Jefe de área', index: 'area_manager', valor: null},
                { concepto: 'Jefe de RH', index: 'rh_manager', valor: null},
                { concepto: 'Requiere viajar', index: 'requires_travel', valor: null},
                { concepto: 'A cargo de gente', index: 'have_persons_in_charge', valor: null},
                { concepto: 'Requiere inglés', index: 'requires_english', valor: null},
                { concepto: 'Nivel de inglés requierido', index: 'english_level', valor: null},
                { concepto: 'Propósito del puesto', index: 'purpose', valor: null},
                { concepto: 'Reporta a', index: 'report_to.name', valor: null},
                { concepto: 'Supervisa a', index: 'supervise_to.name', valor: null},
                { concepto: 'Grado de estudios', index: 'studies', valor: null},
                { concepto: 'Experiencia previa (años)', index: 'experience', valor: null},
                { concepto: 'Formación (conocimientos específicos)', index: 'background', valor: null},
                { concepto: 'Habilidades', index: 'abilities', valor: null},
                { concepto: 'Responsabilidades', index: 'responsibilities', valor: null},
                { concepto: 'Tiene autoridad de', index: 'authority', valor: null},
            ],
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "Positions",
                block:false,
                click: () => {}
            },
         
          
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        this.index();
    },
	methods: {
        onResize () {
            
            if(window.innerWidth < 600){
                this.cancelBtnData.block = true;
            }
            else{
                this.cancelBtnData.block = false;
            }
        },
		validate () {
			return this.$refs.form.validate()
		},
        
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.positions.get(this.id)
			.then((response) => {
                this.items.forEach((y) => {
                    y.valor = Utils.getObjectValue(response.data,y.index);
                    if(y.index == 'area_manager' || y.index == 'rh_manager' || y.index == 'requires_travel' || y.index == 'requires_english' || y.index == 'have_persons_in_charge')
                        y.valor = y.valor ? 'Si' : 'No';
                    
                });
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 