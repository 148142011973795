<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="3" lg="3" class="mx-0 px-0">
                        <alternativeTextField2
                            @onChanged="(v) => {trySearch(v);}" 
                            :valueInput="tableProps.search"
                            :rule="[]"
                            :placeholder="'Buscar'"
                            :label="''"
                            data-cy="search-input"
                            :prependInnerIcon="'mdi-magnify'"
                            class="pick-3 mr-1"
                        ></alternativeTextField2>
					</v-col>
					<v-spacer></v-spacer>
					<v-col v-permission="'events.create'" cols="12" md="3" lg="2" class="mx-0 px-0">
                        <primaryButton :props="createBtnData"></primaryButton>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0"> 
						<datatable :props="tableProps" @method_handler="method_handler"></datatable>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <modal :props="modalProps" @modalResponse="method_handler">
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					<v-col cols="12" md="4">
						<secondaryButton
						:props="cancelModal"
						></secondaryButton>
					</v-col>
					<v-col cols="12" md="4">
						<primaryButton
						:props="acceptModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>
		
	</div>
</template>
<script>
import Utils from '../../../helpers/Utils'
export default {
    data(){
		return {
			selectedID: '',
			actions: [
				{
					name: 'Detail',
					icon: {
						color: 'secondary',
						icon: 'mdi-information-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'events.show'
				},
				{
					name: 'Edit',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/obras/1/edicion',
                    permissions: 'events.update'
				},
				{
					name: 'Delete',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItemModal',
                    permissions: 'events.delete'
				},
			],
			tableProps: {
				headers: [
					{
						text: 'Nombre',
						align: 'left',
						value: 'name',
						class: 'table-b-border'
					},
                    {
						text: 'Dirige',
						align: 'left',
						value: 'speaker',
						class: 'table-b-border'
					},
                    {
						text: 'Fecha',
						align: 'left',
						value: 'start_date',
						class: 'table-b-border'
					},
                    {
						text: 'Vigencia',
						align: 'left',
						value: 'end_date',
						class: 'table-b-border'
					},
                    {
                        text: 'Estatus',
                        align: 'left',
                        value: 'status',
                        class: 'table-b-border'
                    },
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						class: 'table-b-border'
					}
				],
				items: [],
				search: '',
				loading: false
			},
			
            createBtnData: {
                text: "Nuevo evento",
                icon: "mdi-plus",
				to: "EventsCreate",
				block: true,
                click: ()=>{}
            },

			modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},
			
			cancelModal: {
                text: "No, regresar",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.modalProps.visible = false}
            },
			acceptModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
				loading:false,
                click: ()=>{this.delete()}
            },
		}
	},

	mounted(){
		this.index();
	},

    methods: {
        method_handler(object){
			this[object.method_name](object.parameters)
		},
        trySearch(val){
            this.tableProps.search = val;
        },
		index() {
			this.tableProps.loading = true;

			//call api
			this.$api.event.index()
				.then((resp) =>{
					this.tableProps.items = resp.data.map((x) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))
						x.actions[0].action = { name: 'EventsDetails', params: { id: x.id } };
						x.actions[1].action = { name: 'EventsEdit', params: { id: x.id } };
						x['parameters'] = { id: x.id, name: x.name};

                        x.status = x.status == "active" ? "Activo" : "Inactivo";

                        x.start_date = Utils.globalDateFormat(x.start_date);
                        x.end_date = Utils.globalDateFormat(x.end_date);

						return x
					});
				})
				.catch((error) =>{
					console.log(error, "error desde areas");
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
			//console.log(test);
			
			
			
		},

		deleteItemModal(parameters){
			this.selectedID = parameters.id;
			this.modalProps = {
				...this.modalProps,
				text: `¿Estás seguro de eliminar "${parameters.name}"?`,
				
			}
			this.modalProps.visible = true;
			
		},
		delete(){
			this.acceptModal.loading = true;
			this.$api.event.delete(this.selectedID)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se eliminó el activo`);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				

				this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
			})
			.finally(() => {
				this.modalProps.visible = false;
				this.acceptModal.loading = false;
			})
		},


    }
}
</script>