<template>

    <!-- <div class="accountConfirmation-container">
       
    </div> -->

     <contentCardMobile :loading="loading" :elevation="0" classCard="px-0 mx-0 my-auto" cardColor="transparent">
        <template slot="cardContent">
            <div style="height: 100%;">
            <v-row justify="space-around">
                <v-col cols="12" md="5" >
                    <div class="font-weight-bold h1 text-center py-4">
                        <h1>Bienvenido/a</h1>
                    </div>
                    <div class="font-weight-bold text-center">
                       Antes de comenzar establece una contraseña segura
                    </div>
                </v-col>
            </v-row>
    
    
            <v-row justify="space-around">
                <v-col cols="12" md="5" class="employee-request-vacation-container pb-1">
                    <span class="employee-request-vacation-message">
                        Recuerda que no debes compartir tus contraseñas con otros compañeros para salvaguardar
                        la seguridad de tu información.
                    </span>
                </v-col>
            </v-row>
    
            <v-row justify="center" >
                <v-col cols="12">
                    <v-form ref="form" @submit.prevent="save">
                        <request-form :values="form" @save="save" :serverErrors="serverErrors"></request-form>
                    </v-form>
                </v-col>
                
            </v-row>
        
            
        </div>
    
        <v-row justify="center" align="end">
            <v-col cols="12" md="5" xl="5" class="">
                <v-row justify="end" class="px-3 py-3">
                    <primary-button :props="saveBtnData" class=""></primary-button>  
                </v-row>
            </v-col>
        </v-row>
        </template>
    </contentCardMobile>


</template>

<script>
import Utils from '../../../helpers/Utils';
import Form from "./FormRequest.vue";

export default {
    components: {
        'request-form': Form,
    },
	data(){
		return {
            loading: false,
            serverErrors: {},
            form: {
                password: "",
                password_confirmation: "",
            },
            
           
            cancelBtnData: {
                text: "Cancelar",
                icon: "",
                to: "EmployeeVacations",
                block:true,	
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar",
                icon: "",	
                click: this.save,
                block:true,
                loading: false,
            },
		}
    },
    watch: {
       
        
    },
    mounted(){
        if(!this.$store.state.userData.has_temp_password){
            this.$router.push({name: 'MyInfo'});
        }
    },
	methods: {
        validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate();
            // return true;
		},
        redirect(){
            if("employee_site" in this.$store.state.userData.role.permissions){
                if(this.$store.state.userData.role.permissions.employee_site.show){
                    this.$store.state.userData.has_temp_password = false;
                }
            }
            this.$router.push("/m/my-info");
            
        },
        save(){
            
            //console.log(this.form);
            
            if(this.validate()){
                
                let form = this.form;
                this.saveBtnData.loading = true;
                this.$store.state.loading = true;
                this.$api.employeeEmployee.updatePassword(form)
                    .then(() => {
                        //console.log(response)
                        this.$store.dispatch('snackbarSuccess', `Tu contraseña ha sido cambiada`);
                        this.redirect();
						// this.$router.push({name: 'MyInfo'});

                    })
                    .catch((error) => {
                        //console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 400: 
                                //console.log(99, error.response);
                                if(("message" in error.response.data)){
                                    Object.keys(error.response.data.message).forEach((x) => {
                                        //console.log(x);
                                        if(Array.isArray(error.response.data.message[x])){
                                            this.serverErrors = error.response.data.message;
                                        
                                            error.response.data.message[x].forEach((y) => {
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                errors.push(Utils.deleteWord(x,y))
                                            })
                                            this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                        }else{
                                            console.log(error.response.data.message.message)
                                            this.$store.dispatch('snackbarError', error.response.data.message.message);
                                        }
                                    });
                                   
                                }
                               
                            break;
                           
                        }
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },

        
    },
}
</script>

<style lang="scss">
    .employee-request-vacation{

        &-container{
            line-height: 15px !important;
        }
         &-message{
            font-size: 0.8rem;
        }
    }
    

        
</style>