<template>
    <div v-if="event != null">
           <v-overlay :value="overlay" @click="overlay = !overlay">
                <v-img
                    :src="photo_url"
                    
                    aspect-ratio="1"
                    class="grey lighten-2"
                    height="auto"
                    width="100vw"
                ></v-img>
            </v-overlay>
        <contentCardMobile :loading="loading" :elevation="0" classCard="px-0 mx-0 py-0" cardColor="transparent">
        <template slot="cardContent">
         
			<div>
                <v-row justify="space-around">
                    <v-col cols="12" md="5" >
                        <div class="font-weight-bold body-1 text-center">
                           {{event.name}}
                        </div>
                    </v-col>
                </v-row>

                <v-row justify="center" align="end">
                    <v-col cols="12" md="5" xl="5" class="">
                        <div class="employee-event-detail-image">
                            <v-img
                                :src="event.poster_url"
                                max-height="300"
                                contain
                                class="grey darken-4"
                            ></v-img>
                        </div>

                    </v-col>
                </v-row>

                <v-row justify="center" align="center">
                    <v-col cols="12" md="5" xl="5" class="text-center">
                       

                        <span class="employee-event-detail-subtitle">{{event.fullDate}}</span>
                    </v-col>
                </v-row>

                 <v-row justify="center" align="center">
                    <v-col cols="12" md="5" xl="5" class="text-center">
                       

                        <v-row justify="center" align="center">
                            <v-col cols="3" class="d-flex child-flex" v-for="(image, index) in event.photo_album" :key="index">
                        
                                <v-img
                                    :src="image.photo_url"
                                    
                                    aspect-ratio="1"
                                    class="grey lighten-2"
                                    @click="() => {photo_url = image.photo_url ;overlay = !overlay}"
                                >
                                    <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular
                                        indeterminate
                                        color="black lighten-3"
                                        ></v-progress-circular>
                                    </v-row>
                                    </template>
                                </v-img>

                        </v-col>
                        
                    </v-row>

                    </v-col>
                </v-row>
               
            
                
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="5" xl="5" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <!-- <secondary-button :props="cancelBtnData" class="mb-3"></secondary-button> -->
                        <primary-button :props="bakBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCardMobile>
    </div>
    


</template>

<script>
import Utils from '../../../helpers/Utils';
// import Utils from '../../../helpers/Utils';
export default {
    components: {
    },
	data(){
		return {
            ut: Utils,
            loading: false,
           
            id: this.$route.params.id,
            event: null,
            overlay:false,
            photo_url:"",
           
            bakBtnData: {
                text: "Atrás",
                icon: "",	
                to:"EmployeeEvents",
                click: ()=>{},
                block:true,
                loading: false,
                disabled:false,
            },
            
            
           
		}
    },
    watch: {
      
        
    },
    mounted(){
        this.index();
    },
	methods: {
        index(){
            this.loading = true;
            this.$api.employeeEvents.get(this.id)
				.then((resp) =>{
                    this.event = resp.data;
                    const dateData = Utils.getDateTexts(this.event.start_date.split("-"))
                    this.event.fullDate = `${dateData.dayText} ${dateData.day} de ${dateData.monthText} ${this.event.hour ? this.event.hour+" hrs" : ""}`;
                    
					
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					// this.tableProps.loading = false;
                    this.loading = false;
				})
			;

          
        },
       

        
    },
}
</script>

<style lang="scss">
    .employee-event-detail{

        &-subtitle{
            display: inline-flex;
            text-align: center;
        }
    }
    

        
</style>