<template>
    <div>
        <v-row justify="space-around" class="mb-3">
            <v-col cols="12" md="5" class="py-0">
                <datePickerForm
                    label="Fecha de inicio de mis vacaciones"
                    :valueInput="values.start_date"
                    :rule="rules.required"
                    :hideIcon="true"
                    :min="minDaysStart"
                    @valueChange="(v) => {values.start_date = v; delete serverErrors.start_date}"
                    :errorMessages="serverErrors.start_date"
                    :allowed-dates="allowedDates"
                ></datePickerForm>
            </v-col>
        </v-row>

        <v-row justify="space-around" class="mb-3">
            <v-col cols="12" md="5" class="py-0">
                <datePickerForm
                    label="Fecha de mi último día de vacaciones"
                    :valueInput="values.end_date"
                    :rule="rules.required"
                    :hideIcon="true"
                    :min="minDaysEnd"
                    @valueChange="(v) => {values.end_date = v; delete serverErrors.end_date}"
                    :errorMessages="serverErrors.end_date"
                    :allowed-dates="allowedDates"
                ></datePickerForm>
            </v-col>
        </v-row>

        

        <v-row justify="space-around">
            <v-col cols="12" md="5" >
                <span v-if="disclaimerDays" class="red--text">
                    Tu solicitud excede el límite de 5 días seguidos por 
                    periodo vacacional, esta será evaluada por tu jefe inmediato 
                    previo a su aprobación.
                </span>
            </v-col>
        </v-row>
        
    
    </div>
</template>

<script>
import Utils from '../../../helpers/Utils'
/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit', 'daysOff'],
   
    data(){
        return {
            minDaysStart: Utils.getDateAddedDays(14),
            minDaysEnd: Utils.getDateAddedDays(14),
            disclaimerDays:false,
            ut: Utils,

            lockDays:{
                week:[],
                holidays:[]
            },

          

            rules: {
				required: [
					v => !!v || 'Campo requerido'
				],
				
               
            },
        }
    },
    watch: {
       [`values.start_date`]: function () {
            this.disclaimerDays = false;
            if(this.values.start_date != "" && this.values.end_date != ""){
                const diffDays = (Utils.datediff(Utils.parseDate(this.values.start_date), Utils.parseDate(this.values.end_date)))
                if(diffDays < 0)
                    this.serverErrors['start_date']= "Campo no válido";
                if(diffDays >= 6){
                    
                    this.disclaimerDays = this.checkCountDays(diffDays);
                }
            }
            
        },
        [`values.end_date`]: function () {
            this.disclaimerDays = false;
            if(this.values.start_date != "" && this.values.end_date != ""){
                const diffDays = (Utils.datediff(Utils.parseDate(this.values.start_date), Utils.parseDate(this.values.end_date)))
                if(diffDays < 0)
                    this.serverErrors['end_date']= "Campo no válido"
                if(diffDays >= 6){
                    // this.checkCountDays(diffDays);
                    this.disclaimerDays = this.checkCountDays(diffDays);
                }
            }
        },
    },
    mounted(){
        // this.processOffDays(this.daysOff)
        this.lockDays = Utils.processDaysOff(this.daysOff);
        // this.minDaysStart =  this.ut.getDateAddedDays(14);
        // console.log(this.minDaysStart, "monnnn")
       
    },
    methods: {
        allowedDates(val){
            
           
            const date = new Date(val);
            //holidays
            if(this.lockDays.holidays.includes(val))
                return null;

            //days off
            if(this.lockDays.week.includes(date.getDay()))
                return null;

            return val;
        },

        checkCountDays(diff){
            let count = 0;
            for (let i = 0; i < diff + 1; i++) {
                let st = Utils.onlyAddDays(this.values.start_date, i)
                if(this.allowedDates(st)!= null){
                    count++;
                }
            }
            
            return count >= 6;
        }
       
    }
}
</script>

<style>

</style>