<template>
    <div>


        <v-row>
            <v-col>
              <datePickerForm
                    
                    label="FECHA"
                    :valueInput="values.date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.date = v; delete serverErrors.date}"
                    :errorMessages="serverErrors.date"
                ></datePickerForm>
               
            </v-col>
        </v-row>
       
        <v-row>
            <v-col>
                <textFieldForm 
                    
                    @valueChange="(v) => {values.name = v.trim(); delete serverErrors.name}" 
                    :valueInput="values.name" 
                    :rule="rules.name" 
                    label="NOMBRE DE LA FESTIVIDAD"
                    :errorMessages="serverErrors.name"
                ></textFieldForm>
               
            </v-col>
        </v-row>

        

       

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', ],
   
    data(){
        return {
            rules: {
				required: [
					v => !!v || 'Campo requerido'
				],
                name:[
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ]
				
				
            },
            

          
        }
    },
    watch: {
        values: function (vv) {
            //console.log(this.values, vv);
        }
    },
    mounted(){
        
    },
    methods: {
        
       
    }
}
</script>

<style lang="scss">
    .no-label .v-text-field .v-input__control .v-input__slot input, textarea{
        margin-top: 0 !important;
    }
</style>