<template>
   <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center" >
                    <v-col class="text-left col-md-5">
                        <div class="font-weight-bold body-1">
                            Registrar Vacaciones
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="5">
                        <v-form ref="form" @submit.prevent="save">
                            <vacation-form ref="Vacationform" :values="form" @save="save" :serverErrors="serverErrors" :editMode="false" :daysOff="daysOff"></vacation-form>
                        </v-form>
                    </v-col>
                    
                </v-row>

            </div>

           <v-row justify="center" align="end">
				<v-col cols="12" md="5" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
   </contentCard>
</template>

<script>
import Vacationform from "./VacationForm.vue";
import Utils from "../../../../helpers/Utils";
export default {
    components: {
        'vacation-form': Vacationform,
    },
	data(){
		return {
            loading: false,
            serverErrors: {},
            id: this.$route.params.id,
            daysOff: {
                business_days:[],
                resting_days:[],
                holidays:[]
            },
            form: {
                employee_id: '',
                company: '',
                //type: '',
                start_date: '',
                end_date: '',
                comments: '',

                required_supervisor_auth: false,    //??????
                
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "Employees",	
                block:false,
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar",
                icon: "mdi-content-save-outline",	
                click: this.save,
                loading: false,
                block:false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        this.index();
    },
	methods: {
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
        getOffDays(){
            console.log("days")
            this.loading = true;
            this.$api.employees.getDaysOff(this.id)
				.then((resp) =>{
                    this.daysOff = resp.data;
					
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.loading = false;
                    this.$store.state.loading = false;
				})
			;
        },
        index(){
            //this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.employees.get(this.id)
			.then((response) => {
                this.form.company = response.data.current_contract.branch_office.company.id;
                this.form.employee_id = response.data.id

               
                this.getOffDays();                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				// this.loading = false;
                // this.$store.state.loading = false;
                // this.$refs.form.reset();
			})
        },
		validate () {
			return this.$refs.form.validate()
		},
        save(){
            this.saveBtnData.loading = true;
            this.$store.state.loading = true;
           
           
            //console.log(this.form);
            if(this.validate()){
                
                const request = this.prepareRequest(this.form);
                
                this.$api.vacation.store(request)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                        this.$router.push({name: 'Employees'});
                    })
                    .catch((error) => {
                        //console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 400: 
                                //console.log(99, error.response);
                                if(("message" in error.response.data)){
                                    Object.keys(error.response.data.message).forEach((x) => {
                                        //console.log(x);
                                        this.serverErrors = error.response.data.message;
                                        error.response.data.message[x].forEach((y) => {
                                            this.serverErrors[x] = Utils.deleteWord(x,y);
                                            errors.push(Utils.deleteWord(x,y))
                                        })
                                    });
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                            
                           
                        }

                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                this.saveBtnData.loading = false;
                this.$store.state.loading = false;
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },

        /**
         * Process data
         */
        prepareRequest(data){
            let newData = JSON.parse(JSON.stringify(data));

            //newData.amount = parseFloat(parseFloat(newData.amount).toFixed(2));
            // newData.required_supervisor_auth = !newData.required_supervisor_auth;
            
            return newData;
        }
    },
}
</script>

<style>

</style>