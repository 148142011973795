<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center" >
                    <v-col class="text-left col-md-5">
                        <div class="font-weight-bold body-1">
                            Nueva Locación
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="6" lg="5">
                        <v-form ref="form" @submit.prevent="save">
                            <locations-form ref="LocationsForm" :values="form" @save="save" :serverErrors="serverErrors"></locations-form>
                        </v-form>
                    </v-col>
                    
                </v-row>

            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="5" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import Utils from '../../../../helpers/Utils';
import LocationsForm from "./Form.vue";
export default {
    components: {
        'locations-form': LocationsForm,
       
    },
	data(){
		return {
            loading: false,
            serverErrors: {},
            form: {
                
                name: '',
                company_id: '',

                country: '',
                street: '',
                ext_number: '',
                int_number: '',
                zip_code: '',
                neighborhood: '',
                city: '',
                state: ''
             
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "Locations",
                block:false,
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar",
                icon: "mdi-content-save-outline",	
                click: this.save,
                block:false,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
    },
	methods: {
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
		validate () {
            
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate();
		},
        save(){
            this.saveBtnData.loading = true;
            this.$store.state.loading = true;
            //console.log(this.form);
            

            if(this.validate()){
                let request = {...this.form, city: this.form.city+''}    
                let form = JSON.parse(JSON.stringify(request))
                
                this.$api.locations.store(form)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                        this.$router.push({name: 'Locations'});
                    })
                    .catch((error) => {
                        console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 400: 
                                if(("message" in error.response.data)){
                                    Object.keys(error.response.data.message).forEach((x) => {
                                        //console.log(x);
                                        this.serverErrors = error.response.data.message;
                                        error.response.data.message[x].forEach((y) => {
                                            this.serverErrors[x] = Utils.deleteWord(x, y);
                                            errors.push(Utils.deleteWord(x, y))
                                        })
                                    });
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                            break;
                           
                        }

                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                this.saveBtnData.loading = false;
                this.$store.state.loading = false;
                this.$store.dispatch('snackbarError', `Formulario incompleto`);
            }
            // eslint-disable-next-line no-console
        }
    },
}
</script>

<style>

</style>