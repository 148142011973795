<template>
    <div>
        <!-- <v-card class="mb-4 mt-0" elevation="0"> -->
            <!-- <v-card-text> -->
                <v-row>
                    <v-col>
                       <textFieldForm 
                            @valueChange="(v) => {values.name = v.trim(); delete serverErrors.name}" 
                            :valueInput="values.name" 
                            :rule="rules.name" 
                            label="Nombre"
                            :errorMessages="serverErrors.name"
                        ></textFieldForm>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                       <autocompleteOneForm
                            label="Empresa"
                            :loading="loadingCompanies"
                            :valueInput="values.company_id"
                            :rule="rules.company_id"
                            :items="serverItems.company"
                            :errorMessages="serverErrors.company_id"
                            @valueChange="(v) => {values.company_id = v; delete serverErrors.company_id}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>
               
                
            <!-- </v-card-text> -->
        <!-- </v-card> -->

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
   
    data(){
        return {
            loadingCompanies: false,
            
            rules: {
				name: [
					v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				],
				company_id: [
                    v => !!v || 'El campo es requerido'
				]
				
            },
            serverItems: {
                company: [],
            },

          
        }
    },
    watch: {
        values: function (vv) {
            //console.log(this.values, vv);
        }
    },
    mounted(){
        this.getCompanies()
    },
    methods: {

       
        getCompanies()
        {
            this.loadingCompanies = true;
            this.$api.user.getUserBusinessSelect()
				.then((resp) =>{
					//console.log(resp, "desde form");
					this.serverItems.company = resp.data.map((item) => {
                        return {text: item.name, value: item.id};
                    });
				})
				.catch((error) =>{
					console.log(error, "error desde form areas");
				})
				.finally(() => {
                    this.loadingCompanies = false;
				})
			;
        }
    }
}
</script>

<style>

</style>