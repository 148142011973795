<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center" >
                    <v-col class="text-left col-md-6">
                        <div class="font-weight-bold body-1">
                            Nuevo evento
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="6">
                        <v-form ref="form" @submit.prevent="save">
                            <event-form ref="EventForm" :values="form" @save="save" :serverErrors="serverErrors" :fullForm="false"></event-form>
                        </v-form>
                    </v-col>
                    
                </v-row>
                
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import Object2Form from '../../../helpers/Object2Form';
import Utils from '../../../helpers/Utils';
import EventForm from "./Form.vue";
export default {
    components: {
        'event-form': EventForm,
    },
	data(){
		return {
            loading: false,
            serverErrors: {},
            form: {
                name: '',
                poster_file:[],
                poster_url: "",
                start_date: "",
                end_date: "",
                hour: "",
                place: "",
                speaker: "",
                comments: "",
                has_attendance: true,
                
                //dump employee list
                assist:[
                    
                    // {name: "nom3", value: false}
                ],
                //dump imgs list
                imgs:[
                    // {url: "https://cdn.vuetifyjs.com/images/john-smirk.png"},
                    // {url: "https://cdn.vuetifyjs.com/images/john-smirk.png"},
                ],
                companies_notified:[
                    // {id:'innovaciones-tecnologicas-mercado-sa-de-cv'}
                ],
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "Events",
                block:false,
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar",
                icon: "mdi-content-save-outline",	
                click: this.save,
                block:false,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
    },
	methods: {
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
		validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate()
           
		},
        save(){
            this.saveBtnData.loading = true;
            this.$store.state.loading = true;
            console.log(this.form);
            
            if(this.validate()){
                
                let form = this.prepareRequest(this.form);
                
                this.$api.event.store(form)
                    .then(() => {
                        this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                        this.$router.push({name: 'Events'});
                    })
                    .catch((error) => {
                        //console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 400: 
                                //console.log(99, error.response);
                                if(("message" in error.response.data)){
                                    Object.keys(error.response.data.message).forEach((x) => {
                                        //console.log(x);
                                        this.serverErrors = error.response.data.message;
                                        error.response.data.message[x].forEach((y) => {
                                            this.serverErrors[x] = Utils.deleteWord(x,y);
                                            errors.push(Utils.deleteWord(x,y))
                                        })
                                    });
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }

                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                this.saveBtnData.loading = false;
                this.$store.state.loading = false;
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },
        prepareRequest(request)
        {
            let data = JSON.parse(JSON.stringify(request));

            delete data.assist;
            delete data.imgs;

            if(request.poster_file instanceof File){
                data.poster_file = request.poster_file;
            }

            data.has_attendance = data.has_attendance == true? 1:0;


            return Object2Form.objectToFormData(data, '', []);
        }
    },
}
</script>

<style>

</style>