<template>
    <div>
       
        <v-row>
            <v-col>
                <textFieldForm 
                    @valueChange="(v) => {values.name = v; delete serverErrors.name}" 
                    :valueInput="values.name" 
                    :rule="rules.required" 
                    label="Título del aviso"
                    :errorMessages="serverErrors.required"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <datePickerForm
                    label="Fecha de publicación"
                    :valueInput="values.date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.date = v; delete serverErrors.date}"
                    :errorMessages="serverErrors.date"
                ></datePickerForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
              <fileInputForm
                label="Afiche del aviso"
                :multiple="false"
                :valueInput="values.poster_file"
                :url="values.poster_url"
                :rule="rules.fileRequired"
                @valueChange="(v) => {values.poster_file = v; delete serverErrors.poster_file}"
                :errorMessages="serverErrors.poster_file"
                v-on:update:url="values.poster_url = $event"
                ></fileInputForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col>
                <table-items :props="tableProps" @methodHandler="method_handler" :items="tableProps.items" :valueInput="values.companies_notified" @valueChange="(v) => {values.companies_notified = v}" >
                    
                    
                    
                </table-items>
                
            </v-col>
        </v-row>


        

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
   
    data(){
        return {
            loadingCompanies: false,


            

            
            
            rules: {
				required: [
					v => !!v || 'Campo requerido'
				],
				
                fileRequired: [
                    v => {
                        if(this.values.poster_file.length == 0 && this.values.poster_url == "")
                            return "El campo es requerido"
                        return true
                    }
                ]
				
            },
            serverItems: {
                business: []
            },

            tableProps: {
				headers: [
					{
						text: 'Empresas a notificar',
						align: 'left',
						value: 'name',
						class: 'table-b-border body-1 font-weight-bold',
                        sortable: false,
					},
                   
					{
						text: '',
						align: 'right',
						sortable: false,
						value: 'data-table-select',
						class: 'table-b-border'
					}
				],
				items: [],
				search: '',
				loading: false,
                showSelect: true,
                key:"id",
                showSelect:true
			},

          
        }
    },
    watch: {
        values: function (vv) {
            //console.log(this.values, vv);
        }
    },
    mounted(){
        this.getCompanies();
    },
    methods: {
        method_handler(object){
			this[object.method_name](object.parameters)
		},

        getCompanies()
        {
            this.tableProps.loading = true;
            this.$api.user.getUserBusinessSelect()
				.then((resp) =>{
					//console.log(resp, "desde form");
					this.tableProps.items = resp.data.map((item) => {
                        return {name: item.name, id: item.id}
                    });
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
                    this.tableProps.loading = false;
				})
			;
        },
       
       
    }
}
</script>

<style lang="scss">
    .no-label .v-text-field .v-input__control .v-input__slot input, textarea{
        margin-top: 0 !important;
    }
</style>