<template>
  <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center" >
                    <v-col class="text-left col-md-6">
                        <div class="font-weight-bold body-1">
                            Editar Empleado
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="6">
                        <v-form ref="form" @submit.prevent="save">
                            <employee-form  ref="EmployeeForm" :values="form" @save="save" :stepIn="step" :stepClasses="stepClasses" @tabChanged="(val) => this.test(val)" :serverErrors="serverErrors"></employee-form>
                        </v-form>
                    </v-col>
                    
                </v-row>

            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" class="">
                    <v-row justify="end" class="px-3 py-3">
                    <secondary-button :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
					<primary-button :props="saveBtnData"></primary-button>

                    </v-row>
				</v-col>
			</v-row>
        </template>
       
        <modal :props="modalProps" @modalResponse="method_handler">
            <!-- Buttons -->
            <template slot="cardActions">
                <v-row justify="end">
                    <v-col cols="4">
                        <secondaryButton
                        :props="cancelModal"
                        ></secondaryButton>
                    </v-col>
                    <v-col cols="4">
                        <primaryButton
                        :props="acceptModal"
                        ></primaryButton>
                    </v-col>
                </v-row>
            </template> 
        </modal>
     </contentCard>
  
</template>

<script>
import EmployeeForm from "./Form.vue";
import Utils from '../../../../helpers/Utils';
import Object2Form from '../../../../helpers/Object2Form';
export default {
    components: {
        'employee-form': EmployeeForm,
    },
    
	data(){
		return {
            step: '1',
            id: this.$route.params.id,
            stepClasses: {st1: '', st2: '', st3: ''},
            loading: false,
            serverErrors: {},
            form: {
                /**GENERAL */
                employe_number: '',
                name: '',
                email: '',
                business_email: '',
                profession: '',
                birthplace: '',
                birthdate: '',
                gender: '',
                marital_status: '',
                curp: '',
                rfc: '',
                infonavit_number: '',
                fonacot_number: '',
                nss: '',
                phone: '',
                blood_type: '',
                diseases: [],
                other_diseases:'',
                country: '',
                street: '',
                ext_number: '',
                int_number: '',
                zip_code: '',
                neighborhood: '',
                city: '',
                state: '',
                emergency_contact: '',
                emergency_number: '',
                payee_name: '',
                payee_family_phone: '',
                payee_family_relationship: '',
                photo_file: [],
                photo_url: null,
                has_childs: false,
                children: [],

                /**DOCUMENTATION */
                birthCertificate_file: [],
                birthCertificate_url: '',
                birthCertificate_required: 0,
                ine_file: [],
                ine_url: '',
                ine_required: 0,
                curp_file: [],
                curp_url: '',
                curp_required: 0,
                rfc_file: [],
                rfc_url: '',
                rfc_required: 0,
                nss_file: [],
                nss_url: '',
                nss_required: 0,
                proofResidency_file: [],
                proofResidency_url: '',
                proofResidency_required: 0,
                studyCert_file: [],
                studyCert_url: '',
                studyCert_required: 0,
                economyStudy_file: [],
                economyStudy_url: '',
                economyStudy_required: 0,
                recomendationLetter_file: [],
                recomendationLetter_url: '',
                recomendationLetter_required: 0,
                psychometricTest_file: [],
                psychometricTest_url: '',
                psychometricTest_required: 0,
                internalRules_file: [],
                internalRules_url: '',
                internalRules_required: 0,
                confidential_file: [],
                confidential_url: '',
                confidential_required: 0,
                payment_file: [],
                payment_url: '',
                payment_required: 0,
                medicalTest_file: [],
                medicalTest_url: '',
                medicalTest_required: 0,
                letter_file: [],
                letter_url: '',
                letter_required: 0,
                ret_infonavit_file: [],
                ret_infonavit_url: '',
                ret_infonavit_required: 0,
                ret_fonacot_file: [],
                ret_fonacot_url: '',
                ret_fonacot_required: 0,
                epp_file: [],
                epp_url: '',
                epp_required: 0,
                waiver_equipment_file: [],
                waiver_equipment_url: '',
                waiver_equipment_required: 0,

                /**HIRE */
                company: '',
                branch_office_id: '',
                admission_date: '',
                type: '',
                start_date:'',
                end_date: '',
                mode: '',
                position_id: '',
                payroll_type: '',
                fiscal_salary: '',
                integrated_salary: '',
                support_transportation: '',
                mealTimeCheck: '',
                has_overtime_payment: '',
                repeatWeekdaysTimes: '', //FALTA EN BACK
                monday_schedule_start: '',
                monday_schedule_end: '',
                tuesday_schedule_start: '',
                tuesday_schedule_end: '',
                wednesday_schedule_start: '',
                wednesday_schedule_end: '',
                thursday_schedule_start: '',
                thursday_schedule_end: '',
                friday_schedule_start: '',
                friday_schedule_end: '',
                saturday_schedule_start: '',
                saturday_schedule_end: '',
                sunday_schedule_start: '',
                sunday_schedule_end: '',
                contract_file: [],
                contract_path: '',

                contract_multifiles: [],
                delete_contract_files: [],



            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "Employees",	
                click: () => {}
            },
            renewBtnData: {
                text: "Renovar",
                icon: "mdi-sync",
                to: "",
                block:false,
                click: () => {this.openModal()}
            },
            saveBtnData: {
                text: "Continuar",
                icon: "mdi-content-save-outline",	
                click: this.nextStep,
                loading: false,
                block:false,
            },
            modalProps: {
				visible: false,
				width: '500',
				title: 'Renovar contrato',
				text: 'Al renovar se guardará de forma definitiva el primer contrato y no será posible modificarlo ¿Estás seguro de continuar?',
				loading: false,
				bottomLineColor: 'caution',
				
			},
            cancelModal: {
                text: "No, regresar",
                icon: "",
				to: "",
				block: false,
				color: 'caution',
                click: ()=>{this.modalProps.visible = false}
            },
			acceptModal: {
                text: "Si, continuar",
                icon: "",
				to: "",
				block: false,
				color: 'cautionButton',
				textColor:'white',
                textColorLevel: "0",
				loading:false,
                classes: "",
                darkMode: true,
                click: ()=>{this.renewContract()}
            },
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        this.index();
    },
	methods: {
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
        method_handler(object){
            this[object.method_name](object.parameters)
        },
        test(val){
            console.log(this.step);
           console.log(val);
            this.step = val+'';
            console.log(this.step);
            this.saveBtnData.text = 'Continuar';
            if(this.step == 3){
                this.saveBtnData.text = 'Guardar cambios';
            }
        },
        /**
         * Get employee
         */
        index(){
            //this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.employees.get(this.id)
			.then((response) => {
                this.form = {
                    ...this.form,
                    employe_number: response.data.employe_number,
                    name: response.data.name,
                    email: response.data.email,
                    business_email: response.data.business_email??"",
                    profession: response.data.profession??"",
                    birthplace: response.data.birthplace??"",
                    birthdate: response.data.birthdate??"",
                    gender: response.data.gender??"",
                    marital_status: response.data.marital_status??"",
                    curp: response.data.curp,
                    rfc: response.data.rfc,
                    infonavit_number: response.data.infonavit_number??"",
                    fonacot_number: response.data.fonacot_number??"",
                    nss: response.data.nss,
                    phone: response.data.phone,
                    blood_type: response.data.blood_type??"",
                    diseases: response.data.diseases??[],
                    other_diseases: response.data.other_diseases??"",
                    country: response.data.country,
                    street: response.data.street,
                    ext_number: response.data.ext_number??"",
                    int_number: response.data.int_number??"",
                    zip_code: response.data.zip_code,
                    neighborhood: response.data.neighborhood,
                    city: response.data.city,
                    state: response.data.state,
                    emergency_contact: response.data.emergency_contact,
                    emergency_number: response.data.emergency_number,
                    payee_name: response.data.payee_name??"",
                    payee_family_phone: response.data.payee_family_phone??"",
                    payee_family_relationship: response.data.payee_family_relationship??"",
                    photo_file: [],
                    photo_url: response.data.photo_url, 
                    has_childs: false,
                    children: [],
                };

                if("children" in response.data){
                    if(response.data.children.length > 0){
                        this.form.has_childs = true;

                        response.data.children.forEach(item => {
                            this.form.children.push(
                                {birthdate: item.birthdate, gender: item.gender, id: item.id}
                            )
                        });
                    }
                }
                //part 1
                // this.form = {
                //     ...this.form, 
                //     photo_file:[], 
                //     photo_url: response.data.photo_url, 
                //     has_childs: false,
                //     children: []
                // };
                //part 2
                this.setDocuments(response.data.documents);
                //part 3
                this.form = {...this.form, 
                    ...response.data.current_contract,
                    monday_schedule_start: this.getValidTime(response.data.current_contract.monday_schedule_start),
                    monday_schedule_end: this.getValidTime(response.data.current_contract.monday_schedule_end),
                    tuesday_schedule_start: this.getValidTime(response.data.current_contract.tuesday_schedule_start),
                    tuesday_schedule_end: this.getValidTime(response.data.current_contract.tuesday_schedule_end),
                    wednesday_schedule_start: this.getValidTime(response.data.current_contract.wednesday_schedule_start),
                    wednesday_schedule_end: this.getValidTime(response.data.current_contract.wednesday_schedule_end),
                    thursday_schedule_start: this.getValidTime(response.data.current_contract.thursday_schedule_start),
                    thursday_schedule_end: this.getValidTime(response.data.current_contract.thursday_schedule_end),
                    friday_schedule_start: this.getValidTime(response.data.current_contract.friday_schedule_start),
                    friday_schedule_end: this.getValidTime(response.data.current_contract.friday_schedule_end),
                    saturday_schedule_start: this.getValidTime(response.data.current_contract.saturday_schedule_start),
                    saturday_schedule_end: this.getValidTime(response.data.current_contract.saturday_schedule_end),
                    sunday_schedule_start: this.getValidTime(response.data.current_contract.sunday_schedule_start),
                    sunday_schedule_end: this.getValidTime(response.data.current_contract.sunday_schedule_end),
                    position_id: response.data.current_contract.position?.id, 
                    branch_office_id: response.data.current_contract?.branch_office?.id,
                    company: (response.data.current_contract.branch_office?.company?.id),
                    contract_file: [],
                    contract_path: response.data.current_contract?.contract_url??"",
                    // contract_multifiles: response.data.current_contract?.files??[],
                };

                this.form.contract_multifiles = response.data?.current_contract?.files.map((item) => {
                    let nameFile = item.file.split("/");
                    nameFile = nameFile[nameFile.length-1];
                    return {url: item.file_url, path: item.file, name: nameFile.replace(/(.{40})..+/, "$1...")}
                });

               
				// console.log(this.form.contract_multifiles);
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
                // this.$refs.form.reset();
			})
        },

        getValidTime(time)
        {
            if(time == '' || time == null)
                return null;
            return time.substring(0,time.length - 3)
        },

        /**
         * Get name attrubute from html parent
        */
        getParentName(input){
            if("name" in input.$parent.$attrs)
                return input.$parent.$attrs.name;
            else
                return this.getParentName(input.$parent);
            
        },

        /**
         * Set error class to tabs
        */
        setErrorClass(){
            this.clearTabError();
            let errors = Object.entries(this.$refs.form.errorBag);
            //console.log(this.$refs.form.inputs, errors)
            let inputs = this.$refs.form.inputs;
            //onsole.log(inputs[0].label);
            for (let index = 0; index < errors.length; index++) {
                if(errors[index][1]){
                    let found = inputs.find((inpt) => inpt._uid == errors[index][0])
                    //console.log(this.getParentName(found))
                    let parentName = this.getParentName(found);
                    if(parentName == 'p1')
                        this.stepClasses = {...this.stepClasses, st1: 'tab-error'};
                    if(parentName == 'p2')
                        this.stepClasses = {...this.stepClasses, st2: 'tab-error'};
                    if(parentName == 'p3')
                        this.stepClasses = {...this.stepClasses, st3: 'tab-error'};
                }
            }
        },

        /**
         * Remove error class to tabs
        */
        clearTabError(){
            this.stepClasses = {...this.stepClasses, st1: '', st2: '', st3: ''};
        },

        /**
         * Set error to specific tab
        */
        setTabErrorByStep(step){
            if(step == 1){
                this.stepClasses = {...this.stepClasses, st1: 'tab-error'};
                return;
            }
            if(step == 2){
                this.stepClasses = {...this.stepClasses, st2: 'tab-error'};
                return;
            }
            this.stepClasses = {...this.stepClasses, st3: 'tab-error'};
            
        },

        /**
         * Set error to tab with errors backend
        */
        setTabErrorByServerMessages(messages){
            Object.keys(messages).forEach((x) => {
                if(x.startsWith('documentation'))
                    this.setTabErrorByStep(2);
                else if(x.startsWith('contract'))
                    this.setTabErrorByStep(3);
                else
                    this.setTabErrorByStep(1);
               
            });
            
        },
		validate () {
			return this.$refs.form.validate()
		},

        /**
         * Run to the next step
        */
        nextStep(){
            if(this.step == '1'){
                this.$refs.EmployeeForm.setRules(1);
                if(this.validate()){
                    this.clearTabError();
                    this.serverValidate('/steps/one');
                    return;
                }
            }else if(this.step == '2'){
                this.$refs.EmployeeForm.setRules(2);
                if(this.validate()){
                    this.clearTabError();
                    this.serverValidate('/steps/two');
                    return;
                }
                
            }else if(this.step == '3'){
                this.$refs.EmployeeForm.setRules(3);
                //end
                if(this.validate()){
                    //final save
                    this.clearTabError();
                    this.save();
                    return;
                }
            }
            this.setErrorClass();
            this.$store.dispatch('snackbarError', `Formulario incompleto`);
        },

        /**
         * Server validation (Without update)
        */
        serverValidate(step)
        {
            this.saveBtnData.loading = true;
            this.$store.state.loading = true;
            
            let formData = this.prepareRequest(this.form);
            console.log(formData);
            const request = Object2Form.objectToFormData(formData,'',[]);
            this.$api.employees.update(step,this.id, request)
                .then((response) => {
                    console.log(response)
                    this.step = parseInt(this.step) + 1 +'';
                    this.stepClasses = {...this.stepClasses, st1: ''};
                    this.$refs.EmployeeForm.setRules(this.step);
                    Utils.scrollToTop();
                })
                .catch((error) => {
                    //this.setTabErrorByStep(this.step);
                    console.log(error)
                    var errors = []
                    switch(error.response.status){
                        case 400: 
                            if(("message" in error.response.data)){
                                this.setTabErrorByServerMessages(error.response.data.message)
                                Object.keys(error.response.data.message).forEach((x) => {
                                    //console.log(x);
                                    //this.serverErrors = error.response.data.message;
                                    error.response.data.message[x].forEach((y) => {
                                        if(x.startsWith('documentation')){
                                            this.setErrorDocuments(x,y,formData);
                                        }else{
                                            console.log(x,'fuera')
                                            this.serverErrors[x] = Utils.deleteWord(x,y);
                                            
                                        }
                                        errors.push(Utils.deleteWord(x,y))
                                    })
                                });
                                this.$store.dispatch('snackbarError', errors.join('.<br>'));
                            }
                        break;
                        
                    }

                    console.log(this.serverErrors);
                })
                .finally(() => {
                    this.saveBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },

        /**
         * UPDATE
         */
        save(){
            this.saveBtnData.loading = true;
            this.$store.state.loading = true;
           
            // this.$store.state.overlay = true
            let formData = this.prepareRequest(this.form);
            console.log(formData);

            const request = Object2Form.objectToFormData(formData,'',[]);
            this.$api.employees.update('', this.id, request)
                .then((response) => {
                    console.log(response)
                    this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                    this.$router.push({name: 'Employees'});
                    Utils.scrollToTop();
                })
                .catch((error) => {
                    //this.setTabErrorByStep(this.step);
                    console.log(error)
                    var errors = []
                    switch(error.response.status){
                        case 400: 
                            if(("message" in error.response.data)){
                                this.setTabErrorByServerMessages(error.response.data.message);
                                Object.keys(error.response.data.message).forEach((x) => {
                                    error.response.data.message[x].forEach((y) => {
                                        if(x.startsWith('documentation')){
                                            this.setErrorDocuments(x,y,formData);
                                        }else{
                                            console.log(x,'fuera')
                                            this.serverErrors[x] = Utils.deleteWord(x,y);
                                            
                                        }
                                        errors.push(Utils.deleteWord(x,y))
                                    });
                                });
                                this.$store.dispatch('snackbarError', errors.join('.<br>'));
                            }
                        break;
                        
                    }

                    console.log(this.serverErrors);
                })
                .finally(() => {
                    this.saveBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
            // eslint-disable-next-line no-console
        },

        /**
         * Set error messages for documents
        */
        setErrorDocuments(x,message, request){
            x = x.split('.');
            let found = request.documentation[x[1]];
            this.serverErrors[`documentation.${found.type}`] = message;
        },

        /**
         * Set documents to the form
         */
        setDocuments(documents){
            //urls
            this.form.birthCertificate_url = this.getUrlDocument(documents, 'ACTA DE NACIMIENTO'); //documents.filter((doc) => doc.type == 'ACTA DE NACIMIENTO')[0].path;
            this.form.ine_url = this.getUrlDocument(documents, 'IDENTIFICACIÓN OFICIAL');//documents.find(doc => doc.type == 'IDENTIFICACIÓN OFICIAL').path;
            this.form.curp_url = this.getUrlDocument(documents, 'CURP');//documents.filter(doc => doc.type == 'CURP')[0].path;
            this.form.rfc_url = this.getUrlDocument(documents, 'RFC'); //documents.filter(doc => doc.type == 'RFC')[0].path;
            this.form.nss_url = this.getUrlDocument(documents, 'NSS'); //documents.filter(doc => doc.type == 'NSS')[0].path;
            this.form.proofResidency_url = this.getUrlDocument(documents, 'COMPROBANTE DE DOMICILIO'); //documents.filter(doc => doc.type == 'COMPROBANTE DE DOMICILIO')[0].path;
            this.form.studyCert_url = this.getUrlDocument(documents, 'CERTIFICADO DE ESTUDIOS'); //documents.filter(doc => doc.type == 'CERTIFICADO DE ESTUDIOS')[0].path;
            this.form.economyStudy_url = this.getUrlDocument(documents, 'ESTUDIO SOCIOECONÓMICO'); //documents.filter(doc => doc.type == 'ESTUDIO SOCIOECONÓMICO')[0].path;
            this.form.recomendationLetter_url = this.getUrlDocument(documents, 'CARTA DE RECOMENDACIÓN'); //documents.filter(doc => doc.type == 'CARTA DE RECOMENDACIÓN')[0].path;
            this.form.psychometricTest_url = this.getUrlDocument(documents, 'PRUEBA PSICOMÉTRICA'); //documents.filter(doc => doc.type == 'PRUEBA PSICOMÉTRICA')[0].path;
            this.form.internalRules_url = this.getUrlDocument(documents,'REGLAMENTO INTERNO'); //documents.filter(doc => doc.type == 'REGLAMENTO INTERNO')[0].path;
            this.form.confidential_url = this.getUrlDocument(documents, 'CONVENCIO CONFIDENCIAL'); //documents.filter(doc => doc.type == 'CONVENCIO CONFIDENCIAL')[0].path;
            this.form.payment_url = this.getUrlDocument(documents, 'PROPUESTA ECONÓMICA'); //documents.filter(doc => doc.type == 'PROPUESTA ECONÓMICA')[0].path;
            this.form.medicalTest_url = this.getUrlDocument(documents, 'EXAMEN MÉDICO');//documents.filter(doc => doc.type == 'EXAMEN MÉDICO')[0].path;
            this.form.letter_url = this.getUrlDocument(documents, 'ANTECEDENTES PENALES'); //documents.filter(doc => doc.type == 'ANTECEDENTES PENALES')[0].path;
            this.form.ret_infonavit_url = this.getUrlDocument(documents, 'AVISO DE RETENCIÓN INFONAVIT'); //documents.filter(doc => doc.type == 'ANTECEDENTES PENALES')[0].path;
            this.form.ret_fonacot_url = this.getUrlDocument(documents, 'AVISO DE RETENCIÓN FONACOT'); //documents.filter(doc => doc.type == 'ANTECEDENTES PENALES')[0].path;
            this.form.epp_url = this.getUrlDocument(documents, 'VALE DE EQUIPO DE PROTECCIÓN PERSONAL'); //documents.filter(doc => doc.type == 'ANTECEDENTES PENALES')[0].path;
            this.form.waiver_equipment_url = this.getUrlDocument(documents, 'CARTA RESPONSIVA DE EQUIPO'); //documents.filter(doc => doc.type == 'ANTECEDENTES PENALES')[0].path;

            //checks
            this.form.birthCertificate_required = this.getRequiredCheckDocument(documents, 'ACTA DE NACIMIENTO'); //documents.filter((doc) => doc.type == 'ACTA DE NACIMIENTO')[0].path;
            this.form.ine_required = this.getRequiredCheckDocument(documents, 'IDENTIFICACIÓN OFICIAL');//documents.find(doc => doc.type == 'IDENTIFICACIÓN OFICIAL').path;
            this.form.curp_required = this.getRequiredCheckDocument(documents, 'CURP');//documents.filter(doc => doc.type == 'CURP')[0].path;
            this.form.rfc_required = this.getRequiredCheckDocument(documents, 'RFC'); //documents.filter(doc => doc.type == 'RFC')[0].path;
            this.form.nss_required = this.getRequiredCheckDocument(documents, 'NSS'); //documents.filter(doc => doc.type == 'NSS')[0].path;
            this.form.proofResidency_required = this.getRequiredCheckDocument(documents, 'COMPROBANTE DE DOMICILIO'); //documents.filter(doc => doc.type == 'COMPROBANTE DE DOMICILIO')[0].path;
            this.form.studyCert_required = this.getRequiredCheckDocument(documents, 'CERTIFICADO DE ESTUDIOS'); //documents.filter(doc => doc.type == 'CERTIFICADO DE ESTUDIOS')[0].path;
            this.form.economyStudy_required = this.getRequiredCheckDocument(documents, 'ESTUDIO SOCIOECONÓMICO'); //documents.filter(doc => doc.type == 'ESTUDIO SOCIOECONÓMICO')[0].path;
            this.form.recomendationLetter_required = this.getRequiredCheckDocument(documents, 'CARTA DE RECOMENDACIÓN'); //documents.filter(doc => doc.type == 'CARTA DE RECOMENDACIÓN')[0].path;
            this.form.psychometricTest_required = this.getRequiredCheckDocument(documents, 'PRUEBA PSICOMÉTRICA'); //documents.filter(doc => doc.type == 'PRUEBA PSICOMÉTRICA')[0].path;
            this.form.internalRules_required = this.getRequiredCheckDocument(documents,'REGLAMENTO INTERNO'); //documents.filter(doc => doc.type == 'REGLAMENTO INTERNO')[0].path;
            this.form.confidential_required = this.getRequiredCheckDocument(documents, 'CONVENCIO CONFIDENCIAL'); //documents.filter(doc => doc.type == 'CONVENCIO CONFIDENCIAL')[0].path;
            this.form.payment_required = this.getRequiredCheckDocument(documents, 'PROPUESTA ECONÓMICA'); //documents.filter(doc => doc.type == 'PROPUESTA ECONÓMICA')[0].path;
            this.form.medicalTest_required = this.getRequiredCheckDocument(documents, 'EXAMEN MÉDICO');//documents.filter(doc => doc.type == 'EXAMEN MÉDICO')[0].path;
            this.form.letter_required = this.getRequiredCheckDocument(documents, 'ANTECEDENTES PENALES'); //documents.filter(doc => doc.type == 'ANTECEDENTES PENALES')[0].path;
            this.form.ret_infonavit_required = this.getRequiredCheckDocument(documents, 'AVISO DE RETENCIÓN INFONAVIT'); //documents.filter(doc => doc.type == 'ANTECEDENTES PENALES')[0].path;
            this.form.ret_fonacot_required = this.getRequiredCheckDocument(documents, 'AVISO DE RETENCIÓN FONACOT'); //documents.filter(doc => doc.type == 'ANTECEDENTES PENALES')[0].path;
            this.form.epp_required = this.getRequiredCheckDocument(documents, 'VALE DE EQUIPO DE PROTECCIÓN PERSONAL'); //documents.filter(doc => doc.type == 'ANTECEDENTES PENALES')[0].path;
            this.form.waiver_equipment_required = this.getRequiredCheckDocument(documents, 'CARTA RESPONSIVA DE EQUIPO'); //documents.filter(doc => doc.type == 'ANTECEDENTES PENALES')[0].path;

        },

        /**
         * Get document's url by its type
         */
        getUrlDocument(documents, docType){
            try {
                return documents.find(doc => doc.type == docType).file_url;
            } catch (error) {
                return '';
            }
        },
        /**
         * Get document's required check by its type
         */
        getRequiredCheckDocument(documents, docType){
            try {
                return documents.find(doc => doc.type == docType).required == true ? 1 : 0;
            } catch (error) {
                return 0;
            }
        },

        /**
         * Process data to send
         */
        prepareRequest(data)
        {
            let newData = JSON.parse(JSON.stringify(data));

            delete newData.photo;
            delete newData.documents;
           

            //!Part 1
            //photo_file
            delete newData.photo_file;
            if(data.photo_file instanceof File)
                newData.photo_file = data.photo_file;
            else if(data.photo_url != '')
                newData.photo_path = 'path';

            
            if(newData.business_email == "")
                delete newData.business_email;
            
            if(newData.int_number == "")
                delete newData.int_number;

            if(newData.blood_type == "" || newData.blood_type == null)
                delete newData.blood_type;

            if(newData.diseases != null){
                if(newData.diseases.find((item) => item == "OTROS") == undefined)
                    newData.other_diseases = "";
            }

            if(newData.diseases == null || newData.diseases.length == 0)
                delete newData.diseases;

            //!part 2
            //files
            newData.documentation = [];

            //birthCertificate_file
            delete newData.birthCertificate_file;
            if(data.birthCertificate_file instanceof File)
                newData.documentation.push({file:data.birthCertificate_file, type: 'ACTA DE NACIMIENTO', required: data.birthCertificate_required});
            else if(data.birthCertificate_url != '')
                newData.documentation.push({file_url: "path", type: 'ACTA DE NACIMIENTO', required: data.birthCertificate_required});
            else
                newData.documentation.push({type: 'ACTA DE NACIMIENTO', required: data.birthCertificate_required});

            //ine_file
            delete newData.ine_file;
            if(data.ine_file instanceof File)
                newData.documentation.push({file: data.ine_file, type: 'IDENTIFICACIÓN OFICIAL', required: data.ine_required});
            else if(data.ine_url != '')
                newData.documentation.push({file_url: "path", type: 'IDENTIFICACIÓN OFICIAL', required: data.ine_required});
            else
                newData.documentation.push({type: 'IDENTIFICACIÓN OFICIAL', required: data.ine_required});

            //curp_file
            delete newData.curp_file;
            if(data.curp_file instanceof File)
                newData.documentation.push({file: data.curp_file, type: 'CURP', required: data.curp_required});
            else if(data.curp_url != '')
                newData.documentation.push({file_url: "path", type: 'CURP', required: data.curp_required});
            else
                 newData.documentation.push({type: 'CURP', required: data.curp_required});

            //rfc
            delete newData.rfc_file;
            if(data.rfc_file instanceof File)
                newData.documentation.push({file:data.rfc_file, type: 'RFC', required: data.rfc_required});
            else if(data.rfc_url != '')
                newData.documentation.push({file_url: "path", type: 'RFC', required: data.rfc_required});
            else
                newData.documentation.push({type: 'RFC', required: data.rfc_required});

            //nss_file
            delete newData.nss_file;
            if(data.nss_file instanceof File)
                newData.documentation.push({file: data.nss_file, type:'NSS', required: data.nss_required});
            else if(data.nss_url != '')
                newData.documentation.push({file_url: "path", type:'NSS', required: data.nss_required});
            else
                newData.documentation.push({type:'NSS', required: data.nss_required});


            //proofResidency_file
            delete newData.proofResidency_file;
            if(data.proofResidency_file instanceof File)
                newData.documentation.push({file: data.proofResidency_file, type:'COMPROBANTE DE DOMICILIO', required: data.proofResidency_required});
            else if(data.proofResidency_url != '')
                newData.documentation.push({file_url: "path", type:'COMPROBANTE DE DOMICILIO', required: data.proofResidency_required});
            else
                newData.documentation.push({type:'COMPROBANTE DE DOMICILIO', required: data.proofResidency_required});

            //studyCert_file
            delete newData.studyCert_file;
            if(data.studyCert_file instanceof File)
                newData.documentation.push({file: data.studyCert_file, type:'CERTIFICADO DE ESTUDIOS', required: data.studyCert_required});
            else if(data.studyCert_url != '')
                newData.documentation.push({file_url: "path", type:'CERTIFICADO DE ESTUDIOS', required: data.studyCert_required});
            else
                newData.documentation.push({type:'CERTIFICADO DE ESTUDIOS', required: data.studyCert_required});

            //economyStudy_file
            delete newData.economyStudy_file;
            if(data.economyStudy_file instanceof File)
                newData.documentation.push({file: data.economyStudy_file, type:'ESTUDIO SOCIOECONÓMICO', required: data.economyStudy_required});
            else if(data.economyStudy_url != '')
                newData.documentation.push({file_url: "path", type:'ESTUDIO SOCIOECONÓMICO', required: data.economyStudy_required});
            else
                newData.documentation.push({type:'ESTUDIO SOCIOECONÓMICO', required: data.economyStudy_required});

            //recomendationLetter_file
            delete newData.recomendationLetter_file;
            if(data.recomendationLetter_file instanceof File)
                newData.documentation.push({file: data.recomendationLetter_file, type:'CARTA DE RECOMENDACIÓN', required: data.recomendationLetter_required});
            else if(data.recomendationLetter_url != '')
                newData.documentation.push({file_url: "path", type:'CARTA DE RECOMENDACIÓN', required: data.recomendationLetter_required});
            else
                newData.documentation.push({type:'CARTA DE RECOMENDACIÓN', required: data.recomendationLetter_required});

            //psychometricTest_file
            delete newData.psychometricTest_file;
            if(data.psychometricTest_file instanceof File)
                newData.documentation.push({file: data.psychometricTest_file, type:'PRUEBA PSICOMÉTRICA', required: data.psychometricTest_required});
            else if(data.psychometricTest_url != '')
                newData.documentation.push({file_url: "path", type:'PRUEBA PSICOMÉTRICA', required: data.psychometricTest_required});
            else
                newData.documentation.push({type:'PRUEBA PSICOMÉTRICA', required: data.psychometricTest_required});

            //internalRules_file
            delete newData.internalRules_file;
            if(data.internalRules_file instanceof File)
                newData.documentation.push({file: data.internalRules_file, type:'REGLAMENTO INTERNO', required: data.internalRules_required});
            else if(data.internalRules_url != '')
                newData.documentation.push({file_url: "path", type:'REGLAMENTO INTERNO', required: data.internalRules_required});
            else
                newData.documentation.push({type:'REGLAMENTO INTERNO', required: data.internalRules_required});

            //confidential_file
            delete newData.confidential_file;
            if(data.confidential_file instanceof File)
                newData.documentation.push({file: data.confidential_file, type:'CONVENCIO CONFIDENCIAL', required: data.confidential_required});
            else if(data.confidential_url != '')
                newData.documentation.push({file_url: "path", type:'CONVENCIO CONFIDENCIAL', required: data.confidential_required});
            else
                newData.documentation.push({type:'CONVENCIO CONFIDENCIAL', required: data.confidential_required});

            //payment_file
            delete newData.payment_file;
            if(data.payment_file instanceof File)
                newData.documentation.push({file: data.payment_file, type:'PROPUESTA ECONÓMICA', required: data.payment_required});
            else if(data.payment_url != '')
                newData.documentation.push({file_url: "path", type:'PROPUESTA ECONÓMICA', required: data.payment_required});
            else
                newData.documentation.push({type:'PROPUESTA ECONÓMICA', required: data.payment_required});

            //medicalTest_file
            delete newData.medicalTest_file;
            if(data.medicalTest_file instanceof File)
                newData.documentation.push({file: data.medicalTest_file, type:'EXAMEN MÉDICO', required: data.medicalTest_required});
            else if(data.medicalTest_url != '')
                newData.documentation.push({file_url: "path", type:'EXAMEN MÉDICO', required: data.medicalTest_required});
            else
                newData.documentation.push({type:'EXAMEN MÉDICO', required: data.medicalTest_required});

            //letter_file
            delete newData.letter_file;
            if(data.letter_file instanceof File)
                newData.documentation.push({file: data.letter_file, type:'ANTECEDENTES PENALES', required: data.letter_required});
            else if(data.letter_url != '')
                newData.documentation.push({file_url: "path", type:'ANTECEDENTES PENALES', required: data.letter_required});
            else
                newData.documentation.push({type:'ANTECEDENTES PENALES', required: data.letter_required});

            //ret_infonavit_file
            delete newData.ret_infonavit_file;
            if(data.ret_infonavit_file instanceof File)
                newData.documentation.push({file: data.ret_infonavit_file, type:'AVISO DE RETENCIÓN INFONAVIT', required: data.ret_infonavit_required});
            else if(data.ret_infonavit_url != '')
                newData.documentation.push({file_url: "path", type:'AVISO DE RETENCIÓN INFONAVIT', required: data.ret_infonavit_required});
            else
                newData.documentation.push({type:'AVISO DE RETENCIÓN INFONAVIT', required: data.ret_infonavit_required});

            
            //ret_fonacot_file
            delete newData.ret_fonacot_file;
            if(data.ret_fonacot_file instanceof File)
                newData.documentation.push({file: data.ret_fonacot_file, type:'AVISO DE RETENCIÓN FONACOT', required: data.ret_fonacot_required});
            else if(data.ret_fonacot_url != '')
                newData.documentation.push({file_url: "path", type:'AVISO DE RETENCIÓN FONACOT', required: data.ret_fonacot_required});
            else
                newData.documentation.push({type:'AVISO DE RETENCIÓN FONACOT', required: data.ret_fonacot_required});


            //epp_file
            delete newData.epp_file;
            if(data.epp_file instanceof File)
                newData.documentation.push({file: data.epp_file, type:'VALE DE EQUIPO DE PROTECCIÓN PERSONAL', required: data.epp_required});
            else if(data.epp_url != '')
                newData.documentation.push({file_url: "path", type:'VALE DE EQUIPO DE PROTECCIÓN PERSONAL', required: data.epp_required});
            else
                newData.documentation.push({type:'VALE DE EQUIPO DE PROTECCIÓN PERSONAL', required: data.epp_required});

            //waiver_equipment_file
            delete newData.waiver_equipment_file;
            if(data.waiver_equipment_file instanceof File)
                newData.documentation.push({file: data.waiver_equipment_file, type:'CARTA RESPONSIVA DE EQUIPO', required: data.waiver_equipment_required});
            else if(data.waiver_equipment_url != '')
                newData.documentation.push({file_url: "path", type:'CARTA RESPONSIVA DE EQUIPO', required: data.waiver_equipment_required});
            else
                newData.documentation.push({type:'CARTA RESPONSIVA DE EQUIPO', required: data.waiver_equipment_required});




            //!part 3
            newData.contract = new Object();
            newData.contract["admission_date"] = data.admission_date;
            newData.contract["start_date"] = data.start_date;
            newData.contract["end_date"] = data.end_date;
            newData.contract["type"] = data.type;
            newData.contract["mode"] = data.mode;
            newData.contract["payroll_type"] = data.payroll_type;
            newData.contract["fiscal_salary"] = data.fiscal_salary;
            newData.contract["integrated_salary"] = data.integrated_salary;
            newData.contract["support_transportation"] = data.support_transportation?true:false;
            newData.contract["meal_schedule"] = data.meal_schedule?true:false;
            newData.contract["has_overtime_payment"] = data.has_overtime_payment?true:false;

            if( data.monday_schedule_start != '' &&  data.monday_schedule_start != null)
                newData.contract["monday_schedule_start"] = data.monday_schedule_start;
            if( data.monday_schedule_end != '' &&  data.monday_schedule_end != null)
                newData.contract["monday_schedule_end"] = data.monday_schedule_end;
            if( data.tuesday_schedule_start != '' &&  data.tuesday_schedule_start != null)
                newData.contract["tuesday_schedule_start"] = data.tuesday_schedule_start;
            if( data.tuesday_schedule_end != '' &&  data.tuesday_schedule_end != null)
                newData.contract["tuesday_schedule_end"] = data.tuesday_schedule_end;
            if( data.wednesday_schedule_start != '' &&  data.wednesday_schedule_start != null)
                newData.contract["wednesday_schedule_start"] = data.wednesday_schedule_start;
            if( data.wednesday_schedule_end != '' &&  data.wednesday_schedule_end != null)
                newData.contract["wednesday_schedule_end"] = data.wednesday_schedule_end;
            if( data.thursday_schedule_start != '' &&  data.thursday_schedule_start != null)
                newData.contract["thursday_schedule_start"] = data.thursday_schedule_start;
            if( data.thursday_schedule_end != '' &&  data.thursday_schedule_end != null)
            newData.contract["thursday_schedule_end"] = data.thursday_schedule_end;
            if( data.friday_schedule_start != '' &&  data.friday_schedule_start != null)
                newData.contract["friday_schedule_start"] = data.friday_schedule_start;
            if( data.friday_schedule_end != '' &&  data.friday_schedule_end != null)
                newData.contract["friday_schedule_end"] = data.friday_schedule_end;
            
            if( data.saturday_schedule_start != '' &&  data.saturday_schedule_start != null)
                newData.contract["saturday_schedule_start"] = data.saturday_schedule_start;
            if( data.saturday_schedule_end != '' &&  data.saturday_schedule_end != null)
                newData.contract["saturday_schedule_end"] = data.saturday_schedule_end;
            
            if(data.sunday_schedule_start != '' && data.sunday_schedule_start != null)
                newData.contract["sunday_schedule_start"] = data.sunday_schedule_start;
            if(data.sunday_schedule_end != ''&& data.sunday_schedule_end != null)
                newData.contract["sunday_schedule_end"] = data.sunday_schedule_end;

            newData.contract["branch_office_id"] = data.branch_office_id;
            newData.contract["position_id"] = data.position_id;
            newData.contract["repeat_schedule"] = data.repeat_schedule?1:0;
             
            if(data.type == 'INDETERMINADO'){
                delete newData.contract['start_date'];
                delete newData.contract['end_date'];
            }

            //contract_file
            delete newData.contract_file;
            // if(data.contract_file instanceof File){
            //     newData.contract["contract_file"] = data.contract_file;
            // }else if(data.contract_path != '')
            //     newData.contract["contract_path"] = data.contract_path;
            // else if(data.contract_path == "")
            //     delete newData.contract["contract_path"];

            //new files
            if(data.contract_file.length > 0){
                newData.contract["new_contract_files"] = data.contract_file.map((item) =>{
                    console.log(item)
                    return {file: item};
                });
            }

            //deleted files
            if(data.delete_contract_files.length > 0){
                newData.contract["delete_contract_files"] = data.delete_contract_files
            }
            delete newData.files;
            delete newData.delete_contract_files;
            delete newData.contract_multifiles;


            delete newData.position;
            delete newData.branch_office;

            console.log(newData, 99);
            

            return newData;
        },

        /**
         * Modal to renew a contract
         */
        openModal(){
            this.modalProps.visible = true;	
        },

        /**
         * Renew employee's contract
         */
        renewContract(){
            console.log(this.id);
            this.modalProps.visible = true;	
        }


    },
}
</script>

<style lang="scss">
    .buttons{
        &__container{
            display: flex !important;
            
            justify-content: flex-end;

            @media (max-width: #{map-get($grid-breakpoints, 'sm')}) {
				//background-color: red;
                flex-direction: column;
                & > * {
                    margin: 5px 8px 5px 0;
                }
			}
			// @media (min-width: #{map-get($grid-breakpoints, 'md')}) {
				
			// }
			// @media (min-width: #{map-get($grid-breakpoints, 'lg')}) {
				
			// 	background-color: blue;
			// }
			// @media (min-width: #{map-get($grid-breakpoints, 'xl')}) {
				
				
			// }
        }
    }
</style>