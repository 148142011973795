<template>
    <div>
        <v-row>
            <v-col >
                <textFieldForm
                    label="Nombre completo"
                    :valueInput="values.name"
                    :rule="rules.name"
                    @valueChange="(v) => {values.name = v.trim(); delete serverErrors[`name`]}"
                    :errorMessages="serverErrors[`name`]"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <textFieldForm
                    label="Teléfono personal"
                    :valueInput="values.phone"
                    :rule="rules.phone"
                    @valueChange="(v) => {values.phone = v.trim(); delete serverErrors[`phone`]}"
                    :errorMessages="serverErrors[`phone`]"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <autocompleteOneForm
                    label="Medio de postulación"
                    :valueInput="values.means_of"
                    :rule="rules.means_of"
                    :items="serverItems.referencesList"
                    @valueChange="(v) => {values.means_of = v; delete serverErrors[`means_of`]}"
                    :errorMessages="serverErrors[`means_of`]"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <autocompleteOneForm
                    label="Entrevistador"
                    :loading="loadingEmployee"
                    :valueInput="values.interviewer_id"
                    :rule="rules.interviewer_id"
                    :items="serverItems.employees"
                    @valueChange="(v) => {values.interviewer_id = v; delete serverErrors[`interviewer_id`]}"
                    :errorMessages="serverErrors[`interviewer_id`]"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col >
                <datePickerForm
                    label="Fecha de la entrevista"
                    :valueInput="values.interview_date"
                    :rule="rules.interview_date"
                    @valueChange="(v) => {values.interview_date = v; delete serverErrors[`interview_date`]}"
                    :errorMessages="serverErrors[`interview_date`]"
                ></datePickerForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <fileInputForm
                    label="Curriculum"
                    :multiple="false"
                    :valueInput="values.resume_file"
                    :rule="rules.resume_file"
                    @valueChange="(v) => {values.resume_file = v; delete serverErrors[`resume_file`]}"
                    :errorMessages="serverErrors[`resume_file`]"
                    :url="values.resume_url"
                ></fileInputForm>
            </v-col>
        </v-row>
    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
   
    data(){
        return {
            loadingEmployee:false,
            
            rules: {
				name: [
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				],
                phone: [
					v => {
                        if(v!= null && !v.match("^[0-9]*$"))
                            return 'Solo números';
                        if(v != null && v.length >= 11){
                            return 'Máximo 10 caracteres';
                        }
                        if(!v)
                            return 'El campo es requerido';
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				],
                means_of: [
					v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				],
                interviewer_id: [
					v => !!v || 'El campo es requerido'
				],
                interview_date:[
                    v => !!v || 'El campo es requerido'
                ],
                resume_file: [
                    // v => {
                    //     if(v.length == 0)
                    //         return 'El campo es requerido';
                    //     return true;
                    // }
                ]
              
             
            },
            serverItems: {
                referencesList: [
                    {text: "Linked In", value: "Linked In"},
                    {text: "Indeed", value: "Indeed"},
                    {text: "Facebook", value: "Facebook"},
                    {text: "Bolsa de trabajo UT", value: "Bolsa de trabajo UT"},
                    {text: "Bolsa de trabajo UASLP", value: "Bolsa de trabajo UASLP"},
                    {text: "Bolsa de trabajo UPSLP", value: "Bolsa de trabajo UPSLP"},
                    {text: "Computrabajo", value: "Computrabajo"},
                    {text: "OCC", value: "OCC"}

                ],
                employees: [],

            },

          
        }
    },
    mounted(){
       
        this.getEmployees();
    },
    methods: {
        
       

        /**
         * Get all employees
         */
        getEmployees()
        {
           
            this.loadingEmployee = true;
            this.$api.employees.index()
                .then((resp) => {
                    this.serverItems.employees = resp.data.map((emp) =>{
                        return {value:emp.id, text:emp.name}
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() =>{
                    this.loadingEmployee = false;
                })
            ;
        }
    }
}
</script>

<style lang="scss">
</style>