<template>
    <div>
        <!-- <v-card class="mb-4 mt-0" elevation="0"> -->
            <!-- <v-card-text> -->
                <v-row>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            label="Empresa"
                            :valueInput="values.company"
                            :rule="rules.required"
                            :items="serverItems.company"
                            @valueChange="(v) => {values.company = v; getLocations(v); getEmployeeByBusiness(v); delete serverErrors['company']}"
                            :loading="loadingCompanies"
                            :errorMessages="serverErrors['company']"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>


                <v-row>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            name="p3"
                            label="Sucursal"
                            :valueInput="values.branch_office_id"
                            :rule="rules.required"
                            :items="serverItems.locations"
                            :loading="loadingLocations"
                            @valueChange="(v) => {values.branch_office_id = v; delete serverErrors[`contract.branch_office_id`]}"
                            :errorMessages="serverErrors[`contract.branch_office_id`]"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>


                <v-row>
                    <v-col class="py-2">
                       <autocompleteOneForm
                            label="Empleado"
                            :valueInput="values.employee_id"
                            :rule="rules.required"
                            :items="serverItems.employees"
                            @valueChange="(v) => {values.employee_id = v; updateEmployeeName(v); delete serverErrors['employee_id']}"
                            :loading="loadingEmployee"
                            :errorMessages="serverErrors['employee_id']"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                       <autocompleteOneForm
                            label="Motivo de baja"
                            :valueInput="values.reason"
                            :rule="rules.required"
                            :items="serverItems.reasons"
                            @valueChange="(v) => {values.reason = v; delete serverErrors['reason']}"
                            :errorMessages="serverErrors['reason']"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <textAreaForm
                            @valueChange="(v) => {values.comments = v.trim(); delete serverErrors.comments}" 
                            :valueInput="values.comments" 
                            :rule="rules.required" 
                            label="Comentarios sobre la baja"
                            :errorMessages="serverErrors.comments"
                            :rows="3"
                        ></textAreaForm>
                    </v-col>
                </v-row>
               
                
            <!-- </v-card-text> -->
        <!-- </v-card> -->

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
   
    data(){
        return {
            tab: null,
            show4: false,
            loadingCompanies: false,
            loadingEmployee:false,
            loadingLocations:false,
            
            rules: {
				required: [
					v => !!v || 'el campo es requerido'
				],
				company: [
                    //v => !!v || 'El campo es requerido'
				]
				
            },
            serverItems: {
                company: [],
                employees: [],
                locations: [],
                reasons: [
                    {text: "Despido", value: "despido"},
                    {text: "Renuncia", value: "renuncia"},
                ]
            },

          
        }
    },
    mounted(){
        this.getCompanies();
        this.getEmployeeByBusiness(this.values.company)
        this.getLocations(this.values.company)
    },
    methods: {
        updateEmployeeName(id ){
            if(id == null || id == "" || id == undefined)
                return;

            const found = this.serverItems.employees.find((x) => x.value == id);
            this.values.employeeName = found.text??"NA";
        },
        getLocations(location = undefined)
        {
            if(location == '' || location == undefined)
            return;

            this.loadingLocations = true;
            this.$api.locations.getLocationsByBusiness(location)
            .then((resp) =>{
                //console.log(resp, "carga de locaciones", location);
                this.serverItems.locations = resp.data.map((itm) =>{
                    return {value: itm.id, text: itm.name};
                });
                })
                .catch((error) =>{
                console.log(error);
                })
                .finally(() => {
                this.loadingLocations = false;
                })
            ;
        },
        getCompanies()
        {
            this.loadingCompanies = true;
            this.$api.user.getUserBusinessSelect()
				.then((resp) =>{
					this.serverItems.company = resp.data.map((item) => {
                        return {text: item.name, value: item.id};
                    });
				})
				.catch((error) =>{
					console.log(error, "error desde form areas");
				})
				.finally(() => {
                    this.loadingCompanies = false;
				})
			;
        },

        getEmployeeByBusiness(business = undefined)
        {
            if(business == '' || business == undefined)
                return;
            this.loadingEmployee = true;
            this.$api.employees.indexByBusiness(business)
                .then((resp) => {
                    // this.serverItems.employees = [];
                    
                    this.serverItems.employees = resp.data.map((emp) =>{
                        return {value:emp.id, text:emp.name}
                    });
                    // this.serverItems.employees.push({text:"Sin asignar", value: ""});
                })
                .catch((error) => {
                    console.log(error, "assing form");
                })
                .finally(() =>{
                    this.loadingEmployee = false;
                })
            ;
        }
    }
}
</script>

<style>

</style>