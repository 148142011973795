<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center" justify="space-between">
					<v-col cols="12" sm="9"  class="mx-0 px-0">
						<v-row class="" justify="start" no-gutters>
							<!-- <v-col cols="1"> -->
								<alternativeButton
									:props="returnBtnData"
								></alternativeButton>
							<!-- </v-col> -->
							<v-col class="ml-2">
								<alternativeTextInfo
									:text="jobInfo"
								></alternativeTextInfo>
							</v-col>
						</v-row>
					</v-col>
					<v-spacer></v-spacer>
					<v-col v-permission="'candidates.create'" cols="12" md="3" lg="3" class="mx-0 px-0">
                        <primaryButton v-if="allowCreate && tableProps.loading == false" :props="createBtnData"></primaryButton>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<datatable :props="tableProps" @method_handler="method_handler">
							<!-- chip status item -->
							<template v-slot:[`item.status`]="{ item }">
								<chip-item class="text-capitalize" :color="getColorStatus(item.status)" :text="item.status"></chip-item>
							</template> 
							<!--  -->
						</datatable>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <!-- <modal :props="modalProps" @modalResponse="method_handler"></modal> -->

		
	</div>
</template>
<script>
import ChipDataTableItem from "../../../../../components/ChipDataTableItem.vue";
import Utils from '../../../../../helpers/Utils';
export default {
	components: {
       'chip-item': ChipDataTableItem
    },
    data(){
		return {
            jobId: this.$route.params.jobId,
			jobText:'',
			jobPositions: 0,
			actions: [
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/vacantes/1/candidatos/1/process',
                    permissions: 'candidates.update'
				},
                {
					name: 'Curriculum',
					icon: {
						color: 'secondary',
						icon: 'mdi-file-document-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'openFile',
                    permissions: 'candidates.show'
				},
				
			],
			tableProps: {
				headers: [
					{
						text: 'Nombre',
						align: 'left',
						value: 'name',
						class: 'table-b-border'
					},
                    {
						text: 'Teléfono',
						align: 'center',
						value: 'phone',
						class: 'table-b-border'
					},
                    {
						text: 'Medio de postulación',
						align: 'center',
						value: 'means_of',
						class: 'table-b-border'
					},
                    {
						text: 'Fecha de entrevista',
						align: 'center',
						value: 'interview_date',
						class: 'table-b-border'
					},
                    {
                        text: 'Entrevistador',
                        align: 'center',
                        value: 'interviewer',
                        class: 'table-b-border'
                    },
                    {
                        text: 'Proceso',
                        align: 'center',
                        value: 'status',
                        class: 'table-b-border'
                    },
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						class: 'table-b-border'
					}
				],
				items: [],
				search: '',
				loading: false
			},
			modalProps: {
				visible: false,
				width: '500',
				title: 'Hola',
				text: 'Adiós',
				buttons: [
					{
						text: 'Cancelar',
						color: 'error',
						textColor: 'white',
						btnTypeText: true,
						method: 'cancel',
						parameters: []
					},
					{
						text: 'Aceptar',
						color: 'secondary',
						textColor: 'primary--text mx-1',
						btnTypeText: false,
						method: 'confirm',
						parameters: []
					}
				]
			},
			snackbar: {
				visible: false,
				color: '',
				text: '',
				timeout: '-1'
			},
            createBtnData: {
                text: "Nuevo Candidato",
                icon: "mdi-plus",
				to: "CandidatesCreate",
				block: true,
                click: ()=>{}
            },
			returnBtnData: {
                text: "<",
                icon: "mdi-chevron-left",
                textClass: "black--text",
				to: "Jobs",
				block: false,
                click: ()=>{}
            }
		}
	},
	
	computed:{
		jobInfo:function(){
			
			return `${this.jobText}${(this.jobPositions-this.cantidatesCount)}`
			//return 'Lavalosas - Las Jacarandas - Lugares: 2 - Disponibles: 2';
		},
		cantidatesCount: function(){
		
			return this.tableProps.items.filter((itm) => itm.status == 'contratación').length;
		},
		allowCreate: function(){
			return this.jobPositions-this.cantidatesCount <= 0 ? false: true;
		}
	},

	mounted(){
		this.getJob();
		this.index();
	},

    methods: {
        method_handler(object){
			this[object.method_name](object.parameters)
		},
		index() {
			this.tableProps.loading = true;

			//call api
			this.$api.jobs.getCantidates(this.jobId)
				.then((resp) =>{
					this.tableProps.items = resp.data.map((x) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))
						//! this is edition -> x.actions[0].action = { name: 'CandidatesEdit', params: { jobId: this.jobId, candidateId: x.id } }
						x.actions[0].action = { name: 'CandidatesProcess', params: { jobId: this.jobId, candidateId: x.id } }
						//x.actions[1].action = { name: '', params: { id: x.id } }
						
						//CV wasn't uploaded
						if(x.resume_url == null){
							x.actions[1] = {...x.actions[1], disabled:true};
						}
						x.interviewer = x.interviewer.name;
						x['parameters'] = { id: x.id, fileUrl: x.resume_url}
						x.status = x.current_status == null ?'sin respuesta' : x.current_status.type.toLowerCase();
						// x.status = ;
						if(x.status == 'pruebas psicometricas')
							x.status = 'pruebas psicométricas';
						if(x.status == 'estudio socioeconomico')
							x.status = 'estudio socioeconómico';
						if(x.status == 'contratacion')
							x.status = 'contratación';

                        x.interview_date = Utils.globalDateFormat(x.interview_date);
						return x
					});
				})
				.catch((error) =>{
					console.log(error, "error candidate index");
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
			//console.log(test);
			
		},

		/**
		 * Load job information
		 */
		getJob()
		{
			this.$api.jobs.get(this.jobId)
				.then((resp) => {
					//? calulate by candidates count ? 
					this.jobPositions = resp.data.quantity;
					this.jobText = `${Utils.getObjectValue(resp.data, 'position.name')} - ${Utils.getObjectValue(resp.data, 'branch_office.name')} - Lugares: ${resp.data.quantity} - Disponibles: `;
				})
				.catch((error) => {
					console.log(error, "error get job");
				})
				.finally(() =>{

				})
			;
		},

		openFile(data)
		{
			window.open(data.fileUrl);
		},

		/**
		 * Set chip color by status
		 * @param {string} status 
		 */
        getColorStatus (status) {
            if(status == "entrevista" || 
				status == 'referencias laborales' ||
				status == 'pruebas psicométricas' ||
				status == 'estudio socioeconómico'				
			)
				return 'success';
			if(status == 'contratación')
				return 'caution';
			if(status == "sin respuesta")
				return 'warning';
        },

    }
}
</script>

<style lang="scss">
	
</style>